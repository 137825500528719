import * as React from "react";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  lineHeight: "60px",
  padding: "8px",
}));

const darkTheme = createTheme({ palette: { mode: "dark" } });
const lightTheme = createTheme({ palette: { mode: "light" } });

const ElevatedPaper = ({ children, elevation, ...props }) => {
  return (
    <ThemeProvider theme={props.darkTheme ? darkTheme : lightTheme}>
      <Item elevation={elevation || 24}>{children}</Item>
    </ThemeProvider>
  );
};

export default ElevatedPaper;
