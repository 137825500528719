import React from "react";
import Card from "./Card";
import MaterialTable from "material-table";

const MatTable = (props) => {
  const {
    title,
    data,
    columns,
    actions,
    onRowClick,
    onSelectionChange = () => {},
    tableRef,
    showSelectAllCheckbox,
  } = props;

  // test after doing this properly...
  //  const updatedColumns = columns.map((column) => ({
  //   ...column,
  //   defaultSort: column.field ? "desc" : undefined, // Set default sort to 'desc'
  // }));
  return (
    <MaterialTable
      {...props}
      tableRef={tableRef}
      components={{
        Container: (props) => <Card {...props} />,
      }}
      options={{
        headerStyle: {
          fontWeight: 800,
          fontSize: 18,
        },
        actionsColumnIndex: -1,
        search: true,
        pageSize: 10,
        // selection: true,
        // maxBodyHeight: "65vh",
        // minBodyHeight: "65vh",
        showTextRowsSelected: false,
        padding: "dense",
        showSelectAllCheckbox: showSelectAllCheckbox || false,
        paging: true,
        pageSizeOptions: [10, 20, 50, 100],
        ...props.options,
      }}
      localization={{
        pagination: {
          labelRowsPerPage: "\n",
        },
        header: {
          actions: "",
        },
      }}
      actions={actions}
      columns={columns}
      data={data}
      title={title || ""}
      onRowClick={onRowClick}
      onSelectionChange={(rows, dataClicked, path) =>
        onSelectionChange(rows, path)
      }
    />
  );
};

export default MatTable;
