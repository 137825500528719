import React from "react";
import "./HoverIcons.css";
import Icon from "@material-ui/core/Icon";
import { Tooltip } from "@material-ui/core";

function HoverIcons({
  googleIcon,
  image,
  muiIcon,
  googleIconHover,
  imageHover,
  muiIconHover,
  disabled,
  style,
  onClick,
  tooltip,
  ...props
}) {
  return (
    <span
      className="overIconButton"
      // style={style || { marginTop: "3px", height: "25px" }}
      onClick={onClick}
    >
      <IconSwitch
        tooltip={tooltip}
        googleIcon={googleIcon}
        image={image}
        muiIcon={muiIcon}
        disabled={disabled}
        style={style}
      />
      {/* Its not same component seen twice, one of them will hide onHover from css */}
      <IconSwitch
        tooltip={tooltip}
        googleIcon={googleIconHover}
        image={imageHover}
        muiIcon={muiIconHover}
        disabled={disabled}
      />
    </span>
  );
}

const IconSwitch = ({ tooltip, ...props }) => {
  const googleIcon = props?.googleIcon;
  const image = props?.image;
  const muiIcon = props?.muiIcon;

  if (googleIcon) return <Icon className="iconButton">{googleIcon}</Icon>;
  else if (image)
    return (
      <img
        style={{ opacity: props.disabled ? "50%" : "100%", cursor: "pointer" }}
        className="iconButton"
        src={image}
      />
    );
  else if (muiIcon)
    return tooltip ? (
      <Tooltip title={tooltip}>
        <Icon className="iconButton" style={{ cursor: "pointer" }}>
          {muiIcon}
        </Icon>
      </Tooltip>
    ) : (
      <Icon className="iconButton" style={{ cursor: "pointer" }}>
        {muiIcon}
      </Icon>
    );

  return <></>;
};

export default HoverIcons;
