import React from "react";
import Paper from "@mui/material/Paper";
const Card = (props) => {
  return (
    <Paper
      {...props}
      sx={{
        background: "rgba(255, 255, 255, 0.37) !important",
        borderRadius: "20px !important",
        ...props.sx,
      }}
    />
  );
};

export default Card;
