import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core/index";
import ProductItem from "./ProductItem";
import { defaultFilter } from "../Detail";
import { useFormContext } from "react-hook-form";
import { search } from "../../../api";
import enums from "helpers/enums";
import { oopsNotFound, selectedDivisionName } from "@ui/Utils/helper";

const DetailContent = ({ isEditable, initialValues }) => {
  const formMethods = useFormContext();
  const { watch } = formMethods;

  const values = watch();
  const customerId = values.createdBy?._id;
  const [vendorCodes, setVendorCodes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models["vendor-portal"]?.vendorCodes)) || [];
        setVendorCodes(res?.[0]?.availableCodes || []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Grid container spacing={3}>
      {(values.items?.filter((item) => defaultFilter(item)) || []).length ? (
        (values.items || []).map(
          (item, itemIndex) =>
            defaultFilter(item) && (
              <ProductItem
                key={itemIndex}
                item={item}
                itemIndex={itemIndex}
                isEditable={isEditable}
                vendorCodes={vendorCodes}
                customerId={customerId}
                initialValues={initialValues}
              />
            )
        )
      ) : values.code ? (
        <div style={{ textAlign: "center" }}>
          <div>
            <img
              src={oopsNotFound}
              style={{ height: "320px", width: "440px" }}
            />
          </div>
          <div>
            <h3>{`No products were ordered in ${selectedDivisionName} Division`}</h3>
          </div>
        </div>
      ) : null}
    </Grid>
  );
};

export default DetailContent;
