import { colors } from '@material-ui/core';

const colorPalette = {
	primary: '#12539E',
	secondary: '#FF9B08',
	darkBlue: '#003675',
	error: '#B00020',
	black: '#000000',
	white: '#ffffff',
	lightBlue: '#F1FAF9',
	circleblue: '#E0F0FA',
	grey: '#333333',
	primary50: '#f9fbe7',
	titleColor: '#838588',
	orange: '#F8991C',
	overline: '#3466B0',
	emphasis: {
		high: 'rgba(85, 87, 90, 1)',
		medium: 'rgba(85, 87, 90, 0.87)',
		low: 'rgba(85, 87, 90, 0.6)',
	},
	lightGrey: '#f5f6fa',
	pageBackground: '#f3f3f3',
	mediumGrey: '#E9ECEF',
	headerGreyBackground: '#E3E6EA',
	mediumRed: '#EB5946',
	textPrimary: '#212529',
	teal1: 'rgba(170, 255, 190, 1)',
	tableHeaderGrey: '#CCCCCC',
	grey1: '#676A6C',
	backgroundCardBlue: '#EFF8FF',
};

const palette = {
	common: {
		black: '#000',
		white: '#fff',
	},
	type: 'light',
	primary: {
		main: '#12539E',
		light: '#547fd0',
		dark: '#002b6f',
	},
	secondary: {
		main: '#FF9B08',
		light: '#ffcc4a',
		dark: '#c66c00',
		contrastText: '#fff',
	},
	error: {
		main: '#B00020',
		light: '#e57373',
		dark: '#d32f2f',
		contrastText: '#fff',
	},
	warning: {
		light: '#ffb74d',
		main: '#ff9800',
		dark: '#f57c00',
		contrastText: 'rgba(0, 0, 0, 0.87)',
	},
	info: {
		light: '#64b5f6',
		main: '#2196f3',
		dark: '#1976d2',
		contrastText: '#fff',
	},
	success: {
		light: '#81c784',
		main: '#4caf50',
		dark: '#388e3c',
		contrastText: 'rgba(0, 0, 0, 0.87)',
	},
	text: {
		primary: 'rgba(0, 0, 0, 0.87)',
		secondary: 'rgba(0, 0, 0, 0.54)',
		disabled: 'rgba(0, 0, 0, 0.38)',
		hint: 'rgba(0, 0, 0, 0.38)',
		divider: 'rgba(0, 0, 0, 0.12)',
	},
	typography: {
		fontFamily: '"Open Sans", "sans-serif"',
	},
	lightBlue: {
		main: colorPalette.lightBlue,
	},
	emphasis: {
		high: colorPalette.emphasis.high,
		medium: colorPalette.emphasis.medium,
		low: colorPalette.emphasis.low,
	},
	background: {
		paper: '#fff',
		default: '#f5f6fa',
	},
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.08)',
		hoverOpacity: 0.08,
		selected: 'rgba(0, 0, 0, 0.14)',
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		divider: colors.grey[200],
	},
};

export { palette, colorPalette };
