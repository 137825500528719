import { palette } from "./palette";

export default {
  h1: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 400,
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "45px",
  },
  h2: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "29px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h3: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 400,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
  h4: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "0.15px",
    lineHeight: "26px",
  },
  h5: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "20px",
  },
  h6: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "0.05px",
    lineHeight: "20px",
  },
  h7: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: "0.05px",
    lineHeight: "20px",
  },
  h8: {
    color: palette.text.primary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "10px",
    letterSpacing: "0.05px",
    lineHeight: "20px",
  },
  subtitle1: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: 0.5,
    lineHeight: "21px",
    textTransform: "uppercase",
  },
  subtitle2: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: "bold",
    fontSize: "16px",
    letterSpacing: 0.5,
    lineHeight: "20px",
    textTransform: "uppercase",
  },
  body1: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.5px",
  },
  body2: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21px",
    letterSpacing: "0.5px",
  },
  body2Bold: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.5px",
  },
  button: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  caption: {
    fontFamily: '"Open Sans", "sans-serif"',
    fontWeight: 400,
    fontSize: "12px",
    letterSpacing: "0.5x",
    lineHeight: "16px",
  },
  overline: {
    color: palette.text.secondary,
    fontFamily: '"Open Sans", "sans-serif"',
    fontSize: "10px",
    fontWeight: 400,
    letterSpacing: "1.5px",
    lineHeight: "13px",
    textTransform: "uppercase",
  },
};
