import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHTextField from "../ReactHookFormControls/RHTextField";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { login } from "./api";
import { useAuth } from "@ui/components/AuthProvider";
import { useNavigate, Navigate } from "react-router-dom";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Paper } from "@material-ui/core/index";
import { Button } from "@material-ui/core/index";
import { updateIAMRecord } from "@ui/ComponentUtils/blueprintAPIs";
import adminPanel from "../../assets/HomePage/adminPanel.png";
import darkEllipse from "../../assets/HomePage/darkEllipse.png";
import lightEllipse from "../../assets/HomePage/lightEllipse.png";
import ChangePasswordDialog from "./ChangePasswordDialog";

const schema = yup.object({
  // phone: yup.string().length(10, "Invalid phone number").required("Required"),
  // password: yup.string().min(8).required(),
});
const StyledFormProvider = styled(FormProvider)`
  padding: 10vh;
  height: 100vh;
`;
const SignIn = ({
  title,
  message,
  iamLogin,
  clientSelect = "",
  userInfo = {},
  setUserInfo = () => {},
  additionalHeaders = {},
  host,
}) => {
  const { signin } = useAuth();
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const auth = useAuth();
  const formMethods = useForm({
    defaultValues: {},
    mode: "all",
    resolver: yupResolver(schema),
  });

  const updateNewPassword = async () => {
    try {
      if (newPassword !== confirmPassword) {
        return CustomEventEmitter.emit("alert_error", "Passwords don't match!");
      }
      setChangePasswordDialog(false);

      userInfo.changePasswordFlag = false;
      userInfo.password = newPassword;

      const token = userInfo.token;

      delete userInfo.token;

      await updateIAMRecord("users", userInfo, {
        additionalHeaders: { Authorization: token },
      });
      CustomEventEmitter.emit("alert_success", "Login with new password!");
    } catch (error) {
      console.log(error);

      CustomEventEmitter.emit("alert_error", "Something went wrong!");
    }
  };

  const onSubmit = async (values) => {
    try {
      const res = await login(
        { ...values, clientSelect, host: host },
        { iamLogin },
        additionalHeaders
      );

      if (res?.user?.changePasswordFlag) {
        setChangePasswordDialog(true);

        setUserInfo({ ...res?.user, token: JSON.stringify(res?.token) });
      } else {
        setUserInfo({ ...res?.user, token: JSON.stringify(res?.token) });
        await signin(res?.user, res?.token);
        CustomEventEmitter.emit("alert_success", "Login successful");
        // window.location.reload();
      }
    } catch (err) {
      console.log(err?.response?.data?.message);
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message || "Invalid Credentials"
      );
    }
  };

  if (auth.user) {
    return <Navigate to="/home" />;
  }

  return (
    <div style={changePasswordDialog ? { filter: "blur(5px)" } : {}}>
      <StyledFormProvider {...formMethods}>
        <Grid container spacing={2}>
          <Grid item xs={6} container justifyContent="center">
            <div style={{ margin: "auto" }}>
              <img
                src={adminPanel}
                alt="img"
                style={{ height: 497.77, width: 606 }}
              />
              <h1 style={{ color: "#14254C", textAlign: "center" }}>{title}</h1>
            </div>
          </Grid>

          <Grid item xs={6} container justifyContent="center">
            <div style={{ zIndex: 0.1 }}>
              <img
                src={lightEllipse}
                style={{
                  position: "fixed",
                  width: "435px",
                  height: "317.63px",
                  bottom: 0,
                  right: 10,
                }}
                alt="img"
              />
            </div>
            <div style={{ zIndex: 2 }}>
              <img
                src={darkEllipse}
                style={{
                  position: "fixed",
                  width: "235px",
                  height: "217.63px",
                  bottom: 10,
                  right: 10,
                }}
                alt="img"
              />
            </div>
            <div style={{ margin: "auto", zIndex: 1 }}>
              <Paper
                style={{
                  zIndex: 1,
                  margin: "40px",
                  width: "662px",
                  height: "565px",
                  boxShadow: "10px 10px 50px 20px rgba(0, 0, 0, 0.04)",
                  borderRadius: "30px",
                }}
              >
                <form
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                  style={{ padding: "60px" }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: "center", color: "#14254C" }}
                  >
                    Sign In
                  </Typography>
                  {message && (
                    <pre
                      style={{
                        textAlign: "center",
                        color: "grey",
                        // fontWeight: "bold",
                        fontSize: "18px",
                        // fontFamily:
                        //   "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                        letterSpacing: "1px",
                        // margin: "20px 0",
                      }}
                    >
                      {message}
                    </pre>
                  )}

                  <Grid
                    style={{ height: "50vh", marginTop: "50px" }}
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      xs={4}
                      direction="column"
                      style={{ maxWidth: "100%" }}
                    >
                      <RHTextField
                        name="phone"
                        label={"Phone"}
                        required
                        style={{ width: "75%" }}
                      />
                      <RHTextField
                        name="password"
                        label={"Password"}
                        type="password"
                        required
                        style={{ width: "75%" }}
                      />
                      {/* <Link to={"/sendOtp"}>Forgot Password</Link> */}
                    </Grid>
                    <Button
                      type="submit"
                      variant="container"
                      style={{
                        marginTop: "50px",
                        color: "white",
                        backgroundColor: "#7CD194",
                      }}
                    >
                      Sign In
                    </Button>
                  </Grid>
                </form>
              </Paper>
            </div>
          </Grid>
        </Grid>

        {changePasswordDialog ? (
          <ChangePasswordDialog
            changePasswordDialog={changePasswordDialog}
            setChangePasswordDialog={setChangePasswordDialog}
            updateNewPassword={updateNewPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
        ) : null}
      </StyledFormProvider>
    </div>
  );
};

export default SignIn;
