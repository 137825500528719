import Label from "@ui/components/Label";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import React from "react";
import { useFormContext } from "react-hook-form";

const PrivacyPolicy = ({ isEditable }) => {
  const values = useFormContext().getValues();

  return (
    <MainSection
      title={"Privacy Policy"}
      style={{ marginBottom: "24px" }}
      actions={[]}
    >
      {isEditable ? (
        <RHTextField name="privacyPolicy" minRows={5} multiline />
      ) : (
        <Label style={{ marginTop: "-24px" }} value={values.privacyPolicy} />
      )}
    </MainSection>
  );
};

export default PrivacyPolicy;
