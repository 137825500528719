import React, { useEffect, useState } from "react";
import enums from "helpers/enums";
import {
  copyToClipboard,
  exportExcel,
  formatDateToRead,
  isAdmin,
  isDivisionControlled,
  isSuperAdmin,
  selectedDivisionId,
  userDetails,
} from "@ui/Utils/helper";
import { isVendor } from "@ui/Utils/helper";
import Header from "@ui/ComponentUtils/Header";
import {
  createRecord,
  getSingleRecord,
  search,
} from "@ui/ComponentUtils/blueprintAPIs";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import { syncExternalRecords } from "../api";
import { Button, Grid, Tooltip } from "@material-ui/core";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

import moment from "moment";
import { getProductViewAccess, orderedStatusArray } from "../Detail/Detail";
import BackgroundImage from "../assets/bground.png";
import CollapseList from "./CollapseList";

export const getOverDueColor = (dueDate) => {
  dueDate = dueDate && moment(dueDate);

  let difference = 10000;

  if (dueDate) {
    difference = dueDate.diff(new Date(), "days");
  }

  if (difference < 0) {
    return "#ff2919";
  } else if (difference >= 0 && difference <= 3) {
    return "#ff6e19";
  }

  return "black";
};

const QuotationsList = () => {
  const model = enums.models["vendor-portal"]?.quotations;

  const [data, setData] = useState([]);
  const [constData, setConstData] = useState([]);
  const [tab, setTab] = useState("In Progress");
  const [totalCalc, setTotalCalc] = useState({
    grossWeight: 0,
    netWeight: 0,
  });
  const [currentEditRow, setCurrentEditRow] = React.useState("none");
  const [clientSetting, setClientSetting] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await getSingleRecord(enums.models["vendor-portal"]?.settings)) ||
          {};
        if (res?._id) {
          setClientSetting(res);
        } else {
          await createRecord(enums.models["vendor-portal"]?.settings, {
            quotationView: { defaultView: true },
          });
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const columns = [
    {
      title: "Recvd.",
      // render: (row) => (
      //   <div>
      //     {/* <Tooltip title="View Products">
      //       <IconButton
      //         color="inherit"
      //         sx={
      //           {
      //             // mr: 2,
      //             // display: { sm: "none" }
      //           }
      //         }
      //         onClick={() => navigateToDetails(row)}
      //         // onMouseEnter={() => setOnHover(row.tableData?.id)}
      //         // onMouseLeave={() => setOnHover(false)}
      //         edge="start"
      //       >
      //         {onHover === row.tableData?.id ? (
      //           <CategoryIcon style={{ cursor: "pointer" }} />
      //         ) : (
      //           <CategoryOutlinedIcon style={{ cursor: "pointer" }} />
      //         )}
      //       </IconButton>
      //     </Tooltip> */}

      //     {/* {loading && activeRow?.tableData?.id === row?.tableData?.id ? (
      //       <CircularProgress style={{ color: "gold" }} />
      //     ) : (
      //       (isVendor
      //         ? !row?.quotationProducts
      //             ?.filter((prod) => prod?.owner === row?.vendorCode)
      //             ?.find(
      //               (prod) =>
      //                 prod?.orderStatus === enums["vendor-portal"]?.orderStatus?.assigned ||
      //                 prod?.orderStatus === enums["vendor-portal"]?.orderStatus?.pending ||
      //                 prod?.orderStatus === enums["vendor-portal"]?.orderStatus?.canceled
      //             )
      //         : true) && (
      //         <Tooltip title="Download PDF">
      //           <IconButton
      //             color="inherit"
      //             sx={{
      //               mr: 2,
      //               // display: { sm: "none" }
      //             }}
      //             onClick={() => {
      //               setActiveRow(row);
      //               downloadPdf(row);
      //             }}
      //             // onMouseEnter={() => setOnHoverPdf(row.tableData?.id)}
      //             // onMouseLeave={() => setOnHoverPdf(false)}
      //             edge="start"
      //           >
      //             {onHoverPdf === row.tableData?.id ? (
      //               <PictureAsPdfIcon style={{ cursor: "pointer" }} />
      //             ) : (
      //               <PictureAsPdfOutlinedIcon style={{ cursor: "pointer" }} />
      //             )}
      //           </IconButton>
      //         </Tooltip>
      //       )
      //     )} */}
      //   </div>
      // ),
    },
    {
      title: clientSetting?.quotationView?.priorityView ? "Priority" : "SrNo",
      field: clientSetting?.quotationView?.priorityView
        ? "priority"
        : "tableData.id",
      // render: (row) => {
      //   if (clientSetting?.quotationView?.priorityView) {
      //     return row.priority;
      //   } else {
      //     const text = (row.tableData?.id || 0) + 1;
      //     return <div onClick={() => navigateToDetails(row)}>{text || ""}</div>;
      //   }
      // },
    },
    {
      title: "OrderID",
      field: "quotationNo",
    },
    {
      title: "Vendor",
      field: "vendorCode",
    },
    {
      title: "Gross \n Net",
    },

    {
      title: "Remarks",
      field: "remarks",
    },
    {
      title: "Order Date",
      field: "orderDate",
    },
    {
      title: "Due Date",
    },
    {
      title: "Status",
    },
  ];

  if (!isVendor) {
    columns.splice(
      2,
      0,
      {
        title: "Client",
        field: "customer.name",
      },
      {
        title: "Zone",
        field: "customer.address.line1",
      }
    );
  }

  // const downloadPdf = async (row) => {
  //   setLoading(true);

  //   try {
  //     let pdfProducts =
  //       row?.quotationProducts?.filter(
  //         (prod) => prod?.owner === row?.vendorCode
  //       ) || [];
  //     pdfProducts = pdfProducts.map((prod) => {
  //       return {
  //         ...prod,
  //         dueDate: prod?.dueDate ? formatDateToRead(prod?.dueDate) : "",
  //         metalPurity: displayMeltingAsPerClient(prod?.metalPurity),
  //       };
  //     });

  //     const orderDate = row.createdAt ? formatDateToRead(row.createdAt) : "";

  //     const res = await getPdfReport(enums.models["vendor-portal"]?.quotations, {
  //       data: {
  //         ...row,
  //         createdAt: orderDate,
  //         quotationProducts: pdfProducts,
  //       },
  //       template: enums.pdfTemplates.vendorQuotation,
  //       pdfTitle: "Report",
  //     });

  //     generatePDF(res, row.quotationNo + "__" + row.vendorCode);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSync = async () => {
    try {
      const res = await syncExternalRecords(
        enums.models["vendor-portal"]?.quotations
      );
      let totalGrossWeight = 0,
        totalNetWeight = 0;

      const filteredQuotations = (res?.data || []).filter(
        (quotation) =>
          (tab === "Completed" ? quotation.received : !quotation.received) &&
          (clientSetting?.quotationView?.priorityView
            ? !!quotation?.priority
            : true)
      );

      filteredQuotations.map((quotation) => {
        (quotation.products || []).map((prod) => {
          totalGrossWeight += prod.grossWeight || 0;
          totalNetWeight += prod.netWeight || 0;
        });
      });

      if (!clientSetting?.quotationView?.priorityView) {
        setTotalCalc({
          grossWeight: totalGrossWeight,
          netWeight: totalNetWeight,
        });
      }

      CustomEventEmitter.emit(
        "alert_success",
        `Sync Successfull for ${res?.data?.length} records`
      );
      window.location.reload();
    } catch (err) {
      console.log(err);

      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message?.toString()
      );
    }
  };

  const downloadTopDesigns = () => {
    try {
      const topDesigns = [],
        designObj = {};
      (data || []).map((quotation) => {
        (quotation.products || []).map((prod) => {
          designObj[prod.styleNo] = designObj[prod.styleNo] || 0;
          designObj[prod.styleNo] += Number(prod.quantity);
        });
      });

      Object.keys(designObj).map((design) => {
        topDesigns.push({ design, quantity: designObj[design] });
      });
      topDesigns.sort((a, b) => b.quantity - a.quantity);

      exportExcel(
        [
          {
            data: topDesigns,
            columns: [
              { title: "Design", field: "design", width: 20 },
              { title: "Quantity", field: "quantity", width: 20 },
            ],
            sheetName: "Top Designs",
          },
        ],
        "Top Designs Report"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const downloadQuotationReport = () => {
    try {
      const reportArr = [];

      data.map((quot) => {
        (quot.products || []).map((prod) => {
          reportArr.push({
            quotationNo: quot.quotationNo,
            vendor: quot.vendorCode,
            customer: quot.customer?.name,
            styleNo: prod.styleNo,
            grossWeight: prod.grossWeight,
            netWeight: prod.netWeight,
            color: prod.metalType,
            purity: prod.metalPurity,
            quantity: prod.quantity,
            orderDate: formatDateToRead(quot.orderDate),
          });
        });
      });

      exportExcel(
        [
          {
            data: reportArr,
            columns: [
              { title: "Quotation No", field: "quotationNo", width: 20 },
              {
                title: "Design",
                field: "styleNo",
                width: 20,
              },
              { title: "Vendor", field: "vendor", width: 20 },
              { title: "Customer", field: "customer", width: 20 },
              { title: "Gross Weight", field: "grossWeight", width: 20 },
              { title: "Net Weight", field: "netWeight", width: 20 },
              { title: "Color", field: "color", width: 20 },
              { title: "Purity", field: "purity", width: 20 },
              { title: "Quantity", field: "quantity", width: 20 },
              { title: "Order Date", field: "orderDate", width: 20 },
            ],
            sheetName: "Quotations",
          },
        ],
        "Quotations Report"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const downloadClientsReport = () => {
    try {
      const nameObj = {};

      (data || []).map((quot) => {
        if (quot.customer?.name) {
          nameObj[quot.customer.name] = nameObj[quot.customer?.name] || {};

          nameObj[quot.customer.name].name = quot.customer?.name;
          nameObj[quot.customer.name].contactName = quot.customer?.contactName;
          nameObj[quot.customer.name].phone = quot.customer?.phone;
          nameObj[quot.customer.name].line1 = quot.customer?.line1;
          nameObj[quot.customer.name].line2 = quot.customer?.line2;
          nameObj[quot.customer.name].state = quot.customer?.state;
          nameObj[quot.customer.name].city = quot.customer?.city;
          nameObj[quot.customer.name].country = quot.customer?.country;
          nameObj[quot.customer.name].pincode = quot.customer?.pincode;
        }
      });

      const reportArr = [];
      Object.keys(nameObj).map((name) => reportArr.push(nameObj[name]));

      exportExcel(
        [
          {
            data: reportArr,
            columns: [
              { title: "Name", field: "name", width: 20 },
              { title: "Contact name", field: "contactName", width: 20 },
              { title: "Phone", field: "phone", width: 20 },
              { title: "Address", field: "line1", width: 20 },
              { title: "Address Line 2", field: "line2", width: 20 },
              { title: "State", field: "state", width: 20 },
              { title: "City", field: "city", width: 20 },
              { title: "Country", field: "country", width: 20 },
              { title: "Pincode", field: "pincode", width: 20 },
            ],
            sheetName: "Clients",
          },
        ],
        "Clients Report"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const headerActions = [
    {
      label: "Top_Designs",
      iconName: "download",
      tooltip: "Download Top Designs",
      onClick: downloadTopDesigns,
      hidden: !(isAdmin || isSuperAdmin) || currentEditRow !== "none",
      color: "primary",
    },
    {
      label: "Quotations",
      iconName: "download",
      tooltip: "Download Quotations Report",
      onClick: downloadQuotationReport,
      hidden: !(isAdmin || isSuperAdmin) || currentEditRow !== "none",
      color: "primary",
    },
    {
      label: "Clients",
      iconName: "download",
      tooltip: "Download Clients Report",
      onClick: downloadClientsReport,
      hidden: !(isAdmin || isSuperAdmin) || currentEditRow !== "none",
      color: "primary",
    },
    {
      label: "Sync",
      iconName: "sync",
      tooltip: "Sync from Diva",
      onClick: handleSync,
      hidden:
        isVendor ||
        !userDetails?.client?.appConfig?.["data-engine"]?.divisionLevel?.[
          isDivisionControlled ? selectedDivisionId : "default"
        ]?.integrateWithDiva ||
        currentEditRow !== "none",
    },
  ];

  const getData = async (setting, criteria = {}) => {
    if (setting?.quotationView?.priorityView) {
      criteria.priority = { $exists: true };
    }
    criteria.received = tab === "In Progress" ? { $ne: true } : true;
    criteria.products = { $exists: true, $ne: [] };

    criteria.sort = { _id: -1 };

    if (isVendor) {
      criteria.vendorCode = {
        $in: userDetails?.vendorCode,
      };
      criteria["products.orderStatus"] = {
        $in: Object.values(enums["vendor-portal"]?.orderStatus).filter(
          (status) => status !== enums["vendor-portal"]?.orderStatus?.pending
        ),
      };
    }

    let res = await search(model, criteria);

    let totalGrossWeight = 0,
      totalNetWeight = 0;

    res.map((quot) => {
      (quot.products || []).map((prod) => {
        totalGrossWeight += prod.grossWeight || 0;
        totalNetWeight += prod.netWeight || 0;
      });
    });

    setTotalCalc({ grossWeight: totalGrossWeight, netWeight: totalNetWeight });

    setData(res || []);
    setConstData(res || []);
  };

  useEffect(() => {
    if (clientSetting?._id) {
      if (clientSetting?.quotationView?.priorityView) {
        getData(clientSetting);
      } else {
        getData();
      }
    }
  }, [tab, clientSetting]);

  const links = [
    {
      name: "Quotation",
      url: "#/" + model,
      isDisplayLink: true,
    },
  ];

  if (!clientSetting?._id) {
    return "Loading...";
  }

  if (
    !userDetails?.client?.appConfig?.["data-engine"]?.divisionLevel?.[
      isDivisionControlled ? selectedDivisionId : "default"
    ]?.integrateWithDiva
  ) {
    return (
      <div>
        <img
          style={{
            height: "60vh",
            width: "60vw",
            position: "fixed",
          }}
          src={BackgroundImage}
        />
        <h3>Subscribe for Exhibition</h3>
        <div style={{ float: "right", marginTop: 300, paddingRight: 150 }}>
          <h3 style={{ color: "#3b6609" }}>+91 - 9920356121</h3>
          <h3 style={{ color: "#3b6609" }}>Aayush Shah</h3>
          <h5 style={{ color: "#3b6609", marginTop: -10 }}>Sheen AI</h5>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header
        links={[]}
        pageTitle={"Exhibition Orders"}
        actions={headerActions}
      />

      {currentEditRow === "none" && (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: tab === "Completed" ? "#7CD093" : "black",
                color: "white",
              }}
              onClick={() => setTab("Completed")}
            >
              Completed Orders
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: tab === "In Progress" ? "#7CD093" : "black",
                color: "white",
              }}
              onClick={() => setTab("In Progress")}
            >
              In Progress
            </Button>
          </Grid>
        </Grid>
      )}

      <div style={{ marginTop: "16px" }}>
        <CollapseList
          data={data}
          setData={setData}
          constData={constData}
          columns={columns}
          title={
            isNaN(currentEditRow)
              ? `
        Gross: ${totalCalc.grossWeight?.toFixed(3)} 
        <<< -- >>>
        ${totalCalc.netWeight?.toFixed(3)} :Net`
              : `${data[currentEditRow].quotationNo} - ${data[currentEditRow].vendorCode}`
          }
          getData={getData}
          setTotalCalc={setTotalCalc}
          clientSetting={clientSetting}
          currentEditRow={currentEditRow}
          setCurrentEditRow={setCurrentEditRow}
        />
      </div>
    </div>
  );
};

export const CustomCopyIcon = ({ text }) => {
  const [hover, setHover] = useState(false);

  if (!text?.toString().trim()) return null;
  return (
    <div>
      <Tooltip title="Copy">
        {hover ? (
          <FileCopyIcon
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              copyToClipboard(text);
            }}
            style={{ cursor: "copy" }}
          />
        ) : (
          <FileCopyOutlinedIcon
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              copyToClipboard(text);
            }}
            style={{ cursor: "copy" }}
          />
        )}
      </Tooltip>
    </div>
  );
};

export default QuotationsList;
