import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHTextField from "../ReactHookFormControls/RHTextField";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import Button from "../../components/Button";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { sendOTP, signup, verifyOTP } from "./api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { useAuth } from "@ui/components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  otp: yup.string().required("Required"),
});
const StyledFormProvider = styled(FormProvider)`
  padding: 10vh;
  height: 100vh;
`;
const OTP = () => {
  const formMethods = useForm({
    defaultValues: {},
    mode: "all",
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();

  const values = location?.state;
  const phone = values?.phone;

  useEffect(() => {
    sendOTP({ phone });
    if (!phone) {
      navigate("/");
    }
  }, [phone]);

  if (auth.user) {
    return <Navigate to="/home" />;
  }

  const onSubmit = async (values) => {
    try {
      await verifyOTP({ phone, otp: values.otp });
      CustomEventEmitter.emit("alert_success", "OTP verified");

      await signup(values);

      navigate(location.state.navigateTo || "/signIn", {
        state: { phone: phone },
      });
    } catch (err) {
      CustomEventEmitter.emit("alert_error", "Something went wrong!");
      console.log(err);
    }
  };
  return (
    <StyledFormProvider {...formMethods}>
      <Typography variant="h4" gutterBottom>
        OTP
      </Typography>
      <Grid
        style={{ height: "50vh", marginTop: 10 }}
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Grid item container justifyContent="center">
          <RHTextField name="otp" label={"Enter OTP"} />

          <a
            style={{ marginTop: 20 }}
            onClick={() => {
              sendOTP({ phone });
            }}
          >
            Send Again?
          </a>
        </Grid>
        <Button onClick={formMethods.handleSubmit(onSubmit)}>Next</Button>
      </Grid>
    </StyledFormProvider>
  );
};

export default OTP;
