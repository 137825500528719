import React from "react";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import SportsHockeyIcon from "@mui/icons-material/SportsHockey";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HailIcon from "@mui/icons-material/Hail";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import GroupIcon from "@mui/icons-material/Group";
import BadgeIcon from "@mui/icons-material/Badge";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import FestivalIcon from "@mui/icons-material/Festival";
import FestivalOutlinedIcon from "@mui/icons-material/FestivalOutlined";
// Components
import HomePage from "pages/HomePage/HomePage";
import ShopOutlinedIcon from "@mui/icons-material/ShopOutlined";
import ShopIcon from "@mui/icons-material/Shop";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import FunctionsIcon from "@mui/icons-material/Functions";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
// utils
import OverviewDetail from "views/Overview/Detail/Detail";
import CataloguesList from "views/Catalogues/List/List";
import CatalogueDetail from "views/Catalogues/Detail/Detail";
import OrdersList from "@ui/views/e-creatives/Orders/List/List";
import OrderDetail from "@ui/views/e-creatives/Orders/Detail/Detail";
import GroupDetail from "views/Groups/Detail/Detail";
import GroupsList from "views/Groups/List/List";
import enums from "helpers/enums";
import CustomersList from "@ui/views/data-engine/Customers/List/List";
import CustomerDetail from "@ui/views/data-engine/Customers/Detail/Detail";
import AlertsList from "views/Alerts/List/List";
import AlertDetail from "views/Alerts/Detail/Detail";
import UsersList from "views/Users/List/List";
import UserDetail from "views/Users/Detail/Detail";
import VendorsList from "@ui/views/vendor-portal/Vendors/List/List";
import VendorDetail from "@ui/views/vendor-portal/Vendors/Detail/Detail";
import QuotationsList from "@ui/views/vendor-portal/Quotation/List/List";
import QuotationDetail from "@ui/views/vendor-portal/Quotation/Detail/Detail";
import SettingsList from "@ui/views/vendor-portal/Settings/List/List";
import { isAdmin, isSuperAdmin, userIdentifier } from "@ui/Utils/helper";
import AppConfigDetail from "views/AppConfig/Detail/Detail";
import StockOrdersList from "@ui/views/e-creatives/StockOrders/List/List";
import StockOrderDetail from "@ui/views/e-creatives/StockOrders/Detail/Detail";
import CustomOrdersList from "@ui/views/e-creatives/CustomOrders/List/List";
import CustomOrderDetail from "@ui/views/e-creatives/CustomOrders/Detail/Detail";
import CatalogueQuotationsList from "@ui/views/e-creatives/CatalogueQuotations/List/List";
import CatalogueQuotationDetail from "@ui/views/e-creatives/CatalogueQuotations/Detail/Detail";
import LinkItOrdersList from "@ui/views/e-creatives/LinkIt/List/List";
import LinkItOrderDetail from "@ui/views/e-creatives/LinkIt/Detail/Detail";
import ExpiryLinkDetail from "@ui/views/e-creatives/LinkIt/ExpiryLinkDetail/Detail";
import ExhibitionQuotationsList from "@ui/views/e-creatives/ExhibitionQuotations/List/List";
import ExhibitionQuotationDetail from "@ui/views/e-creatives/ExhibitionQuotations/Detail/Detail";

const itsStyle = { color: enums.colorTheme.primary };

const defaultAccess = isAdmin || isSuperAdmin;

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Dashboard", // dash it ;)
    titleStyle: { color: "grey" },
    path: "e-creatives/dashboard",
    icon: () => <DashboardIcon />,
    selectedIcon: () => <DashboardIcon />,
    component: () => <HomePage />,
    hideMenuByDefault: true,
  },
  {
    access: defaultAccess,
    title: "Alerts",
    path: enums.models.alerts,
    icon: () => <NotificationsActiveOutlinedIcon />,
    selectedIcon: () => <NotificationsActiveOutlinedIcon />,
    component: () => (defaultAccess ? <AlertsList /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "App Config",
    path: enums.models.appConfig,
    icon: () => <SystemSecurityUpdateGoodIcon />,
    component: () => (defaultAccess ? <AppConfigDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Catalogues",
    path: enums.models.catalogues,
    icon: () => <VpnKeyOutlinedIcon style={{ color: "red" }} />,
    selectedIcon: () => <VpnKeyIcon style={{ color: "red" }} />,
    component: () => (defaultAccess ? <CataloguesList /> : <HomePage />),
  },
  {
    access:
      defaultAccess ||
      userIdentifier.isSalesPerson ||
      userIdentifier.isSalesHead,
    title: "Customers",
    path: enums.models.users,
    icon: () => <HailIcon />,
    component: () =>
      defaultAccess ||
      userIdentifier.isSalesPerson ||
      userIdentifier.isSalesHead ? (
        <CustomersList />
      ) : (
        <HomePage />
      ),
  },
  {
    access: true,
    title: "Custom Os",
    titleStyle: itsStyle,
    path: enums.models.customOrders,
    icon: () => <FunctionsOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <FunctionsIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => <CustomOrdersList />,
  },
  {
    access: defaultAccess,
    title: "Stock Os",
    titleStyle: itsStyle,
    path: enums.models.stockOrders,
    icon: () => <ShoppingBasketOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <ShoppingBasketIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => (defaultAccess ? <StockOrdersList /> : <HomePage />),
  },

  {
    access: defaultAccess,
    title: "Diva",
    titleStyle: itsStyle,
    path: enums.models["vendor-portal"].quotations,
    icon: () => <ShopOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <ShopIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => (defaultAccess ? <QuotationsList /> : <HomePage />),
  },

  {
    access: defaultAccess,
    title: "Exhibit IT",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].exhibitionQuotations,
    icon: () => <FestivalOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <FestivalIcon style={itsStyle} />,
    component: () => <ExhibitionQuotationsList />,
  },

  {
    access: defaultAccess,
    title: "Link IT",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].linkItOrders,
    icon: () => <SportsHockeyIcon style={itsStyle} />,
    selectedIcon: () => <SportsHockeyIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => (defaultAccess ? <LinkItOrdersList /> : <HomePage />),
  },

  {
    access: true,
    title: "Quote IT",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].catalogueQuotations,
    icon: () => <RequestQuoteOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <RequestQuoteIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => <CatalogueQuotationsList />,
  },
  {
    access: defaultAccess,
    title: "E-Commerce",
    titleStyle: {
      wordBreak: "keep-all",
      whiteSpace: "nowrap",
      ...itsStyle,
    },
    path: enums.models.orders,
    icon: () => <ShoppingCartOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <ShoppingCartIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => (defaultAccess ? <OrdersList /> : <HomePage />),
  },

  {
    access: defaultAccess,
    title: "Settings",
    path: enums.models["vendor-portal"].settings,
    icon: () => <SettingsSuggestOutlinedIcon />,
    selectedIcon: () => <SettingsSuggestIcon />,
    component: () => (defaultAccess ? <SettingsList /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Specials",
    path: enums.models.groups,
    icon: () => <FormatListNumberedIcon />,
    component: () => (defaultAccess ? <GroupsList /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Support",
    path: enums.models.overviews,
    icon: () => <AppShortcutIcon />,
    component: () => (defaultAccess ? <OverviewDetail /> : <HomePage />),
  },
  {
    access: false, //isAdmin || isSuperAdmin,
    title: "Users",
    path: enums.models.iam.users,
    icon: () => <GroupIcon />,
    component: () => (defaultAccess ? <UsersList /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Vendors",
    path: enums.models["vendor-portal"].users,
    icon: () => <BadgeIcon />,
    component: () => (defaultAccess ? <VendorsList /> : <HomePage />),
  },
];

export const secondaryMenuRoutes = [
  {
    access: defaultAccess,
    title: "Alerts",
    path: enums.models.alerts + "/:code",
    component: () => (defaultAccess ? <AlertDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "E-Commerce",
    path: enums.models.orders + "/:code",
    component: () => (defaultAccess ? <OrderDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "LINK it",
    path: enums.models["e-creatives"].linkItOrders + "/:code",
    component: () => (defaultAccess ? <LinkItOrderDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "EXPIRE it",
    path: enums.models["e-creatives"].expiryLinks + "/:code",
    component: () => (defaultAccess ? <ExpiryLinkDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Stock Order",
    path: enums.models.stockOrders + "/:code",
    component: () => (defaultAccess ? <StockOrderDetail /> : <HomePage />),
  },
  {
    access: true,
    title: "Custom Order",
    path: enums.models.customOrders + "/:code",
    component: () => (true ? <CustomOrderDetail /> : <HomePage />),
  },
  {
    access: true,
    title: "Quote it",
    path: enums.models["e-creatives"].catalogueQuotations + "/:code",
    component: () => (true ? <CatalogueQuotationDetail /> : <HomePage />),
  },
  {
    access: true,
    title: "Exhibit it",
    path: enums.models["e-creatives"].exhibitionQuotations + "/:code",
    component: () => (true ? <ExhibitionQuotationDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Exhibition",
    path: enums.models["vendor-portal"].quotations + "/:code",
    component: () => (defaultAccess ? <QuotationDetail /> : <HomePage />),
  },
  {
    access:
      defaultAccess ||
      userIdentifier.isSalesPerson ||
      userIdentifier.isSalesHead,
    title: "Customers",
    path: enums.models.users + "/:code",
    component: () =>
      defaultAccess ||
      userIdentifier.isSalesPerson ||
      userIdentifier.isSalesHead ? (
        <CustomerDetail />
      ) : (
        <HomePage />
      ),
  },
  {
    access: defaultAccess,
    title: "Catalogues",
    path: enums.models.catalogues + "/:code",
    component: () => (defaultAccess ? <CatalogueDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Specials",
    path: enums.models.groups + "/:code",
    component: () => (defaultAccess ? <GroupDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Overview",
    path: enums.models.overviews + "/:code",
    component: () => (defaultAccess ? <OverviewDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "App Config",
    path: enums.models.appConfig + "/:code",
    icon: () => <SystemSecurityUpdateGoodIcon />,
    component: () => (defaultAccess ? <AppConfigDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Users",
    path: enums.models.iam.users + "/:code",
    component: () => (defaultAccess ? <UserDetail /> : <HomePage />),
  },
  {
    access: defaultAccess,
    title: "Vendors",
    path: enums.models["vendor-portal"].users + "/:code",
    component: () => (defaultAccess ? <VendorDetail /> : <HomePage />),
  },
];
