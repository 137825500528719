import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { isVendor, userDetails } from "@ui/Utils/helper";
import { updateRecordWithHook, getByCode } from "../../api";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {};

const LinkItOrderDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const model = enums.models["e-creatives"]?.linkItOrders;
  const params = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          setLoading(true);
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setInitialValues(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
      setLoading(false);
    })();
  }, [params?.code]);

  const onSubmit = async (values) => {
    try {
      let res = {},
        action = "Created",
        itemIndex = { index: "", orderStatus: "" };

      // const invalidItem = (values.items || []).find((item, index) => {
      //   if (
      //     item.orderStatus === enums.orderStatus.delivered &&
      //     !item.issuedStocks?.length
      //   ) {
      //     itemIndex = { index, orderStatus: item.orderStatus };
      //     return true;
      //   }

      //   if (
      //     item.orderStatus === enums.orderStatus.repair &&
      //     !item.repairStocks?.length
      //   ) {
      //     itemIndex = { index, orderStatus: item.orderStatus };
      //     return true;
      //   }

      //   if (
      //     item.orderStatus === enums.orderStatus.returned &&
      //     !item.returnedStocks?.length
      //   ) {
      //     itemIndex = { index, orderStatus: item.orderStatus };
      //     return true;
      //   }
      // });

      // if (invalidItem) {
      //   return CustomEventEmitter.emit(
      //     "alert_error",
      //     `Fill ${itemIndex.orderStatus} Stocks for Item: ${
      //       itemIndex.index + 1
      //     } to Continue...`
      //   );
      // }

      if (values._id) {
        res = await updateRecordWithHook(
          enums.models["e-creatives"]?.linkItOrders,
          values
        );
        action = "Updated";

        formMethods.reset(res);
        setIsEditable(false);
      }

      CustomEventEmitter.emit("alert_success", action + " Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        loading={loading}
        setIsEditable={setIsEditable}
        initialValues={initialValues}
      />
    </FormProvider>
  );
};

export const defaultFilter = (item) => {
  if (isVendor) {
    return (userDetails.vendorCode || []).find(
      (vCode) =>
        vCode === item.vendorCode &&
        item.orderStatus &&
        item.orderStatus !== enums["vendor-portal"].orderStatus.pending
    );
    // &&
    // (isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    //   : true)
  } else {
    return true;
    // isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    // : ;
  }
};

export default LinkItOrderDetail;
