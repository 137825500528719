import React, { useState } from "react";
import { Controller } from "react-hook-form";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Grid, Tooltip } from "@material-ui/core";

import {
  uploadFiles,
  verifyUploadFiles,
} from "@ui/ComponentUtils/blueprintAPIs";
import { MyCarousel } from "@ui/MuiComponents/index";
import DocUpload from "@ui/Controls/Upload/DocUpload";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import Label from "@ui/components/Label";
import { imageExpired } from "@ui/Utils/helper";

const RHDocUpload = ({
  code,
  name,
  model,
  disabled,
  single,
  isEditable,
  dontUpload = false, // when you dont want this component to upload onChange, send this prop as true so that it will save the files to your form field name array.
  uploadIcon,
  // uploadIcon: {
  //   type: "png",
  //   icon: "icon"
  // },
  hideUploadIcon,
  enableRemove,
  label,
  required,
  previewThumbnail,
  previewHeight,
  previewWidth,
  previewStyle = {},
  rules,
  accept,
  onVerify,
  additionalPath,
  replace = false,
  onUpload = () => {},
  handleRemoveUploadedDoc,
  tooltip,
}) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [files, setFiles] = useState([]);

  const handleUpload = async (value, onChange) => {
    if (files?.length > 0) {
      try {
        let response;

        if (dontUpload) {
          onUpload();
          const tempFiles = Array.from(files).filter((file) => {
            if (file.size <= 5242880) {
              return true;
            } else {
              CustomEventEmitter.emit(
                "alert_error",
                "Skipped file more than 5mb..."
              );
              return false;
            }
          });

          if (single) {
            if (tempFiles?.[0]) {
              return onChange(tempFiles?.[0]);
            }
            return;
          } else {
            return onChange(tempFiles);
          }
        } else if (!!onVerify) {
          return onVerify(
            await verifyUploadFiles(model, files, { additionalPath })
          );
        }

        const payloadLimit = 5,
          reportArray = []; // in mb.
        const total = files?.length || 0;
        let chunkDimension = 0,
          paginatedArray = [[]],
          fileSizeSum = 0;

        for (let i = 0; i < files.length; i++) {
          const file = files[i],
            fileSize = Number(file.size) / 1000000; // bytes => mb conversion / 1000000

          if (fileSize > payloadLimit) {
            reportArray.unshift({
              fileName: file.name,
              large: true,
            });

            CustomEventEmitter.emit(
              "alert_error",
              "File Size Limit Exceeded: 5mb"
            );
          } else if (fileSizeSum + fileSize < payloadLimit) {
            fileSizeSum += fileSize;
            paginatedArray[chunkDimension] =
              paginatedArray[chunkDimension] || [];
            paginatedArray[chunkDimension].push(file);
          } else {
            i--;
            fileSizeSum = 0;
            chunkDimension++;
          }
        }

        let currentDocs = value || (single ? {} : []);

        if (single && paginatedArray[0]?.length) {
          response = await uploadFiles(model, paginatedArray[0], {
            additionalPath: additionalPath,
            replace,
            code: code,
          });

          const data = response || [];
          currentDocs = data[0];
          reportArray.push({ ...data[0], success: true });
        } else {
          for (let p = 0; p < paginatedArray.length; p++) {
            if (paginatedArray[p].length) {
              response = await uploadFiles(model, paginatedArray[p], {
                additionalPath: additionalPath,
                replace,
                code: code,
              });

              const data = response || [];

              data.map((imgData) => {
                let duplicateFlag = false;

                if (replace) {
                  // For REPLACE Case
                  currentDocs.find((doc, docIndex) => {
                    if (doc.fileName === imgData.fileName) {
                      currentDocs[docIndex] = {
                        fileName: imgData.fileName,
                        fileType: imgData.fileType,
                        location: imgData.location,
                        signedUrl: imgData.signedUrl,
                      };
                      duplicateFlag = true;
                    }
                  });
                }

                if (!duplicateFlag) {
                  currentDocs.push({
                    fileName: imgData.fileName,
                    fileType: imgData.fileType,
                    location: imgData.location,
                    signedUrl: imgData.signedUrl,
                  });

                  reportArray.push({ ...imgData, success: true });
                } else reportArray.push({ ...imgData, duplicate: true });
              });
            }
          }
        }

        const messageObj = {
          success: { count: 0, files: [] },
          large: { count: 0, files: [] },
          duplicate: { count: 0, files: [] },
          unknown: { count: 0, files: [] },
        };

        reportArray.map((report) => {
          if (report.success) {
            messageObj.success.count += 1;
            messageObj.success.files.push(report);
          } else if (report.large) {
            messageObj.large.count += 1;
            messageObj.large.files.push(report);
          } else if (report.duplicate) {
            messageObj.duplicate.count += 1;
            messageObj.duplicate.files.push(report);
          } else {
            messageObj.unknown.count += 0;
            messageObj.unknown.files.push(report);
          }
        });

        // window.alert(
        //   `Uploaded: ${messageObj.success.count}\nLarge Files: ${
        //     messageObj.large.count +
        //     ` { ${messageObj.large.files
        //       .map((file) => file?.fileName)
        //       .join(", ")} }`
        //   }\nTotal: ${total}\n${
        //     messageObj.duplicate.count
        //       ? `, Duplicate: ${messageObj.duplicate.count}`
        //       : ""
        //   }${
        //     messageObj.unknown.count
        //       ? `, Unknown: ${messageObj.unknown.count}`
        //       : ""
        //   }`
        // );
        onChange(currentDocs);
        if (onUpload) onUpload(currentDocs, reportArray);

        messageObj.success.count &&
          CustomEventEmitter.emit(
            "alert_success",
            `Uploaded ${messageObj.success.count} Files Successfully`
          );
      } catch (err) {
        console.log(err);
        CustomEventEmitter.emit(
          "alert_error",
          err?.response?.data?.message || "Something went wrong"
        );
        // RM.CustomEventEmitter.emit('alert_error', 'Error in Upload!');
      }
    }
  };

  const removeUploadedDoc = async (index, items, onChange) => {
    let confirmResult = false;
    try {
      confirmResult = await window.confirm("Delete Document! Are you sure?");
      if (confirmResult) {
        let data;
        if (single) {
          data = {};
        } else {
          data = items.map((field) => ({ ...field }));

          data.splice(index, 1);
        }
        // setValue(name, data);
        onChange(data);
      }
    } catch (err) {
      confirmResult = false;
    }
  };

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <Grid container>
          {label && <Label label={label} />}
          <Grid item>
            <DocUpload
              uploadIcon={uploadIcon}
              disabled={disabled}
              multiple={!single}
              tooltip={tooltip}
              files={files}
              hideUploadIcon={!label || hideUploadIcon}
              accept={accept}
              setFiles={setFiles}
              handleUpload={() => handleUpload(field.value, field.onChange)}
            />
            {dontUpload &&
              Array.from((single ? [field.value] : field.value) || [])
                .map((file) => file?.name)
                .join(", ")}
          </Grid>
          {required &&
            !(single ? field.value?.signedUrl : field.value?.length > 0) && (
              <p style={{ color: "red" }}>Required *</p>
            )}

          {(single ? field.value?.signedUrl : field.value?.length > 0) && (
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setOpenPreview(true)}
            >
              <Tooltip title="Preview">
                {previewThumbnail ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        height: previewHeight || 250,
                        width: previewWidth || 250,
                        objectFit: "contain",
                        ...previewStyle,
                      }}
                      alt="img"
                      src={
                        single
                          ? field.value?.signedUrl
                          : field.value[0]?.signedUrl
                      }
                      onError={(event) => {
                        event.target.src = imageExpired;
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        background: "#2d6e9c",
                        color: "white",
                        padding: "3px 6px",
                        borderRadius: "50%",
                        fontSize: 12,
                        fontWeight: "bold",
                        transform: "translate(50%, -50%)",
                      }}
                    >
                      {single && field.value?.signedUrl
                        ? "1"
                        : single
                        ? "0"
                        : field.value?.length}
                    </div>
                  </div>
                ) : (
                  <InsertDriveFileIcon />
                )}
              </Tooltip>
            </Grid>
          )}

          {openPreview &&
            (single ? field.value?.location : field?.value?.length > 0) && (
              <MyCarousel
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
                items={(single ? [field.value] : field.value) || []}
                removeData={
                  isEditable &&
                  ((uploadIndex) =>
                    handleRemoveUploadedDoc
                      ? handleRemoveUploadedDoc(
                          uploadIndex,
                          field.value,
                          field.onChange,
                          setOpenPreview
                        )
                      : removeUploadedDoc(
                          uploadIndex,
                          field.value,
                          field.onChange
                        ))
                }
              />
            )}
        </Grid>
      )}
    />
  );
};

export default RHDocUpload;
