import React from "react";
import { Navigate } from "react-router-dom";
import useSessionStorage from "../Hooks/useSessionStorage";
let AuthContext = React.createContext({ signout: () => {}, signin: () => {} });

export function AuthProvider({ children }) {
  let [user, setUser] = useSessionStorage("userDetails");
  let [token, setToken] = useSessionStorage("token");

  let signin = (newUser, token) => {
    // sessionStorage.setItem("userDetails", JSON.stringify(newUser));

    setUser(newUser);
    setToken(token);
    window.location.href = "/";
  };

  let signout = (callback) => {
    // sessionStorage.removeItem("userDetails");
    setUser(null);
    setToken(null);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ user, signin, signout, token }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }) {
  let auth = useAuth();

  if (!auth?.user) {
    return <Navigate to={"/"} />;
  }

  return children;
}
