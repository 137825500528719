import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import FieldsPreview from "@ui/ComponentUtils/MobileViews/FieldsPreview";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { useEffect } from "react";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";

const DetailContent = (props) => {
  const { isEditable } = props;

  const formMethods = useFormContext();
  // const values = formMethods.watch();

  return (
    <div>
      <AboutUs isEditable={isEditable} />

      <PrivacyPolicy isEditable={isEditable} />
    </div>
  );
};

export default DetailContent;
