import React from "react";

import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { Autocomplete } from "@material-ui/lab/index";

import enums from "helpers/enums";
import { useFormContext } from "react-hook-form";
import { Checkbox } from "@material-ui/core/index";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RHAutoComplete = ({
  rules,
  options,
  label = "",
  render,
  name,
  defaultValue,
  getOptionSelected = (opt, val) => opt === val,
  extraOptions,
  renderFunc,
  renderOption,
  required,
  multiple,
  value,
  onChange,
  textFieldProps = {},
  getOptionLabel,
  autoSelect,
  placeholder,
  autoComplete,
  ...autoprops
}) => {
  const formMethods = useFormContext();

  const myRenderOption = (option, state) => {
    if (typeof renderOption === "function") {
      return renderOption(option, state);
    } else if (renderOption === enums.keywords?.selectAll) {
      const values = formMethods.getValues();

      if (option[render] === enums.keywords?.selectAll) {
        if (values[name]?.length === options?.length - (extraOptions || 1)) {
          state.selected = true;
        } else {
          state.selected = false;
        }

        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              // style={{}}
              checked={state.selected}
            />
            {option[render] || option[name]}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {(renderFunc && renderFunc(option)) ||
              option[render] ||
              option[name]}
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          {(renderFunc && renderFunc(option)) ||
            option[render] ||
            option?.name ||
            option ||
            ""}
        </React.Fragment>
      );
    }
  };

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...autoprops}
            {...field}
            autoSelect={autoSelect}
            autoComplete={autoComplete}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderOption={renderOption && myRenderOption}
            options={options || []}
            size="small"
            onChange={(event, value) => {
              field.onChange(value);
              onChange && onChange(event, value);
            }}
            multiple={multiple}
            value={
              value ||
              (multiple
                ? field.value?.map
                  ? field.value
                    ? field.value
                    : []
                  : field.value
                  ? [field.value]
                  : []
                : field.value
                ? field.value
                : null)
            }
            renderInput={(props) => (
              <TextField
                {...props}
                placeholder={placeholder}
                sx={textFieldProps?.sx}
                helperText={fieldState?.error && fieldState?.error?.message}
                error={Boolean(fieldState?.error)}
                size="small"
                style={{ width: "100%", ...textFieldProps?.style }}
                variant={textFieldProps?.variant || "outlined"}
                label={label + (required ? " *" : "")}
              />
            )}
          />
        );
      }}
    />
  );
};

export default RHAutoComplete;
