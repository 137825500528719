import commonConfig from "config/commonConfig";
import HTTP from "@ui/Utils/http";

const baseApiUrl = commonConfig.baseApiUrl;

export const getAsyncData = async (apiUrl, apiMethod, body = {}) => {
  try {
    let response = {};
    apiUrl = baseApiUrl + apiUrl;
    response = await HTTP[apiMethod](apiUrl, body); // HTTP is an object and is reading its key from apiMethod

    return response?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
