import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import Emitter from "@ui/Utils/CustomEventEmitter";
import {
  base64ToBlobUrl,
  formatDateToRead,
  isAdmin,
  isVendor,
  userDetails,
} from "@ui/Utils/helper";
import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import _ from "lodash";
import { defaultFilter } from "./Detail/Detail";

export const getStockOrderPdfReport = async ({ data,  }) => {
  try {
    const res = await HTTP.post(
      commonConfig.microservices.pdfReport + "e-creatives/pdf/base64",
      {
        context: {
          customData: data,
        },
        templateName: "stockOrders" + "/" + "Template-1/v1",
        pdfOptions: await pdfOptions(data, ),
      }
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const pdfOptions = async (
  contextData = {}
) => {
  const clientInfo = userDetails.client || {};

  // let logo = "";

  // if (clientInfo?.logo?.location) {
  //   logo = `<img style="height:33px;width:80px;" src="${clientInfo?.logo?.location}" alt="" />`; //padding:10px 26px;
  // } else {
  //   logo = `<h3>${clientInfo.name}</h3>`;
  // }

  let topMargin = 5 + "cm";

  return {
    margin: {
      top: topMargin,
      right: "1.5cm",
      bottom: "0px", // to show footer.. increase this
      left: "0cm",
    },
    templateType: "ejs",
    headerTemplate: `
   <div style="-webkit-print-color-adjust: exact; font-size:12px;display: flex; height: 30%; width: 90%; font-size: 10px; margin: 0 auto; padding: 10px; align-items: center; padding: 24px">
    <div style="flex: 1; width: 30%; padding-left: 15px;">
    <div style="word-break: break-all;">        
        <div style="font-style: italic;">
                
        </div>
    </div>
    </div>
    <div style="flex: 1; text-align: center;">
      <div style="font-style: italic;">${"Stock Order"}</div>
      <div style="color: grey; font-weight: bold; margin-top: 5px;">${
        clientInfo.name
      }</div>
    </div>
    <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: flex-end; font-weight: bold;">
      <div>${contextData.orderId}</div>
      <div style="color: grey;">${contextData.dateCreated}</div>
    </div>
  </div>
      `,
    //   footerTemplate: `<div style="color: lightgray;  font-size: 10px; margin-top: 15px; width: 100%; margin:0  36px;">
    // <hr>
    //  <div style='display:flex; justify-content: space-between;'> <div><span style='text-align:left;font-size: 7px;'>
    //  ${
    //    clientInfo && clientInfo.address
    //      ? (clientInfo.address.street ? clientInfo.address.street + ", " : "") +
    //        (clientInfo.address.city ? clientInfo.address.city + ", " : "") +
    //        (clientInfo.address.state ? clientInfo.address.state + ", " : "") +
    //        (clientInfo.address.country && clientInfo.address.country.name
    //          ? clientInfo.address.country.name
    //          : "")
    //      : ""
    //  }
    //  </span></div>
    //  <div style="font-size: 7px;">Page <span class="pageNumber" style="font-size: 7px;"></span>
    //   of <span class="totalPages" style="font-size: 7px;"></span></div></div>
    // </div>`,
  };
};

export const downloadPdf = async (values) => {
  try {
    const clonedValues = _.cloneDeep(values);
    const customValues = {
      ...clonedValues,
      //   categories: clonedValues.categories
      //     .map((item) => {
      //       const availableSubCats = item?.subCategories?.filter(
      //         (subCat) =>
      //           subCat.items?.find((subItem) => defaultFilter(subItem)) ||
      //           defaultFilter(null, subCat)
      //       );

      //       if (availableSubCats?.length) {
      //         return {
      //           ...item,
      //           subCategories: availableSubCats,
      //         };
      //       }
      //       return null;
      //     })
      //     .filter((v) => v),
    };

     

    let expectedCount = 0, // expected
      selectedCount = 0, // input from mobile or Admin panel
      expectedGross = 0, // expected
      selectedGross = 0, // input from mobile or Admin panel
      asPerVendorCount = 0, // input from mobile or Admin panel
      asPerVendorGross = 0; // input from mobile or Admin panel

    const selectedItems = [],
      asPerVendorItems = [];

    (customValues.categories || []).map((catGroup) => {
      (catGroup.subCategories || []).map((subCatGroup) => {
        if(isAdmin){
          expectedCount += subCatGroup.quantity || 0;
          expectedGross +=
            (subCatGroup.quantity || 0) * subCatGroup.averageGrossWeight;
        }
        

        (subCatGroup.items || []).map((item) => {
          if (defaultFilter(item)) {
            selectedGross += (item.quantity || 0) * item.product.grossWeight;

            selectedCount += item.quantity || 0;

             
            selectedItems.push({...item,dueDate: item.dueDate ? formatDateToRead(item.dueDate):''});
          }
        });

        (subCatGroup.vendors || []).map((item) => {
          if (defaultFilter(item)) {
            asPerVendorGross +=
              subCatGroup.averageGrossWeight * (item.quantity || 0);

            asPerVendorCount += item.quantity || 0;

            asPerVendorItems.push({
              ...item,
              dueDate: item.dueDate ? formatDateToRead(item.dueDate) : "",

              category: catGroup.category,
              subCategory: subCatGroup.subCategory,
              averageGrossWeight: subCatGroup.averageGrossWeight,

              approxWeight:
                subCatGroup.averageGrossWeight * (item.quantity || 0),
            });
          }
        });
      });
    });

    const res = await getStockOrderPdfReport({
      data: {
        overallRemarks: customValues.overallRemarks,
        adminRemarks: customValues.adminRemarks,
        
        asPer: customValues.asPer?.collectionLine?.name ? `As per ${customValues.asPer.collectionLine.name} collection`:"As Per Vendor",
        dateExpected: isVendor ? '':( customValues.dateExpected ? formatDateToRead(customValues.dateExpected):''),
        dateCreated: formatDateToRead(
          customValues.dateCreated,
          globalDateTimeFormat.read
        ),
        orderId: customValues.orderId,
// 
        expectedCount,
        expectedGross: expectedGross.toFixed(3),

        selectedCount,
        selectedGross: selectedGross.toFixed(3),

        asPerVendorCount,
        asPerVendorGross: asPerVendorGross.toFixed(3),

        selectedItems,
        asPerVendorItems,
      },
    });

    const handlePrint = (printWindow, pdfUrl) => {
      printWindow.onload = () => {
        printWindow.print();
        // Clean up the URL object to avoid memory leaks
        URL.revokeObjectURL(pdfUrl);
      };
    };

    const pdfUrl = base64ToBlobUrl(res);
    let printWindow = window.open(pdfUrl, "_blank", "width=100,height=100");

    if (printWindow) {
      // sometimes null case handled
      handlePrint(printWindow, pdfUrl);
    } else {
      handlePrint(window.open(pdfUrl, "_blank"), pdfUrl);
    }
  } catch (err) {
    console.log(err);
    Emitter.emit("alert_error", "Something went wrong. Try Again");
  }
};
