import React, { useState } from "react";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Button,
  Drawer,
  Tooltip,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import HoverIcons from "@ui/assets/commonIcons/customIcons/HoverIcons";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import Cancel from "@material-ui/icons/Cancel";

// usage example

//  <CustomDrawer
// 		anchor={'right'}
// 		open={openFieldDrawer && openFieldDrawer[eleIndex]?.right}
// 		onClose={onClose}
// 		onSave={onSubmit}
//      saveDisabled={true}
// 		title={' Component'}
// 		saveButtonTitle="Save">
// 		HELLO WORLD......!
// </CustomDrawer>

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "100px",
    fontWeight: 900,
    letterSpacing: 1.75,
    boxShadow: "none",
    fontSize: "14px",
    border: "1px solid #ffa41c",
    marginRight: "8px",
  },
}));

/**
 * @typedef {object} Props
 *
 * @property {*} children
 * @property {String} title
 * @property {String} saveButtonTitle
 * @property {String} anchor
 * @property {Function} onSave
 * @property {Function} onClose
 * @property {React.CSSProperties} [style]
 * }
 */

/**
 * @param {Props} props
 * @return {*}
 */

const CustomDrawer = ({
  children,
  actions = [],
  containerStyle = {},
  mainDivStyle,
  ...props
}) => {
  const [onHover, setOnHover] = useState(false);

  const classes = useStyles();

  return (
    <React.Fragment key={props.anchor}>
      <Drawer
        anchor={props.anchor || "right"}
        open={props.open}
        onClose={props.onClose}
        PaperProps={{ style: { width: "350px", ...props.style } }}
      >
        <div style={{ width: "100%", overflow: "hidden", ...mainDivStyle }}>
          <Box>
            <Grid
              container
              spacing={2}
              style={{ backgroundColor: "#E0F0FA", height: "60px" }}
            >
              <Grid item xs={6} style={{ whiteSpace: "nowrap" }}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{ marginLeft: "16px", padding: "16px 0px" }}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid item container xs={6} justify="flex-end">
                {actions.map((action, index) => (
                  <Action action={action} key={index} />
                ))}
                <Tooltip title={"Cancel"}>
                  <IconButton onClick={props.onClose}>
                    <HoverIcons
                      muiIcon={<CancelOutlined />}
                      muiIconHover={<Cancel />}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <div
              style={{ padding: "16px", marginTop: "10px", ...containerStyle }}
            >
              {children}{" "}
            </div>

            {!props.hideSaveButton && (
              <div
                style={{
                  padding: "8px",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  disabled={props.saveDisabled}
                  onClick={props.onSave}
                >
                  {props.saveButtonTitle || "Save"}
                </Button>
              </div>
            )}
          </Box>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const Action = (props) => {
  const classes = useStyles();
  const { action = {} } = props;
  const handleOnClick = (event) => {
    if (action.onClick) {
      action.onClick(event);
      event.stopPropagation();
    }
  };
  const classNames = action.hidden ? classes.hideElement : "";

  const displayComponent = () => {
    const button =
      action.buttonType === "icon" ? (
        <IconButton
          color={action.color}
          variant={action.variant}
          // variant="contained"
          onClick={handleOnClick}
          disabled={action.disabled}
          className={classes.iconHover}
          hidden={action.hidden}
        >
          {action.customIcon ? action.customIcon : <Icon>{action.label}</Icon>}
        </IconButton>
      ) : action.buttonType === "custom" ? (
        action.customIcon
      ) : (
        <Button
          color={action.color || "secondary"}
          variant={action.variant || "contained"}
          onClick={handleOnClick}
          disabled={action.disabled}
          hidden={action.hidden}
        >
          <Typography color={action.typographyColor || "white"}>
            {action.label}
          </Typography>
        </Button>
      );

    if (action.tooltip) {
      // fix for issue #1049
      // https://github.com/mbrn/material-table/issues/1049

      return (
        <Tooltip title={action.tooltip}>
          <span>{button}</span>
        </Tooltip>
      );
    } else {
      return button;
    }
  };

  return (
    <Grid item className={classNames}>
      {displayComponent()}
    </Grid>
  );
};

export default CustomDrawer;
