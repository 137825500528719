import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import InternetConnectionChecker from "@ui/Actions/InternetConnection/InternetConnectionChecker";

const Loader = () => {
  const [loading, setLoading] = useState(0);

  React.useEffect(() => {
    CustomEventEmitter.on("progressBar", (value) => {
      setLoading((prev) => (value ? prev + 1 : prev - 1));
    });

    return () => {
      CustomEventEmitter.off("progressBar");
    };
  });

  if (!loading)
    return (
      <>
        <InternetConnectionChecker />
      </>
    );
  return (
    <div
      style={{
        position: "fixed",
        padding: "-2vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        background: "#bbb",
        margin: "-1vh",
        opacity: 0.6,
        zIndex: 1000,
      }}
    >
      <InternetConnectionChecker />
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loader;
