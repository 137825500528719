import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  createRecord,
  updateRecord,
  getSingleRecord,
} from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";

// const validationSchema = yup.object().shape({
// name: yup.string().trim(),
// // .required("Name is required")
// gst: yup
//   .string()
//   .trim()
//   .matches(/^\d{15}$/, "GST must be 15 characters long"),
//   // .required("GST is required")
// pan: yup.string().trim(),
// address: yup.object().shape({
//   country: yup.string().trim(),
//   state: yup.string().trim(),
//   city: yup.string().trim(),
//   street: yup.string().trim(),
//   pincode: yup.string().trim(),
// }),
// email: yup
//   .string()
//   .trim()
//   .email("Invalid email")
//   .required("Email is required"),
// bankName: yup.string().trim(),
// bankAddress: yup.string().trim(),
// accountNo: yup.string().trim(),
// ifsc: yup.string().trim(),
// });

const entitySchema = {
  name: "",
};

const OverviewDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();
  const model = enums.models.overviews;

  useEffect(() => {
    (async () => {
      try {
        const res = await getSingleRecord(model);

        if (res?.code) {
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {};
      if (!values._id) {
        res = await createRecord(model, values);
      } else {
        res = await updateRecord(model, values);
      }
      setIsEditable(false);
      formMethods.reset(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default OverviewDetail;
