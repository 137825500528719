import { createConfirmation } from "react-confirm";
import PasswordDialog from "./PasswordDialog";

// create confirm function
export const promptPassword = createConfirmation(PasswordDialog);

// This is optional. But wrapping function makes it easy to use.
export function promptPasswordWrapper(confirmation, options = {}) {
  return promptPassword({ confirmation, options });
}
