import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHTextField from "../ReactHookFormControls/RHTextField";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import Button from "../../components/Button";
import { useNavigate, Navigate } from "react-router-dom";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { useAuth } from "@ui/components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  phone: yup.string().length(10, "Invalid phone number").required("Required"),
});
const StyledFormProvider = styled(FormProvider)`
  padding: 10vh;
  height: 100vh;
`;

const SendOtp = () => {
  const formMethods = useForm({
    defaultValues: {},
    mode: "all",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const auth = useAuth();

  if (auth.user) {
    return <Navigate to="/home" />;
  }

  const onSubmit = () => {
    navigate("/otp", {
      state: {
        phone: formMethods.getValues().phone,
        navigateTo: "/resetPassword",
      },
    });
  };
  return (
    <StyledFormProvider {...formMethods}>
      <Typography variant="h4" gutterBottom>
        Send OTP
      </Typography>
      <Grid
        style={{ height: "80vh", marginTop: 10 }}
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Grid item container justifyContent="center">
          <RHTextField
            style={{ marginBottom: 10 }}
            name="phone"
            label={"Phone"}
          />
        </Grid>
        <Button onClick={formMethods.handleSubmit(onSubmit)}>Next</Button>
      </Grid>
    </StyledFormProvider>
  );
};

export default SendOtp;
