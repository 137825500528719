import React, { useState, useRef, useEffect } from "react";
import FilterByFields from "@ui/Controls/Search/FilterByFields";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";
import MatTable from "@ui/components/MatTable";
import { paginateWithHook } from "@ui/ComponentUtils/blueprintAPIs";
import {
  formatDateToRead,
  getProductImageUri,
  imageExpired,
} from "@ui/Utils/helper";
import enums from "helpers/enums";
import { useFormContext } from "react-hook-form";
import { Checkbox, Button } from "@mui/material";
import { uniqueDesigns } from "views/Catalogues/api";
import DeleteCatalogueProducts from "./DeleteProducts";
import ProductDetailPopup from "@ui/MuiComponents/Carousel/ProductDetailPopup";
import Emitter from "@ui/Utils/CustomEventEmitter";

export const pageSizeOptions = [25, 50, 100, 200, 500, 1000, 2500];

const IndividualWorkflow = ({
  isEditable,
  selectedProducts,
  setSelectedProducts,
  showDeleteProducts,
  setShowDeleteProducts,
}) => {
  const formMethods = useFormContext();
  const { setValue, watch, getValues } = formMethods;
  const values = watch();
  const [metaInfo, setMetaInfo] = useState({});
  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 25,
    page: 0,
  });
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const tableRef = useRef();
  const [filterCriteria, setFilterCriteria] = useState({});
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [hideDiamondCarats, setHideDiamondCarats] = useState(true);

  const handleFieldLevelSearch = (tempFilterCriteria) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: [tempFilterCriteria, {}],
    };

    tableRef.current.onQueryChange(queryData);
    setFilterCriteria(tempFilterCriteria);
  };

  const getData = async (query) => {
    setSelectedProducts([]);
    setSelectAllChecked(false);

    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }

    try {
      setListPageTableQuery({ page: query.page, pageSize: query.pageSize });

      query.criteria = query.criteria || [{}, {}];

      //   query.criteria[0] = {
      //     ...(query.criteria[0] || {}),
      //     catalogues: { $nin: [values._id] },
      //   };

      let response = await paginateWithHook(
        enums.models.products,
        query,
        "priceAfterDiscount=true"
      );

      if (response && response.paginationInfo) {
        let records = response.records || [];
        if (records.find((rec) => parseFloat(rec.totalDiamondWeight))) {
          setHideDiamondCarats(false);
        }
        setMetaInfo(response.paginationInfo || {});

        // const selectedProductIds = (selectedProducts || []).map(prod=> prod._id);
        // records = records.map((record) => ({
        //   ...record,
        //   tableData: {
        //     checked: selectedProductIds.includes(record._id),
        //   },
        // }));

        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);
      setMetaInfo({});
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const onSelectionChange = (rows) => {
    const currentPageDataIds = (tableRef.current?.dataManager?.data || []).map(
      (prod) => prod._id
    );
    const selectedRows = rows; //.map((row) => row._id); // rows = 100, 99

    let flag = false;

    const updatedProducts = (selectedProducts || []).filter((prod) => {
      if (
        currentPageDataIds.includes(prod._id) &&
        !selectedRows.find((row) => row._id === prod._id)
      ) {
        flag = true;
        return false;
      }
      return true;
    });

    const newProducts = selectedRows.filter(
      (row) => !updatedProducts.find((prod) => prod._id === row._id)
    );

    if (!flag && newProducts.length) {
      updatedProducts.unshift(...newProducts);
    }

    // setValue("individualProducts", updatedIds);

    setSelectedProducts(updatedProducts);

    if (
      // flag ||  // flag also helps to know if anything got removed from All records.. just when we find that paginate API metaInfo.totalRecords is not matching to uniqueDesigns Aggregate query, we will see what to do.. till then peace...
      updatedProducts.length < metaInfo.totalRecords
    ) {
      setSelectAllChecked(false);
    }
  };

  const handleAddToCatalogues = () => {
    const individualProducts = getValues("individualProducts") || [];

    // Extract IDs from individualProducts for quick comparison
    const individualProductIds = individualProducts.map((prod) => prod._id);

    // Filter out products from selectedProducts that are already in individualProducts
    const newProducts = selectedProducts
      .filter((prod) => !individualProductIds.includes(prod._id))
      .map((prod) => {
        delete prod?.tableData?.checked;
        return prod;
      });

    // Add the new products to individualProducts
    const updatedIndividualProducts = [...newProducts, ...individualProducts];

    // Update the individualProducts field with the new array
    setValue("individualProducts", updatedIndividualProducts);

    const records = tableRef.current.dataManager.data;
    records.forEach((record) => {
      record.tableData.checked = false;
    });

    // Trigger a table re-render
    tableRef.current.onSelectionChange([]);
    setSelectedProducts([]);
    setSelectAllChecked(false);

    const selectedLength = selectedProducts.length,
      newAddedLength = newProducts.length;
    Emitter.emit(
      "alert_success",
      newAddedLength === selectedLength
        ? `Added ${newAddedLength}`
        : `Added: ${newAddedLength}, Already Exists: ${
            selectedLength - newAddedLength
          }`
    );
  };

  const handleSelectAll = async () => {
    try {
      if (selectAllChecked) {
        // setValue("individualProducts", []);

        if (tableRef.current) {
          const records = tableRef.current.dataManager.data;
          records.forEach((record) => {
            record.tableData.checked = false;
          });

          // Trigger a table re-render
          tableRef.current.onSelectionChange([]);
        }
        setSelectedProducts([]);
        setSelectAllChecked(false);
      } else {
        const allProducts = await uniqueDesigns({
          criteria: {
            ...filterCriteria,
            // catalogues: { $nin: [values._id] }
          },
        });
        // const selectedProductIds = allProducts // allProductIds.map((prod) => prod._id);
        // setValue("individualProducts", selectedProductIds);

        if (tableRef.current) {
          const records = tableRef.current.dataManager.data;
          records.forEach((record) => {
            record.tableData.checked = true;
          });

          // Trigger a table re-render
          tableRef.current.onSelectionChange(records);
        }
        setSelectedProducts(allProducts);
        setSelectAllChecked(true);
      }
    } catch (error) {
      console.error("Error fetching all product IDs", error);
    }
  };

  return (
    <>
      {!!openDetailPopup ? (
        <ProductDetailPopup
          open={openDetailPopup}
          onClose={() => setOpenDetailPopup(false)}
          product={openDetailPopup}
        />
      ) : null}

      {isEditable ? (
        <div style={{ display: showDeleteProducts ? "none" : "block" }}>
          <FilterByFields
            hideFilterStats={true}
            printingStationScreen={true}
            handleFieldLevelSearch={handleFieldLevelSearch}
            metaInfo={metaInfo}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>
              {values.individualProducts?.length ? (
                <Button
                  variant="contained"
                  onClick={() => setShowDeleteProducts(true)}
                  style={{
                    backgroundColor: "#67a377", //enums.colorTheme.primary
                  }}
                >
                  {`Show ${values.individualProducts?.length || 0} added`}
                </Button>
              ) : null}
            </h2>

            <div style={{ display: "flex", alignItems: "center" }}>
              {selectedProducts.length ? (
                <Button
                  variant="contained"
                  onClick={handleAddToCatalogues}
                  color="secondary"
                >
                  Add {selectedProducts.length || 0} Products
                </Button>
              ) : null}

              <Checkbox checked={selectAllChecked} onChange={handleSelectAll} />
              <Button
                variant="contained"
                onClick={handleSelectAll}
                style={{ backgroundColor: enums.colorTheme.primary }}
              >
                {selectAllChecked ? "Deselect All" : "Select All"}
              </Button>
            </div>
          </div>

          <div>
            <MatTable
              tableRef={tableRef}
              title={`${metaInfo?.totalRecords || 0} Unique Designs`}
              columns={[
                {
                  title: "Design",
                  field: "styleNo",
                  render: (row) => (
                    <div onClick={() => setOpenDetailPopup(row)}>
                      {row.styleNo}
                    </div>
                  ),
                },
                {
                  title: "SKU",
                  field: "sku",
                  render: (row) => (
                    <div onClick={() => setOpenDetailPopup(row)}>{row.sku}</div>
                  ),
                },
                {
                  title: "Category",
                  field: "category.name",
                  render: (row) => (
                    <div onClick={() => setOpenDetailPopup(row)}>
                      {row?.category?.name || ""}
                    </div>
                  ),
                },
                {
                  title: "Gross, Net",
                  field: "grossWeight",

                  render: (row) => (
                    <div onClick={() => setOpenDetailPopup(row)}>
                      {`${(row.grossWeight || 0).toFixed(3)}, ${(
                        row.netWeight || 0
                      ).toFixed(3)}`}
                    </div>
                  ),
                },
                {
                  title: "Diamond Carats",
                  field: "totalDiamondWeight",
                  hidden: hideDiamondCarats,
                },
                {
                  title: "Since",
                  field: "dateCreated",
                  render: (row) => (
                    <div onClick={() => setOpenDetailPopup(row)}>
                      <div>{formatDateToRead(row.dateCreated)}</div>
                      <div style={{ fontSize: "12px", fontStyle: "italic" }}>
                        {(row.createdBy?.firstName || "") +
                          " " +
                          (row.createdBy?.lastName || "")}
                      </div>
                    </div>
                  ),
                },
                {
                  title: "Image",
                  render: (row) => (
                    <div onClick={() => setOpenDetailPopup(row)}>
                      <img
                        src={getProductImageUri(row)}
                        alt="img"
                        style={{
                          height: 100,
                          width: 100,
                          objectFit: "contain",
                        }}
                        onError={(event) => {
                          event.target.src = imageExpired;
                        }}
                      />
                    </div>
                  ),
                },
              ]}
              data={(query) => getData(query)}
              options={{
                search: false,
                pageSize: listPageTableQuery.pageSize,
                selection: isEditable,
                pageSizeOptions: pageSizeOptions,
                exportButton: false,
              }}
              onSelectionChange={onSelectionChange}
              onChangePage={(page) => {
                setListPageTableQuery({
                  ...listPageTableQuery,
                  page: page,
                });
              }}
              onChangeRowsPerPage={(rowsPerPage) => {
                setListPageTableQuery({
                  ...listPageTableQuery,
                  pageSize: rowsPerPage,
                });
              }}
            />

            <ScrollTopButton />
          </div>
        </div>
      ) : null}

      {isEditable && !showDeleteProducts ? null : (
        <DeleteCatalogueProducts
          isEditable={isEditable}
          setOpenDetailPopup={setOpenDetailPopup}
          setShowDeleteProducts={setShowDeleteProducts}
          hideDiamondCarats={hideDiamondCarats}
          setHideDiamondCarats={setHideDiamondCarats}
        />
      )}
    </>
  );
};

export default IndividualWorkflow;
