import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";

const SmartForm = ({
  initialValues,
  watchFields,
  validationSchema,
  onSubmit,
  onError,
  children,
}) => {
  const formMethods = useForm({
    defaultValues: initialValues || {},
    mode: "all",
    resolver: validationSchema && yupResolver(validationSchema),
  });

  const { watch, handleSubmit } = formMethods; //trigger

  // useEffect(() => {
  // 	trigger(); // To trigger all validation fields onMount
  // }, []);

  watchFields && watch(watchFields);

  const handleOnKeyDown = (e) => {
    if (e.shiftKey && (e.charCode === 13 || e.keyCode === 13)) {
      return true;
    } else if (e.charCode === 13 || e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        // blocking submit onPress of enter key
        onKeyDown={handleOnKeyDown}
      >
        {children({ ...formMethods })}
      </form>
    </FormProvider>
  );
};

export default SmartForm;
