import styled from "styled-components";

export const AdminChild = styled.div`
  position: absolute;
  top: 0rem;
  left: 0rem;
  background-color: #d9d9d9;
  width: 144.63rem;
  height: 7.13rem;
`;
export const AdminItem = styled.div`
  position: absolute;
  top: 16.63rem;
  left: 4.06rem;
  border-radius: var(--br-981xl);
  background: linear-gradient(180deg, #bfe0ff, rgba(191, 224, 255, 0));
  width: 15.81rem;
  height: 40.38rem;
`;
export const AdminInner = styled.div`
  position: absolute;
  top: 17rem;
  left: 27.56rem;
  border-radius: var(--br-981xl);
  background: linear-gradient(180deg, #c8eec8, rgba(251, 244, 206, 0));
  width: 15.81rem;
  height: 40.38rem;
`;
export const RectangleDiv = styled.div`
  width: 12rem;
  cursor: pointer;
  position: relative;
  background: linear-gradient(180deg, ${({ backgroundColor }) => backgroundColor || "#eaccff"}, rgba(253, 222, 154, 0));
  text-align: center;
  height: 34.375rem;
  border-radius: 62.5rem;
  margin: 10px;
  position: relative;

  .top-element,
  .bottom-element {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    background-color: white;
    padding: 10px;
  }

  .top-element {
    top: 10px;
  }

  .bottom-element {
    bottom: 10px;
  }
`;
export const AdminChild1 = styled.div`
  position: absolute;
  top: 16.63rem;
  left: 74.56rem;
  border-radius: var(--br-981xl);
  background: linear-gradient(180deg, #b8efff, #fff);
  width: 15.81rem;
  height: 40.38rem;
`;
export const BottomData = styled.p`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5rem;
  left: 4rem;
  text-align: left;
  left: 50%;
  right: 50%;

  color: #0c0553;
  text-align: center;
  font-size: 1rem;
  font-family: Gothic A1;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const TopBarData = styled.b`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 1rem;
  text-align: left;
  left: 50%;
  right: 50%;

  .top_bar_content {
    position: absolute;
    aspect-ratio: 2/2;
    object-fit: contain;
  }

  .top_bar_gif {
    width: 10rem;
  }
  @media (max-width: 768px) {
    .top_bar_content {
      aspect-ratio: 2/1;
    }
  }
`;

export const Orders = styled.b`
  position: absolute;
  top: 50rem;
  left: 32.25rem;
  color: #127754;
  text-align: left;
`;
export const Vendor = styled.p`
  margin: 0;
`;
export const VendorPortal = styled.b`
  position: absolute;
  top: 49.06rem;
  left: 55.75rem;
  color: #ffba1e;
`;
export const PurchaseManagement = styled.b`
  position: absolute;
  top: 49.06rem;
  left: 77.06rem;
`;
export const AayushShah = styled.b`
  position: absolute;
  top: 10.81rem;
  left: 10.94rem;
  font-size: 0.94rem;
`;
export const VectorIcon = styled.img`
  position: absolute;
  height: 0.65%;
  width: 0.48%;
  top: 19.04%;
  right: 88.59%;
  bottom: 80.31%;
  left: 10.93%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
export const GroupIcon = styled.img`
  position: absolute;
  height: 4.68%;
  width: 1.75%;
  top: 17.01%;
  right: 93.15%;
  bottom: 78.32%;
  left: 5.1%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
export const AdminRoot = styled.div`
  position: relative;
  background: linear-gradient(180deg, #fffcf5 23.44%, #fff);
  width: 100%;
  height: 61.38rem;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-6xl);
  color: var(--color-midnightblue);
  font-family: var(--font-gothic-a1);
`;
