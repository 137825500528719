import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { userDetails } from "@ui/Utils/helper";

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.appConfig;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate(`/${model}`);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "name",
      label: "App Name",
      fieldType: "textField",
      xs: 6,
    },
    {
      name: "logo",
      label: "App Logo",
      fieldType: "upload",
      accept: "image/*",
      model: model,
      single: true,
    },
    {
      name: "splashScreen",
      label: "Splash Screen",
      fieldType: "upload",
      // accept: "", // research on how to accept only { images or videos }
      model: model,
      single: true,
      xs: 3,
    },
  ];

  const links = [
    {
      name: userDetails.client?.name,
      url: "#/appConfig",
      isDisplayLink: true,
    },
    {
      name: values?.name,
      // url: "#/appConfig",
      isDisplayLink: true,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"App Config"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      ></GeneralSection>
    </>
  );
};

export default DetailHeader;
