import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import {
  getCities,
  getCountries,
  getStates,
  getUniversalAccessToken,
} from "@ui/ComponentUtils/thirdPartyAPIs";
import enums from "helpers/enums";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const earthErrorMessage =
  "Country States City options failed to load. Reload to retry";

const DetailContent = ({ isEditable }) => {
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const [token, setToken] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleEarth = async () => {
    try {
      const token = await getUniversalAccessToken();
      const tempCountries = ((await getCountries(token)) || []).map(
        (country) => country["country_name"]
      );
      setCountries(tempCountries);
      setToken(token);
    } catch (err) {
      CustomEventEmitter.emit("alert_error", earthErrorMessage);
    }
  };

  useEffect(() => {
    handleEarth();
  }, []);

  useEffect(() => {
    if (token && values.kyc?.address?.country) {
      (async () => {
        try {
          const tempStates = (
            (await getStates(token, values.kyc?.address?.country)) || []
          ).map((state) => state["state_name"]);

          setStates(tempStates);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.kyc?.address?.country, token]);

  useEffect(() => {
    if (token && values.kyc?.address?.state) {
      (async () => {
        try {
          const tempCities = (
            (await getCities(token, values.kyc?.address?.state)) || []
          ).map((city) => city["city_name"]);

          setCities(tempCities);
        } catch (err) {
          CustomEventEmitter.emit("alert_error");
        }
      })();
    }
  }, [values.kyc?.address?.state, token]);

  const entityFields = [
    {
      name: "kyc.name",
      label: "Name",
      fieldType: enums.fieldTypes.textField,
    },
    {
      name: "kyc.gst",
      label: "GST",
      fieldType: enums.fieldTypes.textField,
      autoComplete: "off",
    },
    {
      name: "kyc.pan",
      label: "PAN",
      fieldType: enums.fieldTypes.textField,
      autoComplete: "off",
    },
    {
      name: "kyc.phone",
      label: "Phone",
      fieldType: enums.fieldTypes.textField,
    },
    {
      name: "kyc.telephone",
      label: "Telephone",
      fieldType: enums.fieldTypes.textField,
    },
    {
      name: "kyc.emergencyContact",
      label: "Emergency Contact",
      fieldType: enums.fieldTypes.textField,
    },

    {
      name: "kyc.address.country",
      label: "Country",
      fieldType: enums.fieldTypes.dropdown,
      options: countries,
      render: "kyc.address.country",
    },
    {
      name: "kyc.address.state",
      label: "State",
      fieldType: enums.fieldTypes.dropdown,
      options: states,
      render: "kyc.address.state",
    },
    {
      name: "kyc.address.city",
      label: "City",
      fieldType: enums.fieldTypes.dropdown,
      options: cities,
      render: "kyc.address.city",
    },
    {
      name: "kyc.address.street",
      label: "Street",
      fieldType: "textField",
    },
    {
      name: "kyc.address.pincode",
      label: "Pincode",
      fieldType: "textField",
    },
    {
      name: "kyc.email",
      label: "Email",
      fieldType: enums.fieldTypes.textField,
    },

    {
      name: "kyc.bankName",
      label: "Bank Name",
      fieldType: enums.fieldTypes.textField,
      autoComplete: "off",
    },

    {
      name: "kyc.accountNo",
      label: "Account No",
      fieldType: enums.fieldTypes.textField,
      autoComplete: "off",
    },
    {
      name: "kyc.ifsc",
      label: "IFSC",
      fieldType: enums.fieldTypes.textField,
      autoComplete: "off",
    },
    {
      name: "kyc.bankAddress",
      label: "Bank Address",
      fieldType: enums.fieldTypes.textField,
      autoComplete: "off",
      xs: 8,
    },
  ];

  return (
    <GeneralSection
      fields={entityFields}
      title="Know Your Client << KYC >>"
      isEditable={isEditable}
      values={values}
    />
  );
};

export default DetailContent;
