import React, { useState } from "react";

const ReadMore = ({ content = "-", limit = 101 }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (!content) {
    return null;
  }

  return (
    <div>
      {content.length > limit && !expanded ? (
        <>
          {content.slice(0, limit)}...
          <button
            onClick={toggleExpand}
            style={{
              color: "blue",
              cursor: "pointer",
              background: "none",
              border: "none",
              padding: 0,
            }}
          >
            Read More
          </button>
        </>
      ) : (
        <>
          {content}
          {content.length > limit && (
            <button
              onClick={toggleExpand}
              style={{
                color: "blue",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
              }}
            >
              Read Less
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ReadMore;
