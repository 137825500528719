import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  createRecordWithHook,
  getByCode,
  updateRecordWithHook,
} from "@ui/ComponentUtils/blueprintAPIs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
});

const entitySchema = {
  name: "",
  workflow: enums.catalogueWorkflow.bulk,
};

const CatalogueDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const model = enums.models.catalogues;
  const [showDeleteProducts, setShowDeleteProducts] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(
            model,
            id,
            `populate=${JSON.stringify([
              {
                path: "individualProducts",
                db: "data-engine",
                collection: "products",
                select:
                  "_id styleNo sku grossWeight netWeight totalDiamondWeight category dateCreated imageFlag division client",
              },
            ])}`
          );
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      if (values.workflow === enums.catalogueWorkflow.bulk) {
        delete values.individualProducts;
      } else {
        delete values.condition;
      }

      if (!values._id) {
        res = await createRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
        action = "Updated";
      }
      let existing = res.existing,
        removed = res.removed,
        updated = res.updated;

      delete res.existing;
      delete res.removed;
      delete res.updated;

      formMethods.reset(res);
      setIsEditable(false);
      setShowDeleteProducts(false);

      CustomEventEmitter.emit(
        "alert_success",
        existing || removed || updated
          ? [
              existing ? `Existing Catalogue Stocks: ${existing}` : "",
              removed ? `Removed: ${removed}` : "",
              updated ? `Updated: ${updated}` : "",
            ]
              .filter(Boolean)
              .join(", ")
          : `${action} Successfully`
      );
    } catch (err) {
      console.log(err);
      let message = err?.response?.data?.message || "Something went wrong";
      if (err?.response?.data?.message?.toString().match("duplicate")) {
        message =
          values.name + " already exists. Change the name and try again.";
      }
      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
        setShowDeleteProducts={setShowDeleteProducts}
      />

      <DetailContent
        isEditable={isEditable}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        showDeleteProducts={showDeleteProducts}
        setShowDeleteProducts={setShowDeleteProducts}
      />
    </FormProvider>
  );
};

export default CatalogueDetail;

export const catalogueStyles = {
  section: {
    // backgroundColor: "#ffeba1",
    padding: "24px",
  },
  heading: {
    backgroundColor: "ff6f00",
  },
};
