import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { isVendor, userDetails } from "@ui/Utils/helper";
import DetailContent from "./components/DetailContent";
import { createRecordWithHook, getByCode, updateRecordWithHook } from "../api";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  customer: yup
    .object()
    .shape({
      _id: yup.string().required("Required"),
    })
    .nullable()
    .required("Required"),
});

export const entitySchema = {
  orderPriority: enums.customOrderPriority.normal,
  customer: { manager: null },
  orderType: enums.customOrderType.custom,
  items: [
    {
      orderStatus: enums["vendor-portal"].orderStatus.pending,
      quantityUnit: { piece: true },
    },
  ],
};

const CustomOrderDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const model = enums.models["e-creatives"]?.customOrders;
  const params = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const [vendorCodes, setVendorCodes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models["vendor-portal"]?.vendorCodes)) || [];
        setVendorCodes(res?.[0]?.availableCodes || []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          setLoading(true);
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setInitialValues(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
      setLoading(false);
    })();
  }, [params?.code]);

  const onSubmit = async (values) => {
    try {
      let res = {},
        action = "Created";

      if (!values.items?.length) {
        return CustomEventEmitter.emit("alert_error", "Invalid Order...!");
      }

      if (values._id) {
        res = await updateRecordWithHook(
          enums.models["e-creatives"]?.customOrders,
          values
        );
        action = "Updated";
      } else {
        res = await createRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
        action = "Created";
      }

      formMethods.reset(res);
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", action + " Successfully");
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message || "Something went wrong"
      );
    }
  };

  const handlePlaceOrder = async (values) => {
    try {
      let res = {},
        action = "Placed Order";
      if (!values.items?.length) {
        return CustomEventEmitter.emit("alert_error", "Invalid Order...!");
      }

      let vendorNotFoundFlag = false;
      values.items.map((item, itemIndex) => {
        if (!item.vendorCode?.toString().trim()) {
          CustomEventEmitter.emit(
            "alert_error",
            `Select Vendor in #${itemIndex + 1} to continue...`
          );
          vendorNotFoundFlag = true;
        }
      });

      if (vendorNotFoundFlag) {
        return;
      }

      if (values.code && window.confirm("Are you sure to place order?")) {
        values.items = (values.items || []).map((item) => {
          if (item.orderStatus === enums["vendor-portal"].orderStatus.pending) {
            item.orderStatus = enums["vendor-portal"].orderStatus.assigned;
            item.dateDelivered = Date.now();
          }

          return item;
        });

        res = await updateRecordWithHook(
          enums.models["e-creatives"]?.customOrders,
          values
        );
        CustomEventEmitter.emit("alert_success", action + " Successfully");
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message || "Something went wrong"
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        loading={loading}
        setIsEditable={setIsEditable}
        handlePlaceOrder={handlePlaceOrder}
        initialValues={initialValues}
      />

      <DetailContent isEditable={isEditable} vendorCodes={vendorCodes} />
    </FormProvider>
  );
};

export const defaultFilter = (item) => {
  if (isVendor) {
    return (userDetails.vendorCode || []).find(
      (vCode) =>
        vCode === item.vendorCode &&
        item.orderStatus !== enums["vendor-portal"].orderStatus.pending
    );
    // &&
    // (isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    //   : true)
  } else {
    return true;
    // isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    // : ;
  }
};

export default CustomOrderDetail;
