/**
 * @author Abhishek K
 * on March 5, 2024
 */

import {
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DateRange } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import React, { Fragment, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "./dateRangeStyles/dateRangeStyle.css";
import "./dateRangeStyles/dateRangeTheme.css";
import { defaultStaticRanges } from "./dateRangeUtils";
import { enUS } from "date-fns/locale";
import Label from "@ui/components/Label";
import { defaultGoldHex, formatDateToRead } from "@ui/Utils/helper";

const DateRangePickerModal = ({
  openDatePicker,
  setOpenDatePicker,
  selectedDateRange,
  setSelectedDateRange,
  onSubmit, // if you have label and need Date to be empty, then params sent from onSubmit(selectedParam) should be read
  label,
  iconSize,
  actionName = "Search",
  enableAction = { clear: false },
  style = {},
  actionStyle = {},
}) => {
  const initialDateRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const [onHover, setOnHover] = useState(false);

  return (
    <Fragment>
      <Grid
        container
        spacing={3}
        onClick={() => setOpenDatePicker(true)}
        style={{ cursor: "pointer" }}
      >
        {label ? (
          <Grid item xs={9}>
            <Label
              label={label}
              value={
                selectedDateRange?.startDate &&
                `${formatDateToRead(
                  selectedDateRange?.startDate
                )} - ${formatDateToRead(selectedDateRange?.endDate)}`
              }
            />
          </Grid>
        ) : null}

        <Grid item xs={2}>
          <Tooltip title={"Filter by date"}>
            <DateRange
              style={{
                color: "#777",
                fontSize: iconSize || "24px",
                ...style,
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Dialog
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          color={"primary"}
          style={{ padding: "0px 10px 0px 20px" }}
        >
          <Box
            style={{ margin: "0px", paddingTop: "5px" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2" color="primary">
              {"Select Date Range"}
            </Typography>

            <IconButton
              onClick={() => setOpenDatePicker(false)}
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
            >
              {onHover ? <CancelIcon /> : <CancelOutlinedIcon />}
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: "auto" }}>
          <DateRangePicker
            staticRanges={defaultStaticRanges}
            inputRanges={[]}
            ranges={[selectedDateRange || initialDateRange]}
            rangeColors={["#12539E"]}
            onChange={(item) => {
              setSelectedDateRange({
                startDate: item.range1?.startDate || item.selection.startDate,
                endDate: item.range1?.endDate || item.selection.endDate, // 2 different versions maybe.. first it was item.selection.startDate and endDate
                key: "selection",
              });
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction={"horizontal"}
            startDatePlaceholder={"From Date"}
            endDatePlaceholder={"To Date"}
            locale={enUS}
          />
        </DialogContent>
        <DialogActions>
          {enableAction.clear ? (
            <Button
              style={{
                marginRight: "17px",
                marginBottom: "10px",
                backgroundColor: defaultGoldHex,
                color: "white",
                minWidth: "100px",
                fontWeight: 900,
                letterSpacing: 1.75,
                boxShadow: "none",
                fontSize: "16px",
              }}
              id="_asf_btn_srh"
              variant="contained"
              onClick={() => {
                setSelectedDateRange();
                onSubmit();
                setOpenDatePicker(false);
              }}
            >
              Clear
            </Button>
          ) : null}

          <Button
            id="_asf_btn_srh"
            color="secondary"
            variant="contained"
            onClick={() => {
              setOpenDatePicker(false);
              onSubmit(selectedDateRange || initialDateRange);
            }}
            style={{
              marginRight: "17px",
              marginBottom: "10px",
              color: "white",
              minWidth: "100px",
              fontWeight: 900,
              letterSpacing: 1.75,
              boxShadow: "none",
              fontSize: "16px",
              ...actionStyle,
            }}
          >
            {actionName}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DateRangePickerModal;
