import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ChangePasswordDialog = ({
  changePasswordDialog,
  setChangePasswordDialog,
  updateNewPassword,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  return (
    changePasswordDialog && (
      <Dialog
        open={changePasswordDialog}
        maxWidth="sm"
        fullWidth
        onClose={() => setChangePasswordDialog(false)}
      >
        <DialogTitle onClose={() => setChangePasswordDialog(false)}>
          <b style={{ color: "#1976d2" }}>Change Password</b>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3} style={{ marginTop: "24px" }}>
            {/* New Password Field */}
            <Grid item xs={12}>
              <TextField
                label="New Password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                fullWidth
                helperText={"Keep it Strong"}
                error={!newPassword?.trim() || newPassword?.length < 8}
                size="small"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Confirm Password Field */}
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                fullWidth
                // helperText="Close your eyes and type"
                error={confirmPassword && newPassword !== confirmPassword}
                size="small"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Confirm Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ marginTop: 20 }}
                variant="contained"
                color="primary"
                disabled={
                  !newPassword?.trim() ||
                  !confirmPassword?.trim() ||
                  newPassword !== confirmPassword
                }
                onClick={updateNewPassword}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  );
};

export default ChangePasswordDialog;
