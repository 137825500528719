import { useSnackbar } from "notistack";
import React from "react";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const SnackbarComponent = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    CustomEventEmitter.on("alert_success", (e) => {
      enqueueSnackbar(e, { variant: "success" });
    });

    CustomEventEmitter.on("alert_error", (e) => {
      enqueueSnackbar(e, { variant: "error" });
    });

    CustomEventEmitter.on("alert_warning", (e) => {
      enqueueSnackbar(e, { variant: "warning" });
    });
    return () => {
      CustomEventEmitter.off("alert_success");

      CustomEventEmitter.off("alert_error");

      CustomEventEmitter.off("alert_warning");
    };
  });

  return <></>;
};

export default SnackbarComponent;
