import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const RHCheckbox = ({ name, rules, defaultValue, value, label, ...props }) => {
  return (
    <Controller
      {...props}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                {...props}
                checked={!!value || !!field.value}
                color={props.color || "secondary"}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                  props.onChange && props.onChange(event);
                }}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};

export default RHCheckbox;
