import { getProductImageUri } from "@ui/Utils/helper";
import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core/index";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import Card from "components/Card";
import { useParams } from "react-router-dom";
import { MyCarousel } from "@ui/MuiComponents/index";
import { addToCart } from "../api";

const ProductsGrid = ({ cartItems = [], setCartItems = () => {} }) => {
  const [products, setProducts] = useState([]);
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        const id = params.id,
          clientId = params.clientId;

        setProducts(
          (await search(enums.models.products, {
            catalogues: { $in: [id] },
            client: clientId,
          })) || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Grid container spacing={3}>
      {products?.length ? (
        products.map((prod, prodIndex) => {
          return (
            <Grid item xs={4} key={prodIndex}>
              <ProductCard
                prod={prod}
                cartItems={cartItems}
                setCartItems={setCartItems}
              />
            </Grid>
          );
        })
      ) : (
        <div>Not available...</div>
      )}
    </Grid>
  );
};

export const ProductCard = ({ prod, cartItems, setCartItems }) => {
  const matched =
    cartItems?.find((item) => item.product?.sku === prod.sku) || {};
  const quantity = matched.quantity ? matched.quantity : "";

  return (
    <div style={{ cursor: "pointer" }}>
      <Card
        style={{
          padding: "24px",
          justifyContent: "center",
          textAlign: "center",
          borderRadius: "16px",
        }}
      >
        <div>
          <DisplayImage prod={prod} />
        </div>
        <div style={{ fontSize: 16 }}>
          <b>{prod.styleNo}</b>
        </div>
        <div style={{ fontSize: 12 }}>
          Gross Weight: <b>{Number(prod.grossWeight).toFixed(3)} g</b>
        </div>
        <div style={{ fontSize: 12 }}>
          Net Weight: <b>{Number(prod.netWeight).toFixed(3)} g</b>
        </div>

        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              addToCart([prod], { cartItems, setCartItems }, { decrease: true })
            }
          >
            -
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addToCart([prod], { cartItems, setCartItems })}
          >
            Add to cart {`${quantity ? `( ${quantity} )` : ""}`}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => addToCart([prod], { cartItems, setCartItems })}
          >
            +
          </Button>
        </div>
      </Card>
    </div>
  );
};

const DisplayImage = ({ prod }) => {
  const [imageUri, setImageUri] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const url = getProductImageUri(prod);

  useEffect(() => {
    (async () => {
      setImageUri(url);
    })();
  }, [prod]);

  return (
    <>
      <MyCarousel
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        items={[
          {
            fileName: url.split("/")[url.split("/").length - 1],
            fileType: "image/png",
            location: url,
          },
        ]}
      />
      <img
        onClick={() => setOpenPreview(true)}
        style={{ height: 120, width: 120 }}
        src={imageUri}
        onerror="this.onerror=null;this.src='https://storage.googleapis.com/sheen-upload/Assets/Common/image-not-found.jpg';"
      />
    </>
  );
};
export default ProductsGrid;
