import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import {
  handleGlobalUserControl,
  isDivisionControlled,
} from "@ui/Utils/helper";
import { appModule } from "../../api";
import {
  getCities,
  getCountries,
  getStates,
  getUniversalAccessToken,
} from "@ui/ComponentUtils/thirdPartyAPIs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const earthErrorMessage =
  "Country, States, City options failed to load. Reload to retry";

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const [vendorCodes, setVendorCodes] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models["vendor-portal"]?.users;
  const [token, setToken] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleEarth = async () => {
    try {
      const token = await getUniversalAccessToken();
      const tempCountries = ((await getCountries(token)) || []).map(
        (country) => country["country_name"]
      );
      setCountries(tempCountries);
      setToken(token);
    } catch (err) {
      CustomEventEmitter.emit("alert_error", earthErrorMessage);
    }
  };

  useEffect(() => {
    handleEarth();
  }, []);

  useEffect(() => {
    if (token && values.companyDetails?.address?.country) {
      (async () => {
        try {
          const tempStates = (
            (await getStates(token, values.companyDetails?.address?.country)) ||
            []
          ).map((state) => state["state_name"]);

          setStates(tempStates);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.companyDetails?.address?.country, token]);

  useEffect(() => {
    if (token && values.companyDetails?.address?.state) {
      (async () => {
        try {
          const tempCities = (
            (await getCities(token, values.companyDetails?.address?.state)) ||
            []
          ).map((city) => city["city_name"]);

          setCities(tempCities);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.companyDetails?.address?.state, token]);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await search(enums.models["vendor-portal"]?.vendorCodes);

        if (res?.[0]?.availableCodes) {
          setVendorCodes(res?.[0]?.availableCodes || []);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    if (isDivisionControlled) {
      (async () => {
        try {
          const res = (await search(enums.models.divisions)) || [];
          setDivisions(res);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, []);

  const entityFields = [
    {
      name: "firstName",
      label: "First Name",
      fieldType: "textField",
    },
    {
      name: "lastName",
      label: "Last Name",
      fieldType: "textField",
    },
    {
      name: "vendorCode",
      label: "Vendor Codes",
      fieldType: "dropdown",
      options: vendorCodes || [],
      multiple: true,
      getOptionSelected: (option, value) => option === value,
    },
    {
      name: "division",
      label: "Division",
      fieldType: enums.fieldTypes.dropdown,
      options: divisions || [],
      multiple: true,
      getOptionLabel: (option) => option?.name || "",
      getOptionSelected: (opt, val) => opt?.name === val?.name,
      hidden: !isDivisionControlled,
      render: "name",
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
    },
    {
      name: "password",
      label: "Password",
      fieldType: "textField",
      hash: true,
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },
    {
      name: "companyDetails.name",
      label: "Company Name",
      fieldType: "textField",
    },

    {
      name: "companyDetails.address.country",
      label: "Country",
      fieldType: enums.fieldTypes.dropdown,
      options: countries,
      render: "companyDetails.address.country",
    },
    {
      name: "companyDetails.address.state",
      label: "State",
      fieldType: enums.fieldTypes.dropdown,
      options: states,
      render: "companyDetails.address.state",
    },
    {
      name: "companyDetails.address.city",
      label: "City",
      fieldType: enums.fieldTypes.dropdown,
      options: cities,
      render: "companyDetails.address.city",
    },

    {
      name: "companyDetails.address.street",
      label: "Street",
      fieldType: "textField",
    },
    {
      name: "companyDetails.address.pincode",
      label: "Pincode",
      fieldType: "textField",
    },
  ];

  const links = [
    {
      name: "Vendors",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.vendorCode?.join(", ") || "",
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      hidden: isEditable,
      label: values.disabled ? "Approve User" : "Disable User",
      iconName: values.disabled ? "disable" : "update",
      tooltip: values.disabled ? "Approve User" : "Disable User",
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
            iamControl: { disabled: !values.disabled },
          },
          [appModule]
        );

        window.location.reload();
      },
      color: "primary",
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Vendors"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
