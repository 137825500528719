import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHTextField from "../ReactHookFormControls/RHTextField";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import Button from "../../components/Button";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { resetPassword } from "./api";
import { useAuth } from "@ui/components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  confirmPassword: yup.string().min(8).required("Required"),
  password: yup.string().min(8).required(),
});
const StyledFormProvider = styled(FormProvider)`
  padding: 10vh;
  height: 100vh;
`;
const ResetPassword = () => {
  const formMethods = useForm({
    defaultValues: {},
    mode: "all",
    resolver: yupResolver(schema),
  });

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  if (auth.user) {
    return <Navigate to="/home" />;
  }
  const onSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      formMethods.setError("confirmPassword", {
        message: "Passwords do not match",
      });
      return;
    }
    await resetPassword({
      phone: location.state.phone.replace("+91", ""),
      password: values.password,
    });
    navigate("/signIn");
  };

  return (
    <StyledFormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <Grid
          style={{ height: "80vh" }}
          container
          spacing={2}
          direction="column"
          alignItems="center"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            xs={3}
            direction="row"
            spacing={3}
            style={{ maxWidth: "100%" }}
          >
            <Grid item xs={12}>
              <RHTextField
                fullWidth
                name="password"
                label={"Password"}
                type="password"
              />
            </Grid>

            <Grid item xs={12}>
              <RHTextField
                fullWidth
                name="confirmPassword"
                label={"Confirm Password"}
                type="password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            style={{ marginTop: "auto" }}
            onClick={formMethods.handleSubmit(onSubmit)}
          >
            Next
          </Button>
        </Grid>
      </form>
    </StyledFormProvider>
  );
};

export default ResetPassword;
