import { theme } from '../theme';

const layoutStyle = {
	pageRoot: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(3),
	},
	tableRoot: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(0),
	},
};

export { layoutStyle };
