import React from "react";
import clsx from "clsx";

import { Grid, TextField, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //	paddingBottom: theme.spacing(2),
  },
  textBox: {
    width: "100%",
  },
}));

const EnhancedFilterBar = (props) => {
  const classes = useStyles();
  const { filterData } = props;

  const handleChange = (prop) => (event, values) => {
    // do the validation here and set the filterData.
    props.getvalues(values);
  };

  const handleOnClose = (event, value, reason) => {
    if (reason === "clear") {
      props.resetFilter();
    }
  };

  return (
    <Grid
      container
      justify="space-evenly"
      alignItems="center"
      className={classes.root}
      style={{ ...props.styles }}
    >
      <Grid item xs={12}>
        <Autocomplete
          disabled={props.disabled}
          multiple
          id="size-small-outlined-multi"
          size="small"
          options={[]}
          value={filterData}
          getOptionLabel={(option) => ""}
          freeSolo
          onInputChange={(event, value, reason) =>
            handleOnClose(event, value, reason)
          }
          onChange={handleChange()}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                // variant="outlined"
                key={index}
                label={option}
                size="small"
                style={{ backgroundColor: "#E3F2FD", color: "#003675" }}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              className={clsx(classes.margin, classes.textBox)}
              {...params}
              variant={props.variant ? props.variant : "outlined"}
              label={props.label ? props.label : "Filter"}
              placeholder={props.placeholder ? props.placeholder : "Search"}
              InputProps={
                {
                  ...params.InputProps,
                  ...props?.InputProps,
                } || { ...params.InputProps }
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EnhancedFilterBar;
