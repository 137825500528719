import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApi["e-creatives"];

export const getSingleRecord = async (model, criteria = {}) => {
  try {
    // currently used for getEcommerceCarts and getLinkitCarts
    const url = baseApiUrl + model + "/getSingleRecord";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
