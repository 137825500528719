import React, { useEffect, useState } from "react";
import { defaultFilter } from "../Detail";
import { oopsNotFound, selectedDivisionName } from "@ui/Utils/helper";
import { useFormContext } from "react-hook-form";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import { ProductItem } from "./DetailHeader";

const DetailContent = ({ isEditable, initialValues }) => {
  const formMethods = useFormContext();
  const { watch } = formMethods;
  const values = watch();
  const customerId = values.createdBy?._id;
  const [vendorCodes, setVendorCodes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models["vendor-portal"]?.vendorCodes)) || [];
        setVendorCodes(res?.[0]?.availableCodes || []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return values.categories?.length ? (
    (values.categories || []).map((item, itemIndex) => {
      const subCatWithProduct = item?.subCategories?.find(
        (subCat) =>
          subCat.items?.find((subItem) => defaultFilter(subItem)) ||
          defaultFilter(null, subCat)
      );

      return subCatWithProduct ? (
        <ProductItem
          key={itemIndex}
          item={item}
          itemIndex={itemIndex}
          isEditable={isEditable}
          customerId={customerId}
          vendorCodes={vendorCodes}
          initialValues={initialValues}
        />
      ) : null;
    })
  ) : values.code ? (
    <div style={{ textAlign: "center" }}>
      <div>
        <img
          src={oopsNotFound}
          style={{ height: "320px", width: "440px" }}
          alt="Not Found"
        />
      </div>
      <div>
        <h3>{`No products were ordered in ${selectedDivisionName} Division`}</h3>
      </div>
    </div>
  ) : null;
};

export default DetailContent;
