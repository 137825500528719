import React from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Label from "@ui/components/Label";
import { Grid } from "@material-ui/core/index";
import {
  formatDateToRead,
  isAdmin,
  isVendor,
  uniqueArray,
} from "@ui/Utils/helper";
import "./divisionAnimation.css";
import CategoryGroup from "./CategoryGroup";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import { downloadPdf } from "../../api";
import { Autocomplete } from "@material-ui/lab/index";
import { TextField } from "@material-ui/core/index";

// const columns = [
//   { label: "Product", field: "product.styleNo" },
//   { label: "Category", field: "product.category.name" },
//   { label: "Image", field: "product.image" },
//   { label: "Melting", field: "melting" },
//   { label: "Screw", field: "screw" },
//   { label: "Size", field: "size" },
//   { label: "Color", field: "color" },
//   { label: "Remarks", field: "remarks" },
//   { label: "Quantity", field: "quantity" },
// ];

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch, getValues, reset } = formMethods;
  const values = watch();
  const params = useParams();
  const navigate = useNavigate();

  const [vendorsIncluded, setVendorsIncluded] = React.useState([]);

  React.useEffect(() => {
    const tempVendorsIncluded = [];
    if (values.asPer && values.asPer.vendorCode) {
      tempVendorsIncluded.push(values.asPer.vendorCode);
    }

    (values.categories || []).forEach((order) => {
      // Extract vendor code from asPer

      // Extract vendor codes from subCategories.vendors
      order.subCategories.forEach((subCategory) => {
        subCategory.vendors.forEach((vendor) => {
          if (vendor.vendorCode) {
            tempVendorsIncluded.push(vendor.vendorCode);
          }
        });
      });

      // Extract vendor codes from subCategories.items.product
      order.subCategories.forEach((subCategory) => {
        subCategory.items.forEach((item) => {
          if (item.product && item.product.vendorCode) {
            tempVendorsIncluded.push(item.product.vendorCode);
          }
        });
      });
    });

    setVendorsIncluded(uniqueArray(tempVendorsIncluded));
  }, [values.categories]);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + enums.models["e-creatives"].stockOrders);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [
    {
      name: enums.ordersType.stockOrder,
      url: "#/" + enums.models["e-creatives"].stockOrders,
      isDisplayLink: true,
    },
    {
      name: `${values.orderId || ""}`,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Download PDF",
      tooltip: "Download PDF",
      iconName: "pdf",
      hidden: isEditable,
      onClick: () => downloadPdf(values),
    },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  if (loading) return "";

  const headerValueStyle = {
    fontWeight: "bold",
    color: "#6e6e6e",
  };

  return (
    <>
      <Header links={links} pageTitle={"Stock Order"} actions={actions} />

      <Grid container spacing={3} style={{ marginBottom: "24px" }}>
        <Grid item xs={2}>
          <Label
            label={"Order ID"}
            value={values.orderId}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={2}>
          <Label
            label={"Destination Zone"}
            value={values.destinationZone}
            valueStyle={headerValueStyle}
          />
        </Grid>

        {/* <Grid item xs={2}>
          <Label
            label={"Source Zone"}
            value={values.sourceZone}
            valueStyle={headerValueStyle}
          />
        </Grid> */}

        <Grid item xs={2}>
          {!isVendor && (
            <Label
              label={"Vendors Included"}
              value={vendorsIncluded.join(", ")}
              valueStyle={headerValueStyle}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Label
            label={"Created"}
            value={`${
              values.createdBy?.firstName + " " + values.createdBy?.lastName
            }, ${formatDateToRead(values.dateCreated)}, ${
              values.createdBy?.phone
            }`}
            valueStyle={headerValueStyle}
          />
        </Grid>

        {/* <Grid item xs={4}>
          <Label label={"Expected" } />
        </Grid>
        <Grid item xs={4}>
          <Label label={"Selected" } />
        </Grid>
        <Grid item xs={4}>
          <Label label={"Vendor Wise"} />
        </Grid> */}
        <Grid item xs={6}>
          <Label
            label={"Expected By"}
            value={
              values.dateExpected ? formatDateToRead(values.dateExpected) : null
            }
            valueStyle={headerValueStyle}
          />
        </Grid>
        <Grid item xs={6}>
          {isEditable ? (
            <Autocomplete
              label={"Bulk Order Status"}
              options={Object.values(enums["vendor-portal"].orderStatus)}
              disableClearable={true}
              onChange={(_, val) => {
                const currentValues = getValues();

                (currentValues.categories || []).map(
                  (categoryGroupItem, catIndex) => {
                    (categoryGroupItem.subCategories || []).map(
                      (subCatGroup, subCatIndex) => {
                        currentValues.categories[catIndex].subCategories[
                          subCatIndex
                        ].items = (subCatGroup.items || []).map((item) => ({
                          ...item,
                          orderStatus: val,
                          dateDelivered: Date.now(),
                        }));

                        currentValues.categories[catIndex].subCategories[
                          subCatIndex
                        ].vendors = (subCatGroup.vendors || []).map((item) => ({
                          ...item,
                          orderStatus: val,
                          dateDelivered: Date.now(),
                        }));
                      }
                    );
                  }
                );

                reset(currentValues);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder={"Bulk Order Status"}
                  size="small"
                  style={{ width: "100%" }}
                  variant={"outlined"}
                  // label={label + (required ? " *" : "")}
                />
              )}
            />
          ) : (
            <Label value={""} label={"Bulk Order Status"} />
          )}
        </Grid>

        <Grid item xs={6}>
          <Label
            label={"Overall Remarks"}
            value={values.overallRemarks}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={6}>
          {isEditable && isAdmin ? (
            <RHTextField
              name={`adminRemarks`}
              label={"Admin Remarks"}
              multiline={true}
              rows={3}
              style={{ fontWeight: "bold", color: "#3b3b3b" }}
            />
          ) : (
            <Label
              label={"Admin Remarks"}
              value={values.adminRemarks}
              valueStyle={{ ...headerValueStyle, color: "#3b3b3b" }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

// const diamondColumns = [
//   { label: "Sr No", field: "srNo" },
//   { label: "Quantity", field: "diamondQuantity" },
//   { label: "Total Carat (cts)", field: "totalCarat" },

//   { label: "Shape", field: "shape" },
//   { label: "Color", field: "color" },

//   { label: "Solitaire Length (mm)", field: "solitaireSize.length" },
//   { label: "Solitaire Width (mm)", field: "solitaireSize.width" },

//   { label: "Sieve Length (mm)", field: "sieveSize.length" },
//   { label: "Sieve Width (mm)", field: "sieveSize.width" },

//   { label: "Cut", field: "cut" },
//   { label: "Clarity", field: "clarity" },

//   { label: "Polish", field: "polish" },
//   { label: "Symmetry", field: "symmetry" },
//   { label: "Fluorescence Intensity", field: "fluorescenceIntensity" },
//   { label: "Grading Lab", field: "gradingLab" },
// ];

export const ProductItem = ({
  item,
  itemIndex,
  isEditable,
  vendorCodes = [],
  // customerId,
}) => {
  return (
    <MainSection
      title={""}
      titleStyle={{ color: "black" }}
      style={{
        marginBottom: "24px",
        // opacity: sameDivision ? 1 : 0.5 :( Stock Order is only division level
      }}
    >
      <CategoryGroup
        categoryGroupItem={item}
        categoryGroupItemIndex={itemIndex}
        isEditable={isEditable}
        vendorCodes={vendorCodes}
      />
    </MainSection>
  );
};

// const componentStyles = {
//   greenLabel: { color: "black" },
// };

export default DetailHeader;
