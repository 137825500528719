import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { paginate, search } from "@ui/ComponentUtils/blueprintAPIs";
import {
  handleGlobalUserControl,
  isDivisionControlled,
  uniqueArray,
} from "@ui/Utils/helper";
import commonConfig from "config/commonConfig";

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.iam.users;
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    if (isDivisionControlled) {
      (async () => {
        try {
          const res = (await search(enums.models.divisions)) || [];
          setDivisions(res || []);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, []);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "firstName",
      label: "First Name",
      fieldType: "textField",
    },
    {
      name: "lastName",
      label: "Last Name",
      fieldType: "textField",
    },
    {
      name: "division",
      label: "Division",
      fieldType: "dropdown",
      options: divisions || [],
      multiple: true,
      getOptionLabel: (option) => option?.name || "",
      hidden: !isDivisionControlled,
      render: "name",
    },
    {
      name: "role",
      label: "Role",
      fieldType: enums.fieldTypes.dropdown,
      options: [
        enums.roles.admin,
        enums.roles.branch,
        enums.roles.inventoryManager,
        enums.roles.zoneManager,
        enums.roles.salesPerson,
      ],
    },
    {
      name: "password",
      label: "Password",
      fieldType: "textField",
      // type: "password",
      hash: true,
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
      dataType: enums.dataTypes.number,
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },
  ];

  const links = [
    {
      name: "Users",
      url: "#/" + enums.models.iam.users,
      isDisplayLink: true,
    },
    {
      name: (values.firstName || "") + " " + (values.lastName || ""),
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      hidden: isEditable,
      disabled: values.mustSignout,
      label: "Signout User",
      iconName: "signout",
      tooltip: "Signout " + values.firstName,
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
          },
          ["iam"]
        );

        window.location.reload();
      },
      color: "primary",
    },
    {
      hidden: isEditable,
      label: values.disabled ? "Approve User" : "Disable User",
      iconName: values.disabled ? "disable" : "update",
      tooltip: values.disabled ? "Approve User" : "Disable User",
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
            iamControl: { disabled: !values.disabled },
          },
          ["iam"]
        );

        window.location.reload();
      },
      color: "primary",
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Users"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
