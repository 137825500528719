import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import { uniqueArray, userDetails } from "@ui/Utils/helper";
import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const baseApiUrl = commonConfig.baseApi?.["vendor-portal"];

export const createRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + model + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.put(
      baseApiUrl + model + "/" + data?.code + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const syncExternalRecords = async (model, data = {}) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + "c/" + model + "/syncExternalRecords",
      data
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getQuotationPdfReport = async ({ data, pdfTitle, template }) => {
  try {
    if (template) {
      const res = await HTTP.post(
        commonConfig.microservices.pdfReport + "vendor-portal/pdf/base64",
        {
          context: {
            customData: data,
          },
          templateName:
            "quotations" +
            "/" +
            commonConfig["vendor-portal"]?.pdfVersion[template],
          pdfOptions: await pdfOptions(data, {
            title: pdfTitle,
          }),
        }
      );

      return res?.data?.data;
    } else {
      CustomEventEmitter.emit(
        "alert_error",
        "Select Template in Settings to continue."
      );
      throw { message: "Template not found" };
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const pdfOptions = async (
  contextData = {},
  { title = "Report" } = {}
) => {
  const clientInfo = userDetails.client;

  // let logo = "";

  // if (clientInfo?.logo?.location) {
  //   // response = await rest.get(clientInfo.logo.location, {
  //   //   responseType: "arraybuffer",
  //   // });
  //   // const data =
  //   //   "data:" +
  //   //   response.headers["content-type"] +
  //   //   ";base64," +
  //   //   Buffer.from(response.data, "binary").toString("base64");

  //   logo = `<img style="height:33px;width:80px;" src="${clientInfo?.logo?.location}" alt="" />`; //padding:10px 26px;
  // } else {
  //   logo = `<h3>${clientInfo.name}</h3>`;
  // }

  let topMargin = 5.5 + "cm";

  return {
    margin: { top: topMargin, right: "1.5cm", bottom: "80px", left: "0cm" },
    templateType: "ejs",
    headerTemplate: `
  <div style="-webkit-print-color-adjust: exact; font-size:12px;background-color: #f9fae0;display: flex;height:70%;
  width:90%;font-size:10px;margin: 0 auto;padding: auto; align-items: center;">
  <div style="text-align:center;flex: 1;width:40%;justify-content:center;align-items:center;
    font-size: 10px;font-weight: 600;color: black;padding-left:35px;">
      ${clientInfo.name}
    </div>

    <div style="text-align:center;flex: 1;width:30%";>
      ${title}
    </div>
    
    <div style="display:flex;text-align:left;flex:1;width:30%;align-items:center;
     margin-left: 35px;padding-right:5px;height:100%;flex-direction: column;justify-content:center;">
      <div style="display:flex;">Order ID: ${contextData.orderId || ""}</div>
      <div style="display:flex;margin-top:8px;">Vendor Code: ${
        contextData.vendorCode || ""
      }</div>
    </div>
  </div>
`,
    footerTemplate: `<div style="color: lightgray;  font-size: 10px; margin-top: 15px; width: 100%; margin:0  36px;">
  <hr>
   <div style='display:flex; justify-content: space-between;'> <div><span style='text-align:left;font-size: 7px;'>
   ${
     clientInfo && clientInfo.address
       ? (clientInfo.address.street ? clientInfo.address.street + ", " : "") +
         (clientInfo.address.city ? clientInfo.address.city + ", " : "") +
         (clientInfo.address.state ? clientInfo.address.state + ", " : "") +
         (clientInfo.address.country && clientInfo.address.country.name
           ? clientInfo.address.country.name
           : "")
       : ""
   }
   </span></div> 
   <div style="font-size: 7px;">Page <span class="pageNumber" style="font-size: 7px;"></span>
    of <span class="totalPages" style="font-size: 7px;"></span></div></div>
  </div>`,
  };
};

export const isVendorCodeEditable = (product, initialValues) => {
  const tempQuotation = { ...initialValues };

  const products = (tempQuotation.products || []).filter((prod) => {
    if (product?._id) {
      return (
        product._id === prod._id &&
        !(
          prod.orderStatus !== enums["vendor-portal"]?.orderStatus.pending ||
          prod.adminRemarks
        )
      );
    }

    return !(
      prod.orderStatus !== enums["vendor-portal"]?.orderStatus.pending ||
      prod.adminRemarks
    );
  });

  if (products.length) {
    return true;
  }
  return false;
};

export const urlToBase64 = async (url) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + enums.models["vendor-portal"].quotations + "/urlToBase64",
      { url: url }
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
