import MuiButton from './MuiButton';
// import MuiCardActions from './MuiCardActions';
// import MuiCardContent from './MuiCardContent';
// import MuiCardHeader from './MuiCardHeader';
// import MuiChip from './MuiChip';
// import MuiIconButton from './MuiIconButton';
// import MuiInputBase from './MuiInputBase';
// import MuiLinearProgress from './MuiLinearProgress';
// import MuiListItemIcon from './MuiListItemIcon';
// import MuiOutlinedInput from './MuiOutlinedInput';
// import MuiPaper from './MuiPaper';
import MuiTableHead from './MuiTableHead';
import MuiTableCell from './MuiTableCell';
// import MuiTableRow from './MuiTableRow';
// import MuiToggleButton from './MuiToggleButton';
// import MuiTypography from './MuiTypography';

export default {
	/*   MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
 
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper, */
	MuiTableHead,
	//  MuiIconButton,
	MuiButton,
	MuiTableCell,
	// MuiTableRow,
	// MuiToggleButton,
	// MuiTypography
};
