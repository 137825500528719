import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core/index";
import ProductsGrid from "./ProductsGrid";
import Header from "./Header";
import Cart from "./Cart";

const PublicCatalogues = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openCartDialog, setOpenCartDialog] = useState(false);

  return (
    <Paper>
      <Header
        cartItems={cartItems}
        openCartDialog={openCartDialog}
        setOpenCartDialog={setOpenCartDialog}
      />
      <div
        style={{
          marginTop: "16px",
          marginBottom: "48px",
          paddingBottom: "98px",
          padding: "16px",
        }}
      >
        {openCartDialog ? (
          <Cart cartItems={cartItems} setCartItems={setCartItems} />
        ) : (
          <ProductsGrid cartItems={cartItems} setCartItems={setCartItems} />
        )}
      </div>
    </Paper>
  );
};

export default PublicCatalogues;
