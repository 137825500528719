import React from "react";

const ReactDocViewer = (props) => {
  const { file, style } = props;
  const officeAppsUrl = "https://view.officeapps.live.com/op/embed.aspx?src=";

  return (
    <div style={{ position: "relative" }}>
      <iframe
        src={officeAppsUrl + file}
        style={{ height: 500, ...style }}
        width={"100%"}
      />
    </div>
  );
};

export default ReactDocViewer;
