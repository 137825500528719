import React, { useEffect, useState } from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, isDivisionControlled } from "@ui/Utils/helper";
import { getUnAssignedCodes } from "../api";

const columns = [
  {
    title: "First Name",
    field: "firstName",
  },
  {
    title: "Last Name",
    field: "lastName",
  },
  {
    title: "Vendor Codes",
    field: "vendorCode",
    render: (row) => row?.vendorCode?.join(", "),
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Divisions",
    field: "division",
    render: (row) =>
      row.division?.map && row.division.map((div) => div.name).join(", "),
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
];

const VendorsList = () => {
  const [unAssignedCodes, setUnAssignedCodes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getUnAssignedCodes(
          enums.models["vendor-portal"]?.vendorCodes
        );

        setUnAssignedCodes(res || []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <GeneralListPage
      title={
        <p>
          {unAssignedCodes?.length
            ? `Not Assigned Vendor Codes: ${unAssignedCodes?.join(", ")}`
            : "All the Available Vendor Codes are assigned"}
        </p>
      }
      columns={columns.filter((col) =>
        isDivisionControlled ? true : col.field !== "division"
      )}
      model={enums.models["vendor-portal"]?.users}
      headerTitle="Vendors"
      searchFields={[
        "vendorCode",
        "firstName",
        "lastName",
        "phone",
        "division.name",
      ]}
      mainQuery={{
        criteria: [{ role: enums.roles.vendor }, {}],
        populate: [
          { path: "products", select: "images" },
          { path: "createdBy", select: "firstName lastName" },
        ],
      }}
    />
  );
};

export default VendorsList;
