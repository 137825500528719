import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { confirmable } from "react-confirm";

const PasswordDialog = ({ show, proceed, confirmation, options }) => {
  const { type } = options;
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const typeOfInput = useMemo(() => {
    if (type !== "password") return type;
    else {
      if (showPassword) return "text";
      else return "password";
    }
  }, [type, showPassword]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={show}
      onClose={() => proceed(null)}
      aria-labelledby="password-dialog"
    >
      <Grid item container justifyContent="center">
        <DialogTitle id="form-dialog-title">
          <Typography color="primary">{"PDF Password"}</Typography>
        </DialogTitle>
      </Grid>
      <DialogContent>
        <TextField
          helperText={confirmation}
          error={options.error}
          autoFocus
          placeholder={"Enter password"}
          margin="dense"
          id="password"
          label={"Password"}
          type={typeOfInput}
          value={password}
          onChange={onPasswordChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "8px 15px" }}
        >
          <Grid item xs={6}>
            {type === "password" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onChange={() => setShowPassword((prev) => !prev)}
                    name="multiple-email"
                    color="primary"
                  />
                }
                label={"Show Password"}
              />
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            xs={6}
          >
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              onClick={() => {
                proceed(null);
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                proceed(password);
              }}
            >
              {"Ok"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

PasswordDialog.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string, // arguments of your confirm function
  options: PropTypes.object, // arguments of your confirm function
};

PasswordDialog.defaultProps = {
  options: { type: "password" },
};
export default confirmable(PasswordDialog);
