import MainCard from "@ui/MuiComponents/Card/MainCard";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography } from "@material-ui/core/index";
import enums from "helpers/enums";
import { useWatch } from "react-hook-form";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import _ from "lodash";
import { getProductImageUri } from "@ui/Utils/helper";
import Label from "@ui/components/Label";
import { getPaginatedData } from "@ui/ComponentUtils/blueprintAPIs";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useFormContext } from "react-hook-form";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import IndividualWorkflow from "./IndividualWorkflow";
import { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();

const DetailContent = ({
  isEditable,
  selectedProducts,
  setSelectedProducts,
  showDeleteProducts,
  setShowDeleteProducts,
}) => {
  const formMethods = useFormContext();
  const { setValue, watch } = formMethods;
  const values = watch();

  const handleChange = (event) => {
    setValue(`workflow`, event.target.value);
  };

  return (
    <>
      <MainSection
        title={values.code ? values.workflow + " Workflow" : "Sync Workflow"}
      >
        {values.code ? null : (
          <RadioGroup value={values.workflow} onChange={handleChange}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value={enums.catalogueWorkflow.bulk}
                  control={<Radio />}
                  label={enums.catalogueWorkflow.bulk}
                  labelPlacement="top"
                  disabled={!isEditable || values.code}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value={enums.catalogueWorkflow.individual}
                  control={<Radio />}
                  label={enums.catalogueWorkflow.individual}
                  labelPlacement="top"
                  disabled={!isEditable || values.code}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        )}

        {values.workflow === enums.catalogueWorkflow.individual ? (
          <IndividualWorkflow
            isEditable={isEditable}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            showDeleteProducts={showDeleteProducts}
            setShowDeleteProducts={setShowDeleteProducts}
          />
        ) : (
          <BulkCriteria isEditable={isEditable} />
        )}
      </MainSection>
      {isEditable ||
      values.workflow === enums.catalogueWorkflow.individual ? null : (
        <MatchingProducts />
      )}
    </>
  );
};

const BulkCriteria = ({ isEditable }) => {
  const values = useWatch();
  return (
    <MainSection title="SET Products Filter">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {isEditable ? (
            <RHAsyncAutoComplete
              name={`condition.styles`}
              label="Designs"
              filterOptionLabelKey="styleNo"
              freeSolo={true}
              multiple={true}
              value={values.condition?.styles || []}
              selectOnFocus={true}
              // clearOnBlur={true}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.styleNo
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(`${inputValue}`);
                }

                return filtered;
              }}
              apiUrl={enums.models.products + "/uniqueDesigns"}
              apiBody={{
                filterColumns: {
                  code: 1,
                },
              }}
              apiMethod="post"
              getOptionLabel={(opt) => opt.styleNo || opt || ""}
              getOptionSelected={(opt, val) =>
                opt?.styleNo && val?.styleNo
                  ? opt?.styleNo === val?.styleNo
                  : opt === val
              }
            />
          ) : (
            <Label
              label={"Designs"}
              value={(values.condition?.styles || [])
                .map((style) => style?.styleNo || style)
                .join(", ")}
            />
          )}
        </Grid>

        <Grid item xs={2}>
          <Typography>Gross Weight</Typography>
          {isEditable
            ? null
            : (values.condition?.grossWeight?.from || "") +
              " - " +
              (values.condition?.grossWeight?.to || "")}
        </Grid>

        {isEditable ? (
          <>
            <Grid item xs={2}>
              <RHTextField
                label="From"
                name={`condition.grossWeight.from`}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <RHTextField
                label="To"
                name={`condition.grossWeight.to`}
                type="number"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4}></Grid>
        )}

        <Grid item xs={6}>
          {isEditable ? (
            <RHAsyncAutoComplete
              label="Categories"
              multiple={true}
              apiUrl={enums.models.category + "/search"}
              apiMethod={"post"}
              name={`condition.category`}
              getOptionLabel={(option) => option?.name || ""}
              getOptionSelected={(option, value) => option.name === value.name}
            />
          ) : (
            <Label
              label={"Categories"}
              value={values.condition?.category
                ?.map((cat) => cat?.name)
                .join(", ")}
            />
          )}
        </Grid>

        <Grid item xs={2}>
          <Typography>Net Weight</Typography>
          {isEditable
            ? null
            : (values.condition?.netWeight?.from || "") +
              " - " +
              (values.condition?.netWeight?.to || "")}
        </Grid>

        {isEditable ? (
          <>
            <Grid item xs={2}>
              <RHTextField
                label="From"
                name={`condition.netWeight.from`}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <RHTextField
                label="To"
                name={`condition.netWeight.to`}
                type="number"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4}></Grid>
        )}

        <Grid item xs={2}>
          <RHCheckbox
            label="With Images"
            name={`condition.imageFlag`}
            color={"secondary"}
            disabled={!isEditable}
          />
        </Grid>

        {/* <Grid
              item
              xs={2}
              onMouseEnter={() => setFilterHover(true)}
              onMouseLeave={() => setFilterHover(false)}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#ffc800",
                  padding: "10px",
                  boxShadow: filterHover && "5px 10px 8px #ffc800",
                }}
                onClick={fetchAvailableProds}
              >
                Filter Products
              </Button>
            </Grid> */}
      </Grid>
    </MainSection>
  );
};

const defaultPageSize = 8;

const MatchingProducts = ({}) => {
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: defaultPageSize,
  });
  const [data, setData] = useState({
    records: [],
    metaInfo: {
      currentPageNo: 0,
      perPageRecords: defaultPageSize,
      totalPages: 1,
      totalRecords: 0,
      criteria: [],
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (values._id) {
          const res = await getPaginatedData(enums.models.products, {
            page: pagination.page,
            pageSize: pagination.pageSize,
            criteria: [{ catalogues: { $in: [values._id] } }, {}],
          });

          setData({ records: res.records, metaInfo: res.paginationInfo });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [values.dateModified, pagination, values.frontend?.sync, values._id]);

  return (
    <MainSection
      title={`${data.metaInfo.totalRecords || 0} Designs, ${
        data.metaInfo.uniqueStocks || 0
      } Stocks`}
      style={{ marginTop: "24px" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <FirstPageIcon
            style={styles.arrow}
            onClick={() => {
              if (pagination.page - 1 < 0) {
                return;
              }
              let page = pagination.page - 1;

              setPagination({
                page: page,
                pageSize: defaultPageSize,
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {`${pagination.page + 1} / ${
            Math.ceil(data.metaInfo.totalRecords / defaultPageSize) || 1
          }`}{" "}
        </Grid>
        <Grid item xs={2}>
          <LastPageIcon
            style={styles.arrow}
            onClick={() => {
              if (
                pagination.page + 1 <
                Math.ceil(data.metaInfo.totalRecords / defaultPageSize)
              ) {
                setPagination({
                  page: pagination.page + 1,
                  pageSize: defaultPageSize,
                });
              }
            }}
          />
        </Grid>

        <Grid item xs={12} container spacing={4}>
          {(data.records || []).map((prod, index) => (
            <Grid item xs={3} key={index}>
              <ProductCard prod={prod} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </MainSection>
  );
};
const ProductCard = ({ prod }) => {
  return (
    <MainCard
      fileName={prod.styleNo}
      title={prod?.styleNo}
      product={prod}
      image={getProductImageUri(prod)}
    >
      <Typography variant="body2" color="text.secondary">
        Category: {prod?.category?.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Gross Weight: {prod?.grossWeight?.toFixed(2)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Net Weight: {prod?.netWeight?.toFixed(2)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Quantity: {prod?.quantity}
      </Typography>
    </MainCard>
  );
};

const styles = {
  arrow: {
    fontSize: "50px",
    cursor: "pointer",
  },
};
export default DetailContent;
