import React from "react";
import { Typography, Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";

const Label = ({
  sameLine,
  labelVariant,
  labelStyle,
  valueVariant,
  valueStyle,
  value,
  label,
  noColon,
  style,
  tooltip,
}) => {
  const LabelComp = () => {
    return (
      <Typography
        variant={labelVariant}
        sx={{ color: "#6a6d70" }}
        style={labelStyle}
      >
        {label}
        {noColon || !label ? "" : ":"}
        &nbsp;
      </Typography>
    );
  };

  const Value = () => {
    return (
      <Typography
        variant={valueVariant}
        sx={{ color: "#32363a", whiteSpace: "pre-line" }}
        style={valueStyle}
      >
        {value}
      </Typography>
    );
  };

  return (
    <Tooltip title={tooltip}>
      <Box style={style}>
        {sameLine ? (
          <Grid container>
            <LabelComp /> <Value />
          </Grid>
        ) : (
          <>
            <LabelComp />
            <Value />
          </>
        )}
      </Box>
    </Tooltip>
  );
};

export default Label;
