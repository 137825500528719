import { theme, colorPalette } from '../theme';

const commonStyle = {
	pageStyle: {
		maxWidth: '100%',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(0),
	},
	linkStyles: {
		textDecoration: 'None',
		textAlign: 'none',
	},

	textFieldStyles: {
		width: '100%',
	},

	buttonStyle: { color: colorPalette.white },
	loginForm: {
		width: '100%',
	},
	loginFormContainer: {
		justifyContent: 'center',
		//width:'60%'
	},
	formFieldGrid: {
		width: '100%',
	},
	formField: {
		//width:'100%'
	},

	caption: {
		color: colorPalette.grey,
	},
	label: {
		color: colorPalette.grey,
	},
	selectStyle: {
		width: '150px',
	},
	textFieldVariant: 'outlined',

	submitButton: {
		color: 'F8991C',
	},
	typographyVariant: 'body2, overline',

	breadCrumbContainer: {
		padding: theme.spacing(2),
	},

	saveIcon: {
		borderRadius: theme.spacing(6),
		backgroundColor: colorPalette.orange,
		color: colorPalette.white,
	},
	cancelButton: {
		width: 104,
		borderRadius: theme.spacing(6),
		backgroundColor: colorPalette.white,
		padding: theme.spacing(2, 5, 2, 5),
		// border:`1px solid ${colorPalette.orange}`,
	},

	subHeadingVariant: 'h5',
	tableHeaderTextStyle: {
		fontWeight: '900',
		color: colorPalette.black,
	},

	tableRowTextStyle: {
		color: colorPalette.textPrimary,
	},
	// for section creation in object page
	section: {
		boxShadow: 'inset 0 -0.0625rem #d9d9d9',
	},
	sectionTitle: {
		color: theme.palette.primary.main,
	},
	p1: {
		padding: theme.spacing(1),
	},
	p2: {
		padding: theme.spacing(2),
	},
	p3: {
		padding: theme.spacing(3),
	},

	pb1: {
		paddingBottom: theme.spacing(1),
	},
	pb2: {
		paddingBottom: theme.spacing(2),
	},
	pb3: {
		paddingBottom: theme.spacing(3),
	},

	pt1: {
		paddingTop: theme.spacing(1),
	},
	pt2: {
		paddingTop: theme.spacing(2),
	},
	pt3: {
		paddingTop: theme.spacing(3),
	},

	pl1: {
		paddingLeft: theme.spacing(1),
	},
	pl2: {
		paddingLeft: theme.spacing(2),
	},
	pl3: {
		paddingLeft: theme.spacing(3),
	},

	pr1: {
		paddingRight: theme.spacing(1),
	},
	pr2: {
		paddingRight: theme.spacing(2),
	},
	pr3: {
		paddingRight: theme.spacing(3),
	},

	m1: {
		margin: theme.spacing(1),
	},
	m2: {
		margin: theme.spacing(2),
	},
	m3: {
		margin: theme.spacing(3),
	},

	mb1: {
		marginBottom: theme.spacing(1),
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
	mb3: {
		marginBottom: theme.spacing(3),
	},

	mt1: {
		marginTop: theme.spacing(1),
	},
	mt2: {
		marginTop: theme.spacing(2),
	},
	mt3: {
		marginTop: theme.spacing(3),
	},

	ml1: {
		marginLeft: theme.spacing(1),
	},
	ml2: {
		marginLeft: theme.spacing(2),
	},
	ml3: {
		marginLeft: theme.spacing(3),
	},

	mr1: {
		marginRight: theme.spacing(1),
	},
	mr2: {
		marginRight: theme.spacing(2),
	},
	mr3: {
		marginRight: theme.spacing(3),
	},
	objectPagePaperContainer: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},

	masterDataDetailsStyles: {
		iconStyle: {
			width: theme.spacing(5),
			height: theme.spacing(5),
			color: theme.palette.emphasis.low,
		},
	},

	subHeadingColorStyles: {
		backgroundColor: theme.palette.lightBlue.main,
	},
	forgotPasswordStyle: {
		fontSize: 10,
		marginLeft: theme.spacing(2),
		textDecoration: 'none',
	},

	pageContainer: {
		padding: theme.spacing(1),
		background: colorPalette.lightGrey,
	},

	textFieldSize: 'small',
	textfieldSection: {
		color: theme.palette.primary.main,
		fontWeight: 500,
		letterSpacing: 1.2,
	},

	listPageContainerStyle: {
		backgroundColor: colorPalette.lightGrey,
		height: '100%',
		overflow: 'hidden',
	},
	listTitleStyle: {
		width: '100%',
		height: '73px',
	},

	imgThumb: {
		width: '45px',
		height: '38px',
	},
	saveButton: {
		borderRadius: theme.spacing(1),
		backgroundColor: colorPalette.secondary,
		color: colorPalette.white,
	},
	disabledButton: {
		borderRadius: theme.spacing(1),
		backgroundColor: colorPalette.disabled,
		color: colorPalette.grey1,
		padding: theme.spacing(2, 5, 2, 5),
	},

	archiveButton: {
		// width:60,
		borderRadius: theme.spacing(1),
		backgroundColor: colorPalette.white,
		border: `1px solid ${colorPalette.orange}`,
	},

	tableHeaderStyle: {
		fontWeight: '900',
		variant: 'BODY2',
		color: colorPalette.black,
		letterSpacing: '1px',
	},

	subContainerBorder: {
		border: '1px solid #E9ECEF',
	},
	headerColour: {
		backgroundColor: '#E0E0E0',
	},
	tableHeaderStyleGrey: {
		textAlign: 'left',
		fontWeight: 'bold',
		color: colorPalette.grey1,
	},

	cancelIcon: {
		borderRadius: theme.spacing(6),
		backgroundColor: colorPalette.white,
		color: colorPalette.secondary,
		border: `1px solid ${colorPalette.orange}`,
	},
	iconStyle: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		marginRight: theme.spacing(3),
		paddingTop: theme.spacing(2),
	},
	disabledIcon: {
		border: theme.spacing(6),
		backgroundColor: colorPalette.disabled,
		color: colorPalette.grey1,
	},
	iconStyleSideNav: {
		height: theme.spacing(5),
	},
	errorStyle: {
		padding: theme.spacing(0, 0, 0, 4),
		color: colorPalette.error,
		fontSize: '10px',
	},
	noDataStyleGrid: {
		textAlign: 'center',
		paddingTop: theme.spacing(10),
		height: '200px',
	},
	noDataStyleTypo: {
		textDecoration: 'none',
		alignSelf: 'center',
		letterSpacing: '0.15px',
	},
	errorStyle2: {
		padding: theme.spacing(0, 0, 0, 4),
		color: colorPalette.error,
		fontSize: '10px',
	},
	dashBoardheadingStyle: {
		color: colorPalette.primary,
		fontWeight: 600,
		letterSpacing: theme.letterSpacing.BODY1,
	},
	errorVariant: theme.typographyStyles.CAPTION,
	containedButtonDisableStyle: {
		borderRadius: theme.spacing(1),
		backgroundColor: colorPalette.secondary,
		color: colorPalette.white,
		opacity: '38%',
	},
	containedSubmitButtonDisableStyle: {
		borderRadius: theme.spacing(1),
		backgroundColor: colorPalette.secondary,
		color: colorPalette.white,
		opacity: '38%',
		marginLeft: theme.spacing(2),
	},
	outlinedButtonDisableStyle: {
		width: 60,
		borderRadius: theme.spacing(1),
		backgroundColor: colorPalette.white,
		border: `1px solid ${colorPalette.secondary}`,
		opacity: '38%',
	},
	basicInfoGrid: {
		height: '72vh',
		overflow: 'auto',
		width: '100%',
	},
	formioDisabledAutoComplete: {
		backgroundColor: '#e9ecef',
	},
	formioDisabledTextfield: {
		style: {
			backgroundColor: '#e9ecef',
			color: '#495057',
		},
	},
	svgIconStyle: {
		height: '24px',
		opacity: '57%',
	},
};

export { commonStyle };
