import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHTextField from "../../@ui/ReactHookFormControls/RHTextField";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import Button from "../../components/Button";
import { Navigate, useNavigate } from "react-router-dom";
import { signup } from "./api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { useAuth } from "@ui/components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
   body {
    background: linear-gradient(
    180deg,
    rgba(255, 88, 88, 0.1) 0%,
    rgba(123, 166, 229, 0.31) 69.79%
  );
  font-family: 'Advent Pro', sans-serif; 
  min-height: 100vh;
  padding-inline: 2vh;overflow:auto;
  }
 
`;

const schema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  companyDetails: yup.object({
    name: yup.string().required("Required"),
  }),
  email: yup.string().email().required("Required"),
  password: yup.string().min(8).required("Required"),
  confirmPassword: yup.string().min(8).required("Required"),
  phone: yup.string().length(10, "Invalid phone number").required("Required"),
});

const StyledFormProvider = styled(FormProvider)`
  padding: 10vh;
  height: 100vh;
`;
const SignUp = () => {
  const formMethods = useForm({
    defaultValues: {},
    mode: "all",
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const auth = useAuth();

  if (auth.user) {
    return <Navigate to="/home" />;
  }

  const onSubmit = async (values) => {
    if (isNaN(values.phone)) {
      formMethods.setError("phone", {
        message: "Invalid phone",
      });
      return;
    }
    if (values.password !== values.confirmPassword) {
      formMethods.setError("confirmPassword", {
        message: "Passwords do not match",
      });
      return;
    }
    try {
      await signup(values);
      navigate("/home", {
        state: { values },
      });
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        CustomEventEmitter.emit("alert_error", "User with phone number exists");
        return;
      }
      CustomEventEmitter.emit("alert_error", err.message);
    }
  };
  return (
    <StyledFormProvider {...formMethods}>
      <GlobalStyle />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Typography variant="h4" gutterBottom>
          Sign Up
        </Typography>
        <Grid
          // style={{ height: "80vh" }
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            xs={12}
            spacing={3}
            direction="row"
            style={{ maxWidth: "100%" }}
          >
            <Grid item xs={12}>
              <RHTextField required name="firstName" label={"First Name"} />
            </Grid>
            <Grid item xs={12}>
              <RHTextField required name="lastName" label={"Last Name"} />
            </Grid>
            <Grid item xs={12}>
              <RHTextField
                required
                name="companyDetails.name"
                label={"Company Name"}
              />
            </Grid>
            <Grid item xs={12}>
              <RHTextField
                required
                name="email"
                label={"Email"}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <RHTextField
                required
                name="phone"
                label={"Phone"}
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <RHTextField
                required
                name="password"
                label={"Password"}
                type="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <RHTextField
                required
                name="confirmPassword"
                label={"Confirm Password"}
                type="password"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              style={{ marginTop: "auto" }}
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </StyledFormProvider>
  );
};

export default SignUp;
