import HTTP from "@ui/Utils/httpWithoutLoading";
import commonConfig from "config/commonConfig";

let baseApiUrl = commonConfig.baseApiUrl;

export const getAsyncData = async (
  apiUrl,
  { apiMethod, apiBody = {}, customBaseApiUrl }
) => {
  try {
    // if (apiUrl?.toString().match('data-engine')) {
    //   baseApiUrl = 'http://localhost:2000/api/';
    // }

    apiUrl = (customBaseApiUrl || baseApiUrl) + apiUrl;

    const response = await HTTP[apiMethod](apiUrl, apiBody); // HTTP is an object and is reading its key from apiMethod

    return response?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
