import React, { useRef, useState } from "react";
import enums from "helpers/enums";
import {
  formatDateToRead,
  isDivisionControlled,
  isVendor,
  selectedDivisionId,
  uniqueArray,
  userDetails,
} from "@ui/Utils/helper";
import { getPaginatedData } from "../../api";
import { useNavigate } from "react-router-dom/dist/index";
import MatTable from "@ui/components/MatTable";
import AdvanceSearchFilter from "@ui/Controls/Search/AdvanceSearchFilter";
import Header from "@ui/ComponentUtils/Header";

const columns = [
  {
    title: "Quotation No",
    field: "quotationNo",
  },
  {
    title: "Products",
    field: "items",
    sorting: false,
    render: (row) => row?.items?.length,
  },
  {
    title: "Customized",
    sorting: false,
    render: (row) => row?.items?.filter((item) => item?.isCustomized).length,
  },
  {
    title: "Quantity",
    sorting: false,
    render: (row) =>
      row?.items?.map((item) => item?.quantity).reduce((a, b) => a + b, 0),
  },
  {
    title: "Order Status",
    sorting: false,
    render: (row) => {
      const types = uniqueArray(
        (row.items || []).map((item) => item?.orderStatus).filter((v) => v)
      );

      return types.join(", ");
    },
  },
  {
    title: "Customer",
    render: (row) =>
      (row?.customer?.firstName || "") +
      " " +
      (row?.customer?.lastName || "") +
      ` ${
        row.customer?.companyDetails?.name
          ? "(" + row.customer?.companyDetails?.name + ")"
          : ""
      }`,
  },
  {
    title: "Created",
    render: (row) => (
      <div style={{ minWidth: "110px" }}>
        <div>{formatDateToRead(row?.dateCreated)}</div>
        <div style={{ fontSize: "12px" }}>
          {row?.createdBy?.firstName + " " + row?.createdBy?.lastName}
        </div>

        <div style={{ fontSize: "12px", fontStyle: "italic" }}>
          {row?.createdBy?.role}
        </div>
      </div>
    ),
  },
];

const ExhibitionQuotationsList = () => {
  const model = enums.models["e-creatives"]?.exhibitionQuotations,
    searchFields = [
      "quotationNo",
      "items.product.sku",
      "items.product.styleNo",
    ],
    userFields = ["$createdBy.firstName", "$createdBy.lastName"],
    headerTitle = "Exhibit it",
    title = "",
    hideAction = { create: true, delete: true, selection: true };

  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 100,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  // const [totalCalc, setTotalCalc] = useState({ grossWeight: 0, netWeight: 0 });
  const navigate = useNavigate();
  const tableRef = useRef();
  const [filterCriteria, setFilterCriteria] = useState([]);

  const getData = async (query = {}) => {
    setListPageTableQuery(query);
    let tabCondition = {};

    if (isDivisionControlled) {
      // product is not having division._id.. never placed quotation from division client in dev or beta or mobile app needs to send in req..
      tabCondition["items.product.division._id"] = selectedDivisionId;
    }

    if (isVendor) {
      tabCondition["items.vendorCode"] = { $in: userDetails.vendorCode };
      tabCondition["items.orderStatus"] = {
        $in: [
          enums["vendor-portal"].orderStatus.accepted,
          enums["vendor-portal"].orderStatus.assigned,
          enums["vendor-portal"].orderStatus.canceled,
          enums["vendor-portal"].orderStatus.delivered,
          enums["vendor-portal"].orderStatus.inProgress,
          enums["vendor-portal"].orderStatus.resent,
        ],
      };
    }

    if (
      query.criteria?.[query.criteria.length - 1]?.searchElements?.length ||
      filterCriteria?.[filterCriteria.length - 1]?.searchElements?.length
    ) {
      if (query.criteria?.[0]?.$or) {
        query.criteria.unshift(tabCondition);
      } else {
        query.criteria = query.criteria || [];
        query.criteria[0] = tabCondition;
      }
    } else {
      query.criteria = [tabCondition, {}];
    }

    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }
    query = {
      ...query,
      filterColumns: {
        code: 1,
        customer: 1,
        createdBy: 1,
        dateCreated: 1,
        quotationNo: 1,
        "items.orderStatus": 1,
        "items.quantity": 1,
        "items.isCustomized": 1,
      },
    };

    try {
      let response = await getPaginatedData(model, query);

      if (response && response.paginationInfo) {
        const records = response.records || [];

        // setTotalCalc({
        //   grossWeight: response.paginationInfo.totalGrossWeight || 0,
        //   netWeight: response.paginationInfo.totalNetWeight || 0,
        // });
        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);

      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const onSelectionChange = (rows) => {
    let currentPageNum = tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }
    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
  };

  const handleSearch = (filterQuery = []) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: filterQuery,
    };
    setFilterCriteria(filterQuery);
    tableRef.current.onQueryChange(queryData);
  };

  return (
    <div>
      <Header links={[]} pageTitle={headerTitle} actions={[]} />

      <AdvanceSearchFilter
        searchFields={searchFields}
        userFields={userFields}
        handleSearch={handleSearch}
      />

      <MatTable
        tableRef={tableRef}
        columns={columns}
        data={(query) => getData(query)}
        // title={`
        // Gross: ${totalCalc.grossWeight?.toFixed(3)}
        // <<< -- >>>
        // ${totalCalc.netWeight?.toFixed(3)} :Net`}
        options={{
          selection: !hideAction.selection,
          search: false,
          pageSize: listPageTableQuery.pageSize,
        }}
        onRowClick={(_, selectedRow) => {
          navigate(`/${model}/${selectedRow.code}`);
        }}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export default ExhibitionQuotationsList;
