import { isAdmin, isSuperAdmin, userDetails } from "@ui/Utils/helper";
import enums from "helpers/enums";
import commonConfig from "../../config/commonConfig";
import HTTP from "../Utils/http";

const baseApiUrl = commonConfig.baseApiUrl;

const buildUrl = (appModule, action) => {
  return `${baseApiUrl}${appModule}/users/${action}`;
};

export const signup = async (userData, appModule = commonConfig.appModule) => {
  try {
    const url = buildUrl(appModule, "signup");
    const res = await HTTP.post(url, userData);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const login = async (
  userData,
  json = { iamLogin: false, appModule: commonConfig.appModule },
  additionalHeaders = {}
) => {
  try {
    let url;
    const appModule = json.appModule || commonConfig.appModule;

    if (json.iamLogin) {
      url = `${baseApiUrl}iam/users/signin`;
    } else {
      url = buildUrl(appModule, "signin");
    }
    const res = await HTTP.post(url, userData, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const logout = async (appModule = commonConfig.appModule, href) => {
  try {
    const url = buildUrl(appModule, "signout");
    const res = await HTTP.post(url);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = href || "/";
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const resetPassword = async (
  userData = { phone: "", password: "" },
  appModule = commonConfig.appModule
) => {
  try {
    const url = buildUrl(appModule, "resetPassword");
    const res = await HTTP.post(url, userData);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const sendOTP = async ({
  phone,
  throwIfUserExist,
  throwIfUserDoesNotExist,

  clientCode,
  shortCode,
  shortCodeModel,
  shortCriteria,

  sentOTPorderId,
  appModule,
}) => {
  try {
    appModule = appModule || commonConfig.appModule;

    const url = buildUrl(appModule, "sendOTP");
    const res = await HTTP.post(
      url,
      sentOTPorderId
        ? { otpSession: sentOTPorderId }
        : {
            phone,
            throwIfUserExist,
            throwIfUserDoesNotExist,
            cc: clientCode,
            shortCodeModel,
            shortCode,
            shortCriteria,
          }
    );
    return res.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyOTP = async ({
  attachToken,
  clientCode,
  shortCode,
  shortCodeModel,
  shortCriteria,
  sentOTPorderId,
  phone,
  otp,
  appModule,
}) => {
  try {
    appModule = appModule || commonConfig.appModule;

    const url = buildUrl(appModule, "verifyOTP");
    const res = await HTTP.post(url, {
      phone,
      otp,
      otpSession: sentOTPorderId,
      attachToken,
      cc: clientCode,
      shortCodeModel,
      shortCode,
      shortCriteria,
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const filterDivisionsWithUserAccess = (divisions, userParams = {}) => {
  const flag = (divisions || []).filter(
    (div) =>
      isAdmin ||
      isSuperAdmin ||
      userParams.role === enums.roles.admin ||
      userParams.role === "Super Admin" ||
      (userParams.division || userDetails.division || []).find(
        (userDiv) => userDiv?._id === div?._id
      )
  );
  return flag;
};
