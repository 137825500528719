import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background: linear-gradient(
    180deg,
    rgba(123, 166, 230, 0.4) 0%,
    rgba(123, 166, 229, 0.65) 43.23%,
    rgba(255, 88, 88, 0.1) 100%
  );
  border-radius: 10px;
  width: 200px;
  height: 8vh;
  border-width: 1px;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Advent Pro";
  cursor: pointer;
`;

export default Button;
