import { useEffect, useState } from "react";

// #lazy loading
const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(
    typeof IntersectionObserver !== "function"
  );

  useEffect(() => {
    if (ref?.current && !isIntersecting) {
      const observer = new IntersectionObserver(([entry]) => {
        return entry.isIntersecting && setIntersecting(true);
      });
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [isIntersecting]);

  return [isIntersecting, ref];
};

export default useOnScreen;
// https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
// Usage
// const DummyComponent = () => {

//   const ref = useRef()
//   const isVisible = useOnScreen(ref)

//   return <div ref={ref}>{isVisible && `Yep, I'm on screen`}</div>
// }
