import React from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { Grid } from "@material-ui/core/index";
import Label from "@ui/components/Label";
import RHDateTimePicker from "@ui/ReactHookFormControls/RHDateTimePicker";
import { formatDateToRead } from "@ui/Utils/helper";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import { addMonths } from "date-fns";

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch } = formMethods;
  const values = watch();

  const params = useParams();
  const navigate = useNavigate();

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      navigate("/" + enums.models["e-creatives"].linkItOrders);
    }
  };

  const links = [
    {
      name: "EXPIRE it",
      url: "#/" + enums.models["e-creatives"].linkItOrders,
      isDisplayLink: true,
    },
    // {
    //   name: `${values.orderId || ""}`,
    //   url: "",
    //   isDisplayLink: false,
    // },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"LINK it"} actions={actions} />

      <MainSection title={"Access Configuration"}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {isEditable ? (
              <RHAsyncAutoComplete
                name={"catalogues"}
                apiUrl={enums.models["e-creatives"].catalogues + "/search"}
                label="Catalogues"
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(opt) => opt.name || ""}
                multiple={true}
              />
            ) : (
              <Label
                label={"Catalogues"}
                value={(values.catalogues || [])
                  .map((cat) => cat.name)
                  .join(", ")}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {isEditable ? (
              <RHAsyncAutoComplete
                name={"validUsers"}
                apiUrl={enums.models["data-engine"].users + "/search"}
                label="Customers"
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(opt) =>
                  opt.firstName
                    ? `🧑🏻‍🏫=${opt.firstName} ${opt.lastName || ""}${
                        opt.companyDetails?.name
                          ? `, 🏰=${opt.companyDetails?.name}`
                          : ""
                      }${opt.phone ? ", ☏=" + opt.phone + ";" : ""}`
                    : ""
                }
                multiple={true}
              />
            ) : (
              <Label
                label={"Customers"}
                value={(values.validUsers || [])
                  .map(
                    (user) =>
                      user.firstName +
                      " " +
                      (user.lastName || "") +
                      " (" +
                      user.phone +
                      ")"
                  )
                  .join(", ")}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {isEditable ? (
              <RHDateTimePicker
                name={"expirationDate"}
                label={"Expiration Date"}
                maxDate={addMonths(new Date(), 6)}
              />
            ) : (
              <Label
                label={"Expiration Date"}
                value={
                  values.expirationDate
                    ? formatDateToRead(
                        values.expirationDate,
                        globalDateTimeFormat.read
                      )
                    : ""
                }
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <Label label={"Link"} value={values.url} />
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default DetailHeader;
