import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

//
/** Access theme objects with styled components. Combines the power of material and styled.<br/>
 * This function takes a component and returns a styled version of it using Material-UI theme.
 * @param component - The component parameter is a React component or html tags that will be styled using the
 * Material-UI theme.
 * @returns The function `styledWithMuiTheme` returns a higher-order function that takes in `styles`
 * and returns a styled component using the `styled` function from the `styled-components` library and
 * the `useTheme` hook from the `@material-ui/core` library. To access `theme` object which defined globally.
 * The styled component is created by passing the original `component` and a function that takes in `props` and returns the `styles
 */
export const styledWithMuiTheme = (component) => (styles) => {
  const StyledComponent = styled(component)((props) =>
    styles?.({ ...props, theme: useTheme() })
  );

  return StyledComponent;
};
