// import { yupResolver } from "@hookform/resolvers/yup";
// import Grid from "@mui/material/Grid";
// import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
// import { downloadFile, isAdmin } from "@ui/Utils/helper";
// import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
// import { createGlobalStyle } from "styled-components";

import {
  createRecord,
  updateRecord,
  getSingleRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {
  name: "",
};

const AppConfigDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });

  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();
  const model = enums.models.appConfig;

  useEffect(() => {
    (async () => {
      try {
        let res = {};

        res = await getSingleRecord(model);

        if (res?.code) {
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {};
      if (!values._id) {
        res = await createRecord(model, values);
      } else {
        res = await updateRecord(model, values);
      }
      formMethods.reset(res);
      setIsEditable(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default AppConfigDetail;
