import React from "react";
import { Grid } from "@material-ui/core/index";
import { ProductCard } from "./ProductsGrid";

const Cart = ({ cartItems = [], setCartItems }) => {
  return (
    <Grid container spacing={3}>
      {cartItems?.length
        ? cartItems.map((cart, cartIndex) => (
            <Grid item xs={12} key={cartIndex}>
              <ProductCard
                setCartItems={setCartItems}
                cartItems={cartItems}
                prod={cart.product}
              />
            </Grid>
          ))
        : "No items"}
    </Grid>
  );
};

export default Cart;
