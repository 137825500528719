import { Stack } from "@mui/material";
import { styledWithMuiTheme } from "../../Styles/styled-with-mui-theme";

export const StackStyled = styledWithMuiTheme(Stack)(({ theme }) => ({
  margin: "2rem auto 1rem 2rem",
  ".account_name": {
    color: "#0C0553",
    textAlign: "center",
    fontSize: "0.9375rem",
    fontFamily: "Gothic A1",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
}));
