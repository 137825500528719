import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { handleGlobalUserControl } from "@ui/Utils/helper";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {};

const UserDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const model = enums.models.iam.users;

  const params = useParams();

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.iam.users);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      if (!values._id) {
        res = await createRecord(model, values);
        navigate(`/${enums.models.iam.users}/${res?.code}`);
      } else {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
          },
          ["iam"]
        );

        action = "Updated";
        res = await updateRecord(model, values);
        navigate(`/${enums.models.iam.users}/${res?.code}`);
      }
      formMethods.reset(res);
      setIsEditable(false);

      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err?.response?.data?.message);
      let message = err?.response?.data?.message
        ?.toString()
        .match("phone_1 dup key")
        ? "User exists with Phone: " + values.phone
        : "";
      CustomEventEmitter.emit("alert_error", message || "Something went wrong");
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />
    </FormProvider>
  );
};

export default UserDetail;
