import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

const baseApiUrl = commonConfig.baseApi?.["e-creatives"];

export const paginateWithHook = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {}
) => {
  try {
    const url = baseApiUrl + model + "/paginate?hook=true";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPaginatedData = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {},
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/paginate";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.put(
      baseApiUrl + model + "/" + data?.code + "?hook=true&webHook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const searchWithHook = async (
  model,
  criteria = {},
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/search?hook=true";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getByCode = async (model, code) => {
  try {
    const url = baseApiUrl + model + "/" + code;

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(baseApiUrl + model + "/" + data?.code, data);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const search = async (model, criteria = {}, additionalHeaders) => {
  try {
    const url = baseApiUrl + model + "/search";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSingleRecord = async (model, criteria = {}) => {
  try {
    const url = baseApiUrl + model + "/getSingleRecord";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAvailableProductsQuantity = async ({
  styleNo,
  stockStatus = enums.stockStatus.inventory,
  additionalHeaders,
}) => {
  try {
    const res = await HTTP.post(
      commonConfig.baseApi?.["e-creatives"] +
        "c/" +
        enums.models["data-engine"].products +
        "/getAvailableProductsQuantity",
      { styleNo, stockStatus },
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
