import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core/index";
import { MyDialogContent, MyDialogTitle } from "@ui/MuiComponents/index";
import { Grid } from "@material-ui/core/index";
import Template1 from "./Templates/Template1";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { toPng } from "html-to-image";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { isVendor, oopsNotFound } from "@ui/Utils/helper";
import { Button } from "@material-ui/core/index";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BackspaceIcon from "@mui/icons-material/Backspace";

export const templateViewOption = {
  client: "Client",
  vendor: "Vendor",
};
const ProductDownload = ({
  openDownloadPopup,
  setOpenDownloadPopup,
  product,
  productIndex,
  quotation = {},
}) => {
  const [selectedView, setSelectedView] = useState("");
  const [imageLocation, setImageLocation] = useState(oopsNotFound);
  const elementRef = React.useRef(null);
  const [imageSrc, setImageSrc] = useState("");
  const defaultView = window.localStorage.getItem("selectedView");

  useEffect(() => {
    if (isVendor) {
      setSelectedView(
        defaultView ? JSON.parse(defaultView) : templateViewOption.vendor
      );
    } else {
      setSelectedView(defaultView ? JSON.parse(defaultView) : "");
    }
  }, [isVendor, defaultView, openDownloadPopup]);

  useEffect(() => {
    if (product?.images?.[0]?.signedUrl) {
      setImageLocation(product?.images?.[0]?.signedUrl);
    }

    return setSelectedView("");
  }, [product]);

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = product.id + ".png";
        link.href = dataUrl;
        link.click();
        CustomEventEmitter.emit("alert_success", "Downloaded Successfully");
        setOpenDownloadPopup({});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid>
      <Dialog
        open={openDownloadPopup?.[productIndex]}
        onClose={() => setOpenDownloadPopup({})}
        fullWidth
      >
        <MyDialogTitle
          onClose={() => setOpenDownloadPopup({})}
          actions={[
            {
              icon: <BackspaceIcon style={{ color: "grey" }} />,
              onClick: () => setSelectedView(""),
              hidden: !(selectedView && !isVendor),
              tooltip: "Go back",
            },
            {
              icon: <DownloadForOfflineIcon />,
              onClick: htmlToImageConvert,
              hidden: !selectedView,
              tooltip: "export Snapshot",
            },
          ]}
        >
          Product - {product.id}
        </MyDialogTitle>
        <MyDialogContent>
          {selectedView ? (
            <Template1
              product={product}
              imageLocation={imageLocation}
              setOpenDownloadPopup={setOpenDownloadPopup}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              elementRef={elementRef}
              quotation={quotation}
              selectedView={selectedView}
            />
          ) : (
            <Grid container spacing={2} style={{ marginBottom: "50px" }}>
              <Grid
                item
                xs={6}
                justify="center"
                container
                onClick={() => {
                  setSelectedView(templateViewOption.client);
                  window.localStorage.setItem(
                    "selectedView",
                    JSON.stringify(templateViewOption.client)
                  );
                }}
              >
                <Button style={{ backgroundColor: "#AFE1AF" }}>
                  <PersonIcon />
                  Client View
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                onClick={() => {
                  setSelectedView(templateViewOption.vendor);
                  window.localStorage.setItem(
                    "selectedView",
                    JSON.stringify(templateViewOption.vendor)
                  );
                }}
              >
                <Button style={{ backgroundColor: "#00CED1" }}>
                  <AssignmentIndIcon />
                  Vendor View
                </Button>
              </Grid>
            </Grid>
          )}
        </MyDialogContent>
      </Dialog>
    </Grid>
  );
};

export default ProductDownload;
