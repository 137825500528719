import React, { useEffect } from "react";
import enums from "helpers/enums";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { FormProvider } from "react-hook-form";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";
import { useForm } from "react-hook-form";
import { getSingleRecord, search } from "@ui/ComponentUtils/blueprintAPIs";
import { Button, Grid } from "@material-ui/core";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import { useState } from "react";
import { Fragment } from "react";
import { useFieldArray } from "react-hook-form";
import { alphabets } from "@ui/Utils/excelImportHelpers";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Header from "@ui/ComponentUtils/Header";
import Label from "@ui/components/Label";
import Template1 from "../assets/Template-1.png";
import Template2 from "../assets/Template-2.png";
import Template3 from "../assets/Template-3.png";
import "./style.css";
import { createRecordWithHook, updateRecordWithHook } from "../api";

const SettingsList = () => {
  const formMethods = useForm({
    defaultValues: {
      priorities: [{ label: alphabets[0] }],
      quotationView: {
        defaultView: true,
      },
    },
    mode: "all",
  });
  const { watch, setValue, control } = formMethods;
  const values = watch();
  const model = enums.models["vendor-portal"]?.settings;
  const [inProgressQuotations, setInProgressQuotations] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `priorities`,
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await getSingleRecord(model);

        if (res?.code) {
          formMethods.reset(res);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    getQuotations();
  }, []);

  const getQuotations = async () => {
    try {
      const res = await search(enums.models["vendor-portal"]?.quotations, {
        received: {
          $ne: true,
        },
        sort: { quotationNo: 1, vendorCode: 1 },
      });

      setInProgressQuotations(res || []);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (values) => {
    try {
      let res = {},
        action = "Created";

      if (!values._id) {
        res = await createRecordWithHook(model, values);
      } else {
        res = await updateRecordWithHook(model, values);

        action = "Updated";
      }

      const tmpPriorities = (values.priorities || []).map((prio) => ({
        label: prio.label,
        quotations: [],
      }));

      formMethods.reset({
        ...res,
        dePrioritize: [],
        priorities: tmpPriorities,
      });
      getQuotations();
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", action + " Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: () => {
        formMethods.reset({});
        setIsEditable(false);
      },
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  const links = [
    {
      name: "",
      url: "#/" + model,
      isDisplayLink: false,
    },
  ];

  const getTemplateImage = () => {
    switch (values.pdfTemplate) {
      case enums["vendor-portal"]?.pdfTemplates.template1:
        return Template1;
      case enums["vendor-portal"]?.pdfTemplates.template2:
        return Template2;
      case enums["vendor-portal"]?.pdfTemplates.template3:
        return Template3;
      default:
        return null;
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Header links={links} pageTitle={"Settings"} actions={actions} />

      <MainSection title={"Exhibition Orders"} style={{ marginBottom: "24px" }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <RHCheckbox
              name="quotationView.defaultView"
              label="Default"
              disabled={!isEditable}
              onChange={(event) =>
                setValue("quotationView.priorityView", !event.target.checked)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <RHCheckbox
              name="quotationView.priorityView"
              label="Priority"
              disabled={!isEditable}
              onChange={(event) =>
                setValue("quotationView.defaultView", !event.target.checked)
              }
            />
          </Grid>

          {values.quotationView?.priorityView ? (
            <Grid container item xs={12} spacing={3}>
              {isEditable && (
                <Grid item xs={12}>
                  <RHAutoComplete
                    name={`dePrioritize`}
                    multiple
                    label="De-Prioritize Quotations"
                    options={
                      inProgressQuotations?.filter((quot) => quot?.priority) ||
                      []
                    }
                    getOptionLabel={(option) =>
                      option?.quotationNo +
                      " --- " +
                      option?.vendorCode +
                      (option?.priority
                        ? " -- Priority: " + option?.priority
                        : "")
                    }
                  />
                </Grid>
              )}

              {isEditable && (
                <Grid item xs={12}>
                  <Button
                    style={{ marginBottom: "24px", marginTop: "24px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => append({ label: alphabets[fields.length] })}
                  >
                    Add Priority
                  </Button>
                </Grid>
              )}

              {fields?.map((priority, index) => (
                <Fragment key={priority.id}>
                  <Grid item xs={1}>
                    {index + 1}.
                  </Grid>
                  <Grid item xs={4}>
                    {isEditable ? (
                      <RHTextField
                        name={`priorities[${index}].label`}
                        label={"Priority Name"}
                      />
                    ) : (
                      <Label label={"Priority"} value={priority.label} />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {isEditable ? (
                      <RHAutoComplete
                        name={`priorities[${index}].quotations`}
                        label="Quotations"
                        multiple
                        options={inProgressQuotations?.filter(
                          (quot) =>
                            !values.priorities?.find((field) =>
                              field.quotations?.find(
                                (fq) => fq?._id === quot?._id
                              )
                            )
                        )}
                        getOptionLabel={(option) =>
                          option?.quotationNo +
                          " --- " +
                          option?.vendorCode +
                          (option?.priority
                            ? " -- Priority: " + option?.priority
                            : "")
                        }
                      />
                    ) : (
                      <Label
                        label={"Quotations"}
                        value={inProgressQuotations
                          ?.filter(
                            (quot) =>
                              !values.priorities?.find((field) =>
                                field.quotations?.find(
                                  (fq) => fq?._id === quot?._id
                                )
                              ) && quot.priority === priority.label
                          )
                          ?.map(
                            (quot) =>
                              `${quot?.quotationNo} (${quot?.vendorCode})`
                          )
                          .join(", ")}
                      />
                    )}
                  </Grid>
                  {isEditable && (
                    <Grid item xs={3}>
                      <DeleteIcon
                        onClick={() => {
                          remove(index);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          ) : null}
        </Grid>
      </MainSection>

      <MainSection title={"Quotation PDF"} style={{ marginBottom: "24px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {isEditable ? (
              <RHAutoComplete
                options={Object.values(enums["vendor-portal"]?.pdfTemplates)}
                name="pdfTemplate"
                label="Choose Template"
                disableClearable
              />
            ) : (
              <Label label={"Template"} value={values.pdfTemplate} />
            )}
          </Grid>

          <Grid item xs={6}>
            <div className="zoom">
              <img
                src={getTemplateImage()}
                alt={(values.pdfTemplate || "") + ".png"}
                style={{ height: 300, width: 300 }}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <RHCheckbox
              name="mapPurityToPercent"
              label="Auto map purity to percentage "
              disabled={!isEditable}
            />
            <p>{`18 => 76`}</p>
            <p>{`22 => 92`}</p>
          </Grid>
        </Grid>
      </MainSection>
    </FormProvider>
  );
};

export default SettingsList;
