import React from "react";
import comingSoon from "../../@ui/assets/backgrounds/comingSoon.png";
import "./homepage.css";

const HomePage = () => {
  return (
    <div className="container">
      <div className="left-half">
        <img className="half-image" src={comingSoon} alt="Coming Soon..." />
      </div>
      <div className="right-half">
        <img
          className="half-image rotate"
          src={comingSoon}
          alt="Coming Soon..."
        />
      </div>
    </div>
  );
};

export default HomePage;
