import React from "react";
import { globalDateTimeFormat } from ".";
import { Controller } from "react-hook-form";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const RHDateTimePicker = ({
  name,
  clearable,
  defaultValue,
  autoOk,
  format,
  rules,
  label,
  ...props
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            {...field}
            {...props}
            clearable={clearable}
            autoOk={autoOk}
            // placeholder="10/10/2018"
            // minDate={new Date()}
            size="small"
            style={props.style || { width: "100%" }}
            label={label}
            inputVariant="outlined"
            format={format || globalDateTimeFormat.edit}
            value={field.value ? field.value : null}
            onChange={(newValue) => {
              field.onChange(newValue);
              props.onChange && props.onChange(newValue);
            }}
            disablePast={props.disablePast}
            disableFuture={props.disableFuture}
            // maxDate={props.maxDate || new Date('2100-01-01')}
            // minDate={props.minDate || new Date('1900-01-01')}
            helperText={fieldState?.error && fieldState?.error?.message}
            error={Boolean(fieldState?.error)}
          />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};

export default RHDateTimePicker;
