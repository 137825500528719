import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, getProductImageUri } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Products",
    render: (row) => row?.products?.length,
  },
  {
    title: "Images",
    render: (row) =>
      row?.products?.map((product) => (
        <img
          src={getProductImageUri(product)}
          alt="img"
          style={{ height: 50, width: 50, paddingLeft: 4 }}
        />
      )),
  },

  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const GroupsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.groups}
      headerTitle="Specials"
      searchFields={["name"]}
      mainQuery={{
        populate: [
          {
            path: "products",
            db: "data-engine",
            collection: "products",
          },
        ],
      }}
    />
  );
};

export default GroupsList;
