import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core/index";
import { urlToBase64 } from "../../api";
import { formatDateToRead } from "@ui/Utils/helper";
import { makeStyles } from "@mui/styles";
import { templateViewOption } from "../ProductDownload";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: "1px solid #ddd", // Border style
    fontSize: 14,
  },
  gridValue: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: "1px solid #ddd", // Border style
    fontWeight: "bold",
    fontSize: 18,
  },
}));

const Template1 = ({
  product,
  setOpenDownloadPopup,
  imageLocation,
  imageSrc,
  setImageSrc,
  elementRef,
  quotation = {},
  selectedView,
}) => {
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const response = await urlToBase64(imageLocation);

        setImageSrc(`data:image/png;base64,${response?.base64Data}`);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    })();

    return () => {
      setImageSrc("");
    };
  }, []);

  if (!imageSrc) {
    return <h5>Loading...</h5>;
  }

  return (
    <div ref={elementRef}>
      <Grid container spacing={2} className={classes.gridItem}>
        <Grid item xs={3} component={Paper} className={classes.gridItem}>
          OD# & Date
        </Grid>
        <Grid item xs={5} component={Paper} className={classes.gridValue}>
          {product.id}
        </Grid>

        <Grid item xs={4} component={Paper} className={classes.gridValue}>
          {(quotation?.orderDate && formatDateToRead(quotation.orderDate)) ||
            ""}
        </Grid>

        <Grid item xs={12} component={Paper} className={classes.gridValue}>
          {selectedView === templateViewOption.client
            ? quotation?.customer?.name
            : quotation?.vendorCode}
        </Grid>

        <Grid item xs={3} component={Paper} className={classes.gridItem}>
          Purity
        </Grid>
        <Grid item xs={3} component={Paper} className={classes.gridValue}>
          {product.metalPurity}
        </Grid>
        <Grid item xs={3} component={Paper} className={classes.gridItem}>
          Cust. OD#
        </Grid>
        <Grid item xs={3} component={Paper} className={classes.gridValue}>
          {product.customerOrderId || ""}
        </Grid>

        <Grid container item xs={7}>
          {/* start */}
          <Grid item xs={6} component={Paper} className={classes.gridItem}>
            Item
          </Grid>
          <Grid item xs={6} component={Paper} className={classes.gridValue}>
            {product?.category || ""}
          </Grid>
          {/* end */}

          {/* start */}
          <Grid
            item
            xs={12}
            component={Paper}
            className={classes.gridValue}
            style={{ fontSize: 14 }}
          >
            {product.exhibitionRemarks}
          </Grid>
          {/* end */}

          {/* start */}
          <Grid item xs={2} component={Paper} className={classes.gridItem}>
            Size
          </Grid>

          <Grid item xs={4} component={Paper} className={classes.gridValue}>
            {product.size}
          </Grid>

          <Grid item xs={2} component={Paper} className={classes.gridItem}>
            Qty
          </Grid>
          <Grid item xs={4} component={Paper} className={classes.gridValue}>
            {product.quantity}
          </Grid>
          {/* end */}

          {/* start */}
          <Grid item xs={6} component={Paper} className={classes.gridItem}>
            Wt. Range
          </Grid>
          <Grid
            item
            xs={6}
            component={Paper}
            className={classes.gridValue}
            style={{ fontSize: 14 }}
          >
            {product?.adminRemarks || ""}
          </Grid>
          {/* end */}
        </Grid>

        <Grid
          item
          xs={5}
          style={{ gridRow: "span 4" }}
          component={Paper}
          className={classes.gridItem}
        >
          <div
            style={{
              width: "100%",
              height: "300px",
              maxWidth: "300px",
              overflow: "hidden",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto", // ###maintain aspect ratio
                objectFit: "contain",
              }}
              src={imageSrc}
              alt="image not found"
            />
            <div style={{ paddingTop: "calc(200/300 * 100%)" }}></div>
          </div>
        </Grid>

        {/* start */}
        <Grid item xs={6} component={Paper} className={classes.gridItem}>
          Apx Wt.
        </Grid>
        <Grid item xs={6} component={Paper} className={classes.gridValue}>
          {product?.grossWeight || ""}
        </Grid>
        {/* end */}

        {/* start */}
        <Grid item xs={6} component={Paper} className={classes.gridItem}>
          Del. Dt
        </Grid>
        <Grid item xs={6} component={Paper} className={classes.gridValue}>
          {(product?.dueDate && formatDateToRead(product?.dueDate)) || ""}
        </Grid>
        {/* end */}
      </Grid>
    </div>
  );
};

export default React.forwardRef(Template1);
