import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, Tooltip } from "@material-ui/core";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  formatDateToRead,
  formatWeight,
  isAdmin,
  isDivisionControlled,
  isSuperAdmin,
  isVendor,
  regexPurify,
  selectedDivisionId,
  uniqueArray,
  userDetails,
} from "@ui/Utils/helper";
import enums from "helpers/enums";
import {
  getSingleRecord,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { CustomCopyIcon } from "@ui/views/vendor-portal/Quotation/List/List";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import HoverIcons from "@ui/assets/commonIcons/customIcons/HoverIcons";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";
import { TextField } from "@material-ui/core/index";
import { defaultFilter } from "../Detail/Detail";
import { ProductItem } from "../Detail/components/DetailHeader";
import { updateRecordWithHook } from "../../api";
import { downloadPdf } from "../api";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import {
  globalDateTimeFormat,
  RHTextField,
} from "@ui/ReactHookFormControls/index";
import ReadMore from "@ui/Actions/ReadMore/ReadMore";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab/index";

const expandStockOrdersSessionStorage = "expandAllStockOrders";

const StockOrders = ({
  data = [],
  setData = () => {},
  constData = [],
  setConstData = [],
  columns,
  title,
  getData,
  setTotalCalc,
}) => {
  const [currentEditRow, setCurrentEditRow] = React.useState("none");
  const windowExpandAll = window.sessionStorage.getItem(
    expandStockOrdersSessionStorage
  )
    ? JSON.parse(window.sessionStorage.getItem(expandStockOrdersSessionStorage))
    : false;
  const [expandAll, setExpandAll] = React.useState(
    windowExpandAll ? true : false
  );
  const [vendorCodes, setVendorCodes] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    (async () => {
      try {
        setVendorCodes(
          (
            (await getSingleRecord(
              enums.models["vendor-portal"].vendorCodes
            )) || {}
          )?.availableCodes || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const checkMatch = (source) => {
    return regexPurify(source?.toString())
      .toLowerCase()
      .match(regexPurify(searchText?.toString()).toLowerCase());
  };

  React.useEffect(() => {
    let modifiedData = [];
    if (searchText?.trim()) {
      const mainData = [...constData];
      modifiedData = mainData
        .map((cd, cdIndex) => {
          if (
            checkMatch(cd.orderId) ||
            checkMatch(
              cd.createdBy?.firstName + " " + cd.createdBy?.lastName
            ) ||
            checkMatch(cd.createdBy?.role) ||
            checkMatch(cd.overallRemarks) ||
            checkMatch(cd.destinationZone) ||
            checkMatch(cd.sourceZone) ||
            checkMatch(cd?.asPer?.vendorCode) ||
            checkMatch(cd?.asPer?.collectionLine?.name) ||
            (cd.categories || []).find((catGroup) =>
              (catGroup?.subCategories || []).find(
                (subCatGroup) =>
                  (subCatGroup.vendors || []).find(
                    (vendor) =>
                      checkMatch(vendor.vendorCode) ||
                      checkMatch(vendor.orderStatus)
                  ) ||
                  (subCatGroup?.items || []).find(
                    (item) =>
                      checkMatch(item?.orderStatus) ||
                      // checkMatch(item?.remarks) || // add this when Client asks.. they will never ask ;P
                      checkMatch(item?.product?.vendorCode) ||
                      checkMatch(item?.product?.styleNo) ||
                      checkMatch(item?.product?.skuNumber) ||
                      checkMatch(item?.product?.category?.name) ||
                      checkMatch(item?.product?.subCategory?.name) ||
                      checkMatch(item?.product?.collectionLine?.name)
                  )
              )
            )
          ) {
            return cd;
          }

          return null;
        })
        .filter((o) => o);
    } else {
      modifiedData = constData;
    }

    setData(modifiedData);

    let totalGrossWeight = 0,
      totalNetWeight = 0;

    const vendorCodes = userDetails.vendorCode || [];

    modifiedData
      .filter((o) => o)
      .map((order) => {
        (order.categories || []).map((catGroup) => {
          (catGroup?.subCategories || []).map((subCatGroup) => {
            (subCatGroup?.items || []).map((item) => {
              if (
                !isDivisionControlled ||
                selectedDivisionId === item?.product?.division?._id?.toString()
              ) {
                if (
                  isVendor &&
                  vendorCodes.includes(item?.product?.vendorCode) &&
                  item?.orderStatus !==
                    enums["vendor-portal"].orderStatus.pending
                ) {
                  totalGrossWeight += item?.product?.grossWeight || 0;
                  totalNetWeight += item?.product?.netWeight || 0;
                } else if (!isVendor) {
                  totalGrossWeight += item?.product?.grossWeight || 0;
                  totalNetWeight += item?.product?.netWeight || 0;
                }
              }
            });
          });
        });
      });

    setTotalCalc({
      totalGrossWeight: Number(totalGrossWeight.toFixed(2)),
      totalNetWeight: Number(totalNetWeight.toFixed(2)),
    });
  }, [searchText, constData]);

  return (
    <div>
      <Grid container spacing={1} style={{ marginBottom: "1px" }}>
        <Grid
          item
          xs={4}
          container
          // justify="center"
          style={{ fontWeight: "bold", paddingTop: "16px" }}
        >
          {title}
        </Grid>
        <Grid item xs={8}>
          {currentEditRow === "none" ? (
            <TextField
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              label={"Search"}
              style={{ width: "100%" }}
              variant="outlined"
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <ScrollTopButton />

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow
              style={{ cursor: "grab" }}
              onMouseDown={(event) => {
                event.target.style.cursor = "grabbing";
              }}
              onMouseUp={(event) => {
                event.target.style.cursor = "grab";
              }}
              onClick={() => {
                if (isNaN(currentEditRow)) {
                  setExpandAll(!expandAll);
                  window.sessionStorage.setItem(
                    expandStockOrdersSessionStorage,
                    !expandAll
                  );
                }
              }}
            >
              <TableCell
                style={{
                  cursor: isNaN(currentEditRow) && "pointer",
                  backgroundColor: "#088082",
                }}
              >
                {!isNaN(currentEditRow) ? null : !expandAll ? (
                  <Tooltip title="Expand All">
                    <ExpandCircleDownIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Collapse All">
                    <ExpandLessIcon
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "50%",
                      }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              {columns.map(
                (col, colIndex) =>
                  !col.hidden && (
                    <TableCell
                      style={{
                        whiteSpace: "pre-line",
                        backgroundColor: "#088082",
                        color: "white",
                        ...col.style,
                      }}
                      key={colIndex}
                    >
                      {col.title}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {(data || []).map((row, rowIndex) =>
              row &&
              (!isNaN(currentEditRow) ? currentEditRow === rowIndex : true) ? (
                <Row
                  key={rowIndex}
                  row={row}
                  rowIndex={rowIndex}
                  columns={columns}
                  data={data}
                  getData={getData}
                  expandAll={expandAll}
                  vendorCodes={vendorCodes}
                  setCurrentEditRow={setCurrentEditRow}
                  setData={setData}
                  setConstData={setConstData}
                />
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

function Row({
  row,
  rowIndex,
  columns,
  getData,
  expandAll,
  vendorCodes,
  setCurrentEditRow,
  data,
  setData,
  setConstData,
}) {
  const [open, setOpen] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(false);
  const formMethods = useForm({
    defaultValues: {
      ...row,
      categories:
        row.categories?.map((category) => ({
          ...category,
          subCategories:
            category.subCategories?.map((subCat) => ({
              ...subCat,
              vendors: subCat.vendors || [],
            })) || [],
        })) || [],
    },
    mode: "all",
  });
  // const { setValue, getValues } = formMethods;
  const navigate = useNavigate();
  // const [openPreview, setOpenPreview] = React.useState({});
  // const [openDownloadPopup, setOpenDownloadPopup] = React.useState({});
  const model = enums.models["e-creatives"].stockOrders;
  const [vendorsIncluded, setVendorsIncluded] = React.useState([]);

  React.useEffect(() => {
    formMethods.reset(row);
  }, [row]);

  React.useEffect(() => {
    const tempVendorsIncluded = [];
    // Extract vendor code from asPer
    if (row.asPer && row.asPer.vendorCode) {
      tempVendorsIncluded.push(row.asPer.vendorCode);
    }

    (row.categories || []).forEach((order) => {
      // Extract vendor codes from subCategories.vendors
      order.subCategories.forEach((subCategory) => {
        subCategory.vendors.forEach((vendor) => {
          if (vendor.vendorCode) {
            tempVendorsIncluded.push(vendor.vendorCode);
          }
        });
      });

      // Extract vendor codes from subCategories.items.product
      order.subCategories.forEach((subCategory) => {
        subCategory.items.forEach((item) => {
          if (item.product && item.product.vendorCode) {
            tempVendorsIncluded.push(item.product.vendorCode);
          }
        });
      });
    });

    setVendorsIncluded(uniqueArray(tempVendorsIncluded));
  }, [row?.categories]);

  React.useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  const navigateToDetails = () => {
    if (!isEditable)
      navigate(`/${enums.models["e-creatives"].stockOrders}/${row.code}`);
  };

  const onSubmit = async (values) => {
    try {
      let res = {};
      if (values.code) {
        res = await updateRecordWithHook(model, values);
      } else {
        CustomEventEmitter.emit("alert_error", "Contact Abhi");
      }
      const tempData = _.cloneDeep(data).map((order) => {
        if (order._id === res._id) {
          return res;
        }

        return order;
      });

      CustomEventEmitter.emit("alert_success", "Updated Successfully");
      formMethods.reset(res);
      setData(tempData);
      setConstData(tempData);
      handleFinally();
    } catch (err) {
      console.log(err);
    }
  };

  const handleReceived = async (row, receivedBoolean) => {
    let isInReview = false,
      modifiedStockOrder;

    if (receivedBoolean) {
      modifiedStockOrder = (row?.categories || []).map((catGroup) => ({
        ...catGroup,
        subCategories: (catGroup.subCategories || []).map((subCatGroup) => ({
          ...subCatGroup,
          items: (subCatGroup.items || []).map((item) => {
            if (
              item.orderStatus === enums["vendor-portal"].orderStatus.pending
            ) {
              isInReview = true;
            }

            const dontUpdateFlag =
              item.orderStatus === enums["vendor-portal"].orderStatus.canceled;

            return {
              ...item,
              dateDelivered: dontUpdateFlag ? item.dateDelivered : new Date(),
              orderStatus: dontUpdateFlag
                ? item.orderStatus
                : enums["vendor-portal"].orderStatus.delivered,
            };
          }),

          vendors: (subCatGroup.vendors || []).map((vendor) => {
            if (
              vendor.orderStatus === enums["vendor-portal"].orderStatus.pending
            ) {
              isInReview = true;
            }
            const dontUpdateFlag =
              vendor.orderStatus ===
              enums["vendor-portal"].orderStatus.canceled;

            return {
              ...vendor,
              dateDelivered: dontUpdateFlag ? vendor.dateDelivered : new Date(),
              orderStatus: dontUpdateFlag
                ? vendor.orderStatus
                : enums["vendor-portal"].orderStatus.delivered,
            };
          }),
        })),
      }));
    }

    try {
      let message = "";
      if (isInReview) {
        message = "Some Orders are still In Review. "; // empty space at last is required // #oye #2ndComment 1.27am, 27th March, 2024
      }
      if (
        window.confirm(
          message +
            `Are you sure to mark ${row?.orderId} as ${
              receivedBoolean ? "Received" : "NOT Received"
            }?`
        )
      ) {
        row.received = receivedBoolean;
        row.receivedBy = {
          _id: userDetails._id,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          role: userDetails.role,
          phone: userDetails.phone,
        };
        row.dateReceived = new Date();

        if (modifiedStockOrder) row.categories = modifiedStockOrder;

        await updateRecord(model, row);

        getData();

        CustomEventEmitter.emit("alert_success", "Updated Successfully");
      }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit("alert_error", "Something went wrong!");
    }
  };

  const handleFinally = () => {
    // After Cancel or Save on Edit
    setIsEditable(false);
    setCurrentEditRow("none");
  };

  let expectedCount = 0,
    expectedGross = 0,
    selectedCount = 0,
    selectedGross = 0,
    vendorWiseCount = 0,
    vendorWiseGross = 0;

  (row.categories || []).map((categoryGroupItem) => {
    (categoryGroupItem.subCategories || []).map((subCatGroup) => {
      const subCatQuantity = subCatGroup?.quantity || 0;

      expectedCount += subCatQuantity;
      expectedGross += subCatQuantity * subCatGroup.averageGrossWeight;

      let subCatItemsQuantity = 0;
      (subCatGroup?.items || []).map((item) => {
        selectedGross += (item.quantity || 0) * item.product.grossWeight;
        selectedCount += item?.quantity || 0;
        subCatItemsQuantity += item?.quantity || 0;
      });

      // vendorWiseCount +=
      //   subCatQuantity > subCatItemsQuantity
      //     ? subCatQuantity - subCatItemsQuantity
      //     : 0;

      // this happens only for pdf to show the actual order report
      (subCatGroup.vendors || []).map((item) => {
        vendorWiseCount += item.quantity || 0;
        vendorWiseGross += item.quantity * subCatGroup.averageGrossWeight;
      });
    });
  });
  const checkCurrentVendor = (item, orderStatus) => {
    return (userDetails.vendorCode || []).find(
      (vCode) =>
        vCode === item.vendorCode &&
        orderStatus !== enums["vendor-portal"].orderStatus.pending
    );
  };

  return (
    <FormProvider {...formMethods}>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{
          cursor: "grab",
          backgroundColor: rowIndex % 2 === 0 ? "white" : "#ffebb3",
        }}
        onClick={(event) => {
          // if (!event.target.closest("TableCell:first-child")) { // not working.. #cgpt #kuch bee
          setOpen(!open);
          // }
        }}
        onMouseDown={(event) => {
          event.target.style.cursor = "grabbing";
        }}
        onMouseUp={(event) => {
          event.target.style.cursor = "grab";
        }}
      >
        <TableCell onClick={(e) => e.stopPropagation()}>
          <div>
            {isEditable ? (
              <>
                <HoverIcons
                  tooltip="Cancel"
                  muiIcon={<CancelOutlinedIcon />}
                  muiIconHover={<CancelIcon />}
                  onClick={() => {
                    if (window.confirm("Are you sure to Discard / Cancel?")) {
                      handleFinally();
                    }
                  }}
                />

                <HoverIcons
                  tooltip="Save"
                  muiIcon={<SaveOutlinedIcon />}
                  muiIconHover={<SaveIcon />}
                  onClick={() => {
                    formMethods.handleSubmit(onSubmit)();
                  }}
                />
              </>
            ) : (
              <>
                <HoverIcons
                  tooltip="View"
                  onClick={() => {
                    navigateToDetails();
                  }}
                  muiIcon={<RemoveRedEyeOutlinedIcon />}
                  muiIconHover={<RemoveRedEyeIcon />}
                />

                <HoverIcons
                  tooltip="Edit"
                  onClick={() => {
                    setCurrentEditRow(rowIndex);
                    setIsEditable(true);
                    setOpen(true);
                  }}
                  muiIcon={<ModeEditOutlineOutlinedIcon />}
                  muiIconHover={<ModeEditIcon />}
                />

                <HoverIcons
                  tooltip="PDF download"
                  onClick={() => {
                    downloadPdf(row);
                  }}
                  muiIcon={<PictureAsPdfOutlinedIcon />}
                  muiIconHover={<PictureAsPdfIcon />}
                />
              </>
            )}
          </div>

          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              setOpen(!open);
            }}
          >
            {isEditable ? null : open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        {isVendor ? null : (
          <TableCell scope="row">
            {!isEditable && (
              <Tooltip
                title={
                  row.received ? "Mark as NOT Received" : "Mark as Received"
                }
              >
                <IconButton
                  color="inherit"
                  onClick={() => handleReceived(row, !row.received)}
                  edge="start"
                  disabled={!(isAdmin || isSuperAdmin)}
                >
                  {row.received ? (
                    <CheckBoxOutlinedIcon style={{ color: "green" }} />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )}

        <TableCell>
          {row.orderId}
          <CustomCopyIcon text={row.orderId} />
        </TableCell>
        {!isVendor && <TableCell>{row.destinationZone}</TableCell>}

        {!isVendor && (
          <TableCell>
            <div>
              <div>{`${expectedCount} qty, ${formatWeight(expectedGross, {
                limit: 1000,
              })};`}</div>

              <div>{`${selectedCount} qty, ${formatWeight(selectedGross, {
                limit: 1000,
              })};`}</div>
              <div>{`${vendorWiseCount} qty, ${formatWeight(vendorWiseGross, {
                limit: 1000,
              })};`}</div>
            </div>
          </TableCell>
        )}

        {!isVendor && (
          <TableCell>
            <b>{vendorsIncluded.join(", ")}</b>
            <CustomCopyIcon text={vendorsIncluded.join(", ")} />
          </TableCell>
        )}

        <TableCell>
          <ReadMore content={row.overallRemarks} />
          <CustomCopyIcon text={row.overallRemarks} />
        </TableCell>

        <TableCell>
          {isEditable ? (
            <RHTextField name="adminRemarks" multiline rows={3} />
          ) : (
            <ReadMore content={row.adminRemarks} />
          )}

          <CustomCopyIcon text={row.overallRemarks} />
        </TableCell>

        {!isVendor && (
          <TableCell>
            <div>
              {row?.createdBy?.firstName + " " + row?.createdBy?.lastName}
            </div>
            <div style={{ fontStyle: "italic" }}>
              {row?.dateCreated
                ? formatDateToRead(row?.dateCreated, globalDateTimeFormat.read)
                : ""}
            </div>
            <div style={{ fontStyle: "italic" }}>{row?.createdBy?.role}</div>
          </TableCell>
        )}

        <TableCell>
          {row.dateExpected
            ? formatDateToRead(row.dateExpected, globalDateTimeFormat.read)
            : null}
        </TableCell>

        <TableCell>
          {(() => {
            const orderStatusArray = (row.categories || [])
              .map((order) =>
                (order?.subCategories || [])
                  .map((subCat) => [
                    ...(subCat?.items || []).map((item) =>
                      (
                        isVendor
                          ? checkCurrentVendor(
                              item.product || {},
                              item.orderStatus
                            )
                          : true
                      )
                        ? item?.orderStatus
                        : null
                    ),
                    ...(subCat?.vendors || []).map(
                      (vendor) =>
                        (isVendor
                          ? checkCurrentVendor(vendor, vendor.orderStatus)
                          : true) && vendor?.orderStatus
                    ),
                  ])
                  .flat()
                  .filter((f) => f)
              )
              .flat();

            const uniqueOrderStatus = uniqueArray(orderStatusArray || []);

            return uniqueOrderStatus.join(", ");
          })()}
        </TableCell>

        {isVendor ? null : (
          <TableCell>
            {row?.receivedBy ? (
              <div style={{ color: row.received ? "green" : "red" }}>
                <div>
                  {row?.dateReceived
                    ? formatDateToRead(
                        row?.dateReceived,
                        globalDateTimeFormat.read
                      )
                    : null}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {row?.receivedBy?.firstName + " " + row?.receivedBy?.lastName}
                </div>

                <div style={{ fontSize: "12px", fontStyle: "italic" }}>
                  {row?.receivedBy?.role}
                </div>
              </div>
            ) : null}
          </TableCell>
        )}
      </TableRow>

      {/* ************************************************************************************************************ */}
      {/* QUOTATION PRODUCTS */}
      <TableRow style={{ backgroundColor: "#ebebeb" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isEditable ? (
              <div
                style={{ width: "50%", marginLeft: "auto", marginTop: "4px" }}
              >
                <Autocomplete
                  label={"Bulk Order Status"}
                  options={Object.values(enums["vendor-portal"].orderStatus)}
                  disableClearable={true}
                  onChange={(_, val) => {
                    const currentValues = formMethods.getValues();

                    (currentValues.categories || []).map(
                      (categoryGroupItem, catIndex) => {
                        (categoryGroupItem.subCategories || []).map(
                          (subCatGroup, subCatIndex) => {
                            currentValues.categories[catIndex].subCategories[
                              subCatIndex
                            ].items = (subCatGroup.items || []).map((item) => ({
                              ...item,
                              orderStatus: val,
                              dateDelivered: Date.now(),
                            }));

                            currentValues.categories[catIndex].subCategories[
                              subCatIndex
                            ].vendors = (subCatGroup.vendors || []).map(
                              (item) => ({
                                ...item,
                                orderStatus: val,
                                dateDelivered: Date.now(),
                              })
                            );
                          }
                        );
                      }
                    );

                    formMethods.reset(currentValues);
                  }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      placeholder={"Bulk Order Status"}
                      size="small"
                      style={{ width: "100%" }}
                      variant={"outlined"}
                      // label={label + (required ? " *" : "")}
                    />
                  )}
                />
              </div>
            ) : null}

            {/* <div>
              Total Gross Weight{" "}
              {row.orderId === "SORRA-0089" && console.log("abhi: ", row)}{" "}
            </div> */}

            <Box sx={{ margin: 1 }}>
              {(row.categories || []).map((item, itemIndex) => {
                const subCatWithProduct = item?.subCategories?.find(
                  (subCat) =>
                    subCat.items?.find((subItem) => defaultFilter(subItem)) ||
                    defaultFilter(null, subCat)
                );

                return subCatWithProduct ? (
                  <ProductItem
                    key={itemIndex}
                    item={item}
                    itemIndex={itemIndex}
                    isEditable={isEditable}
                    customerId={row?.createdBy?._id}
                    vendorCodes={vendorCodes}
                  />
                ) : null;
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </FormProvider>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

export default StockOrders;

const styles = {
  productsColumns: {
    backgroundColor: "#50C078",
    color: "white",
  },
  productsBody: {
    backgroundColor: "#AFE1AF",
  },
};
