import React, { useEffect, useState } from "react";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import HistoryEduTwoToneIcon from "@mui/icons-material/HistoryEduTwoTone";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import LocalConvenienceStoreOutlinedIcon from "@mui/icons-material/LocalConvenienceStoreOutlined";
import enums from "helpers/enums";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import { customOrderColor, onlineOrderColor } from "./List";
import { defaultGoldHex } from "@ui/Utils/helper";

const featherFilterSessionName = "op.customOrders.featherFilter";
export const goldColor = "#916d00";
export const blueColor = "#6e94f5";

const FilterComponent = ({
  selectedFilter,
  setSelectedFilter,
  tableRef,
  listPageTableQuery,
}) => {
  const isOnline = selectedFilter?.orderType === enums.customOrderType.online;
  const isCustom = selectedFilter?.orderType === enums.customOrderType.custom;

  useEffect(() => {
    let filter = window.sessionStorage.getItem(featherFilterSessionName);
    filter = filter ? JSON.parse(filter) : null;

    if (filter?.orderType) {
      setSelectedFilter(filter);
    }
  }, []);

  const handleFilterClick = (filter) => {
    if (selectedFilter?.orderType === filter) {
      filter = null; // Deselect if already selected
    } else {
      filter = { orderType: filter };
    }

    setSelectedFilter(filter); // Select the clicked filter
    window.sessionStorage.setItem(
      featherFilterSessionName,
      filter && JSON.stringify(filter)
    );
    tableRef.current.onQueryChange({ ...listPageTableQuery, page: 0 });
  };

  const handleClearFilter = () => {
    setSelectedFilter();
    window.sessionStorage.removeItem(featherFilterSessionName);
    tableRef.current.onQueryChange(listPageTableQuery);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center", // Center align items vertically
        gap: "8px",
        cursor: "pointer",
      }}
    >
      <div
        onClick={() => handleFilterClick(enums.customOrderType.custom)}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: customOrderColor,
            border: "1px solid #999",
            borderRadius: "4px",
          }}
        ></div>
        <span
          style={{
            marginLeft: "12px",
            color: isCustom ? defaultGoldHex : "black",
            textDecoration: isCustom ? "underline" : "",
            fontWeight: isCustom ? "bold" : "",
            letterSpacing: isCustom ? "0.2em" : "",
          }}
        >
          Custom
        </span>
      </div>

      {selectedFilter && (
        <Grid item>
          <Tooltip title="Mix">
            <IconButton size="small" onClick={handleClearFilter}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      <div
        onClick={() => handleFilterClick(enums.customOrderType.online)}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: onlineOrderColor,
            border: "1px solid #999",
            borderRadius: "4px",
          }}
        ></div>
        <span
          style={{
            marginLeft: "12px",
            color: isOnline ? "blue" : "black",
            textDecoration: isOnline ? "underline" : "",
            fontWeight: isOnline ? "bold" : "",
            letterSpacing: isOnline ? "0.2em" : "",
          }}
        >
          Online
        </span>
      </div>
    </div>
  );
};

export default FilterComponent;
