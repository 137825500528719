import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import CustomDrawer from "@ui/MuiComponents/Drawer/CustomDrawer";
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import ModernClassic from "../assets/Modern_Classic.png";
import EarthyGreen from "../assets/Earthy_Green.png";
import FreshTeal from "../assets/Fresh_Teal.png";
import WarmAmber from "../assets/Warm_Amber.png";
import DustyRose from "../assets/Dusty_Rose.png";
import { Grid } from "@material-ui/core";
import { imageExpired, selectedDivisionName } from "@ui/Utils/helper";

const previewObj = {
  "Modern Classic": ModernClassic,
  "Earthy Green": EarthyGreen,
  "Fresh Teal": FreshTeal,
  "Warm Amber": WarmAmber,
  "Dusty Rose": DustyRose,
};

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.overviews;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate(`/${model}`);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "designPattern",
      label: "Design Pattern",
      fieldType: enums.fieldTypes.dropdown,
      options: enums.designPatterns,
      disableClearable: true,
      xs: 3,
    },
    {
      name: "support.email",
      label: "Email Support",
      fieldType: "textField",
      xs: 3,
    },
    {
      name: "support.whatsApp",
      label: "WhatsApp Support",
      fieldType: "textField",
      type: "number",
      xs: 3,
    },

    {
      name: "slidingImages",
      label: "Sliding Images",
      fieldType: "upload",
      accept: "image/*",
      model: model,
      xs: 3,
    },
  ];

  const links = [
    {
      name: selectedDivisionName,
      url: "#/overview",
      isDisplayLink: true,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Overview"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Configuration"
        isEditable={isEditable}
        values={values}
      >
        <Grid container item xs={4}>
          <img
            src={values.designPattern && previewObj[values.designPattern]}
            style={{ height: 440, width: 280 }}
            alt="img"
            onError={(event) => {
              event.target.src = imageExpired;
            }}
          />
        </Grid>
      </GeneralSection>
    </>
  );
};

export default DetailHeader;
