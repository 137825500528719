import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";
import axios from "axios";

const baseApiUrl = commonConfig.thirdParty.universalTutorial.baseApiUrl;

export const getUniversalAccessToken = async () => {
  try {
    const url = baseApiUrl + "getaccesstoken";

    const res = await HTTP.get(
      url,
      commonConfig.thirdParty.universalTutorial.headers,
      { replaceHeaders: true }
    );

    return res.data.auth_token;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCountries = async (token) => {
  try {
    const url = baseApiUrl + "countries";

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getStates = async (token, country) => {
  try {
    const url = baseApiUrl + "states/" + country;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCities = async (token, state) => {
  try {
    const url = baseApiUrl + "cities/" + state;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
