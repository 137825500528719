import React, { useRef } from "react";
import useOnScreen from "@ui/Hooks/useOnScreen";

const LazyLoad = ({ children, fallback = null, ...props }) => {
  const ref = useRef(); // Create a ref for the element
  const [isIntersecting] = useOnScreen(ref); // Use the custom useOnScreen hook

  return (
    <div ref={ref} {...props}>
      {isIntersecting ? children : fallback}
    </div>
  );
};

export default LazyLoad;
