import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent from "./components/DetailContent";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {
  name: "",
  products: [],
};

const AlertDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const model = enums.models.alerts;

  const params = useParams();

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(
            model,
            id,
            `populate=${JSON.stringify([
              {
                path: "navigation.product",
                select: "styleNo _id",

                collection: enums.models.products,
              },
            ])}`
          );
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      if (!values._id) {
        const res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        const res = await updateRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      }
      window.location.reload(); // reloading till its implemented with updateWithHook and add populate there for product..
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default AlertDetail;
