import MainSection from "@ui/MuiComponents/Section/MainSection";
import React from "react";
import { Grid } from "@material-ui/core";
import { getRHFieldComponent } from "@ui/Utils/helper";

const GeneralSection = (props) => {
  const {
    title,
    fields,
    isEditable,
    values,
    actions,
    disableFields,
    titleStyle,
    children,
    style,
  } = props;

  return (
    <MainSection
      title={title || ""}
      titleStyle={titleStyle}
      style={{ marginBottom: "24px", height: "100%", ...style }}
      actions={actions || []}
    >
      <Grid container spacing={3}>
        {fields.map((field, index) =>
          field.hidden ? null : (
            <Grid item xs={field.xs || props.xs || 4} key={index}>
              {getRHFieldComponent(field, {
                isEditable,
                values,
                disableFields,
              })}
            </Grid>
          )
        )}

        {children}
      </Grid>
    </MainSection>
  );
};

export default GeneralSection;
