import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

const baseApiUrl = commonConfig.baseApi?.["vendor-portal"];

export const getUnAssignedCodes = async () => {
  try {
    const url =
      baseApiUrl +
      "c/" +
      enums.models["vendor-portal"]?.vendorCodes +
      "/getUnAssignedCodes";

    const res = await HTTP.post(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const appModule = "vendor-portal";
