import React from "react";
import { globalDateFormat } from ".";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const RHDatePicker = ({
  name,
  clearable = true,
  autoOk = true,
  format = globalDateFormat.edit,
  defaultValue,
  rules,
  label,
  ...props
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        //console.log(field);
        //console.log(fieldState);
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              {...props}
              {...field}
              label={label}
              clearable={clearable}
              autoOk={autoOk}
              size="small"
              style={props.style || { width: "100%" }}
              inputVariant="outlined"
              format={format || globalDateFormat.edit}
              value={field.value ? field.value : null}
              onChange={(newValue) => {
                field.onChange(newValue);
                props.onChange && props.onChange(newValue);
              }}
              disablePast={props.disablePast}
              disableFuture={props.disableFuture}
              maxDate={props.maxDate || new Date("2100-01-01")}
              minDate={props.minDate || new Date("1900-01-01")}
              helperText={fieldState?.error && fieldState?.error?.message}
              error={Boolean(fieldState?.error)}
            />
          </MuiPickersUtilsProvider>
        );
      }}
    />
  );
};

export default RHDatePicker;
