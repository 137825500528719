import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { useFormContext, useFieldArray } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  formatDateToRead,
  getProductImageUri,
  oopsNotFound,
} from "@ui/Utils/helper";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { pageSizeOptions } from "./IndividualWorkflow";

const DeleteCatalogueProducts = ({
  isEditable,
  setShowDeleteProducts,
  setOpenDetailPopup,
  hideDiamondCarats = true,
  setHideDiamondCarats,
}) => {
  const { control, setValue } = useFormContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const { fields } = useFieldArray({
    control,
    name: "individualProducts",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      setSelectedRows([]);
    };
  }, []);

  useEffect(() => {
    if (fields.find((rec) => parseFloat(rec.totalDiamondWeight))) {
      setHideDiamondCarats(false);
    }
  }, [fields]);

  const columns = [
    {
      title: "Design",
      field: "styleNo",
      render: (row) => (
        <div onClick={() => setOpenDetailPopup(row)}>{row.styleNo}</div>
      ),
    },
    {
      title: "SKU",
      field: "sku",
      render: (row) => (
        <div onClick={() => setOpenDetailPopup(row)}>{row.sku}</div>
      ),
    },
    {
      title: "Category",
      field: "category.name",
      render: (row) => (
        <div onClick={() => setOpenDetailPopup(row)}>
          {row?.category?.name || ""}
        </div>
      ),
    },
    {
      title: "Gross, Net",
      field: "grossWeight",
      render: (row) => (
        <div onClick={() => setOpenDetailPopup(row)}>
          {`${(row.grossWeight || 0).toFixed(3)}, ${(
            row.netWeight || 0
          ).toFixed(3)}`}
        </div>
      ),
    },
    {
      title: "Diamond Carats",
      field: "totalDiamondWeight",
      hidden: hideDiamondCarats,
    },
    {
      title: "Since",
      field: "dateCreated",
      render: (row) => (
        <div onClick={() => setOpenDetailPopup(row)}>
          <div>{formatDateToRead(row.dateCreated)}</div>
          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {(row.createdBy?.firstName || "") +
              " " +
              (row.createdBy?.lastName || "")}
          </div>
        </div>
      ),
    },
    {
      title: "Image",
      render: (row) => (
        <div onClick={() => setOpenDetailPopup(row)}>
          <img
            src={getProductImageUri(row)}
            alt="img"
            style={{ height: 100, width: 100, objectFit: "contain" }}
            onError={(event) => {
              event.target.src = oopsNotFound;
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {isEditable ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
        >
          {selectedRows.length ? null : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowDeleteProducts(false)}
            >
              Add Products
            </Button>
          )}

          <Button
            variant="contained"
            color="secondary"
            style={{
              backgroundColor: selectedRows.length === 0 ? "grey" : "#f50057", //enums.colorTheme.primary
            }}
            disabled={selectedRows.length === 0}
            onClick={() => {
              let removedCount = 0;
              const remainingProducts = fields.filter((field) => {
                if (!selectedRows.find((row) => row._id === field._id)) {
                  return true;
                }
                removedCount++;
                return false;
              });
              setValue("individualProducts", remainingProducts);
              Emitter.emit("alert_success", `Removed: ${removedCount}`);
              setSelectedRows([]);
            }}
          >
            Remove Products
          </Button>
        </div>
      ) : null}

      <MaterialTable
        columns={columns}
        data={fields}
        title={`Catalogue Products - ${fields.length}`}
        options={{
          selection: isEditable,
          actionsColumnIndex: -1,
          paging: true,
          pageSizeOptions,
          pageSize: 25,
        }}
        onSelectionChange={(rows) => {
          setSelectedRows(rows);
        }}
      />
    </>
  );
};

export default DeleteCatalogueProducts;
