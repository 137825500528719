import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";

const columns = [
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Body",
    field: "body",
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const AlertsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.alerts}
      headerTitle="Alerts"
      searchFields={["name"]}
    />
  );
};

export default AlertsList;
