import { createTheme } from "@material-ui/core/styles";

import { palette, colorPalette } from "./palette";
import typography from "./typographyTheme";
import overrides from "./overrides";

const theme = createTheme({
  palette,
  typography,
  overrides,
  spacing: 8,
  letterSpacing: {
    H4: "0.25",
    H5: "0.25",
    H6: "0.15",
    SUBTITLE1: "0.5",
    SUBTITLE2: "0.5",
    BODY1: "0.5",
    BODY2: "0.5",
    CAPTION: "0.5",
    BUTTON: "0.75",
    OVERLINE: "1.5",
    SUBHEADING: "0.75",
  },
  typographyStyles: {
    H1: "h1",
    H2: "h2",
    H3: "h3",
    H4: "h4",
    H5: "h5",
    H6: "h6",
    SUBTITLE1: "subtitle1",
    SUBTITLE2: "subtitle2",
    BODY1: "body1",
    BODY2: "body2",
    CAPTION: "caption",
    BUTTON: "button",
    OVERLINE: "overline",
    SRONLY: "srOnly",
    INHERIT: "inherit",
    DISPLAY4: "display4",
    DISPLAY3: "display3",
    DISPLAY2: "display2",
    DISPLAY1: "display1",
    HEADLINE: "headline",
    TITLE: "title",
    SUBHEADING: "subheading",
  },
  selectVariant: "outlined",
});

export const drawerWidth = 270;

export { colorPalette, theme };
