import React, { useEffect, useRef, useState } from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead } from "@ui/Utils/helper";
import DomainIcon from "../assets/domain.png";
import LINKit from "./LINKit";
import { useNavigate } from "react-router-dom";
import { TextField, IconButton } from "@mui/material";
import { Autocomplete } from "@material-ui/lab/index";
import { Edit, Save, Cancel, Add } from "@mui/icons-material";
import { search, updateRecord } from "@ui/ComponentUtils/blueprintAPIs";
// import { Checkbox } from "@material-ui/core/index";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SyncIcon from "@mui/icons-material/Sync";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { Tooltip } from "@material-ui/core/index";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Grid } from "@material-ui/core/index";
import { syncCatToProducts } from "../api";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CataloguesList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [catalogues, setCatalogues] = useState([]);
  const [validUsers, setValidUsers] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null); // Track editing row
  const [editedRowData, setEditedRowData] = useState({}); // Store edited row data
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const model = enums.models.catalogues;
  const [expiryUrl, setExpiryUrl] = useState({});
  // const extraOptions = 3;
  const tableRef = useRef();

  useEffect(() => {
    (async () => {
      try {
        let res = await search(enums.models.users);

        res.sort((a, b) => a.role?.localeCompare(b.role));

        setUsers(
          [
            {
              firstName: enums.keywords.includedUsers.public,
              role: "Auto",
            },
            {
              firstName: enums.keywords.includedUsers.private,
              role: "Auto",
            },
            // {  firstName: enums.keywords.selectAll, role: "Auto" },
            ...res,
          ] || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const navigateToDetail = (row) => {
    navigate(`/${model}/${row.code}`);
  };

  const handleEditClick = (row) => {
    setEditingRowId(row._id);
    setEditedRowData({ code: row.code, includedUsers: row.includedUsers });
  };

  const handleSaveClick = async () => {
    try {
      if (editedRowData.code) {
        await updateRecord(enums.models.catalogues, {
          code: editedRowData.code,
          includedUsers: editedRowData.includedUsers,
        }); // in detail we use updateRecord with hook due to internal pre hooks for the selected products to assign catalogues _id to selected products.. here only updating includedUsers for now, which doesn't have that dependancy... #Aug 26, 2024

        tableRef.current && tableRef.current.onQueryChange();

        // if (tableRef.current && tableRef.current.updateDataManager) {
        //   tableRef.current.updateDataManager((prevData) =>
        //     prevData.map((row) =>
        //       row.code === editedRowData.code
        //         ? { ...row, includedUsers: editedRowData.includedUsers }
        //         : row
        //     )
        //   );
        // }
        setEditedRowData({});
        setEditingRowId(null);
      } else {
        Emitter.emit("alert_error", "Something went wrong.."); // never will come here.. just emergency
      }
    } catch (err) {
      console.log(err);
      Emitter.emit("alert_error", "Something went wrong");
    }
  };

  const handleCancelClick = () => {
    setEditingRowId(null);
    setEditedRowData({});
  };

  // const handleFieldChange = (field, value) => {
  //   setEditedRowData((prevData) => ({
  //     ...prevData,
  //     [field]: value,
  //   }));
  // };

  const EcommerceUsers = ({ row, editingRowId }) => {
    const [expanded, setExpanded] = useState(false);

    // Limit the number of users displayed initially
    const displayedUsers = expanded
      ? row?.includedUsers || []
      : (row?.includedUsers || []).slice(0, 3); // Show first 3 users by default

    const handleToggleExpand = () => {
      setExpanded(!expanded);
    };

    return (
      <div>
        {/* Show the first few users and handle "Read more" */}

        {displayedUsers.map((user, index) => (
          <span key={index}>
            {user?.firstName}
            {index < displayedUsers.length - 1 ? ", " : ""}
          </span>
        ))}

        {/* Show 'Read more' if not expanded and there are more users */}
        {!expanded && row?.includedUsers?.length > 3 && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={handleToggleExpand}
          >
            {" "}
            ...Read more
          </span>
        )}

        {/* Show 'Show less' when expanded */}
        {expanded && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={handleToggleExpand}
          >
            {" "}
            ...Read less
          </span>
        )}

        {/* Edit button */}
        {editingRowId ? null : (
          <IconButton onClick={() => handleEditClick(row)}>
            <Tooltip
              title={displayedUsers?.length ? "Edit Users" : "Add Users"}
            >
              {displayedUsers?.length ? <Edit /> : <Add />}
            </Tooltip>
          </IconButton>
        )}
      </div>
    );
  };

  const handleSync = async (row) => {
    try {
      const { existing, updated, removed } = await syncCatToProducts(row);
      Emitter.emit(
        "alert_success",
        `Products\nExisting: ${existing}\nRemoved: ${removed}\nUpdated: ${updated}`
      );
    } catch (err) {
      console.log(err);
      Emitter.emit("alert_error", "Something went wrong");
    }
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (row) => (
        // editingRowId === row._id ? (
        //   <TextField
        //     value={editedRowData.name}
        //     onChange={(e) => handleFieldChange("name", e.target.value)}
        //   />
        // ) : (
        <div onClick={() => !editingRowId && navigateToDetail(row)}>
          {row.name}
        </div>
      ),
      // ),
    },

    {
      title: "Workflow",
      field: "workflow",
      render: (row) => (
        <div onClick={() => !editingRowId && navigateToDetail(row)}>
          {row.workflow}
        </div>
      ),
    },
    {
      title: "Created",
      render: (row) => (
        <div
          onClick={() => !editingRowId && navigateToDetail(row)}
          style={{ minWidth: "110px" }}
        >
          <div>{formatDateToRead(row?.dateCreated)}</div>
          <div style={{ fontSize: "12px" }}>
            {row?.createdBy?.firstName + " " + row?.createdBy?.lastName}
          </div>

          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {row?.createdBy?.role}
          </div>
        </div>
      ),
    },

    {
      title: "Actions",
      render: (row) => {
        return editingRowId ? null : (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <MyHoverIcons
              muiIcon={<VisibilityOutlinedIcon />}
              muiIconHover={<VisibilityIcon />}
              tooltip={"View"}
              onClick={() => navigateToDetail(row)}
            />
            {row.workflow === enums.catalogueWorkflow.bulk && (
              <MyHoverIcons
                muiIcon={<SyncIcon />}
                muiIconHover={<SyncLockIcon />}
                tooltip={"Sync New Products"}
                onClick={() => handleSync(row)}
              />
            )}
          </div>
        );
      },
    },

    {
      title: "LINK it App",
      hidden: editingRowId,
      cellStyle: {
        width: 140,
        minWidth: 140,
      },
      render: (row) => (
        <img
          src={DomainIcon}
          style={{
            height: "32px",
            width: "32px",
            cursor: "pointer",
            filter:
              "invert(57%) sepia(100%) saturate(532%) hue-rotate(156deg) brightness(92%) contrast(101%)", // Adjusted filters to approximate #1d9bc2
          }}
          onClick={() => {
            setCatalogues([{ _id: row._id, name: row.name }]);
            setValidUsers(row.includedUsers?.filter((user) => user._id));
            handleOpenDialog(row);
          }}
          alt="Generate LINK"
          title="Generate LINK"
        />
      ),
    },

    {
      title: "E-Commerce App",
      render: (row) =>
        editingRowId === row._id ? (
          <div style={{ display: "flex" }}>
            <Autocomplete
              multiple={true}
              options={users || []}
              onChange={(_, value) => {
                // const isSelectAll = value?.find(
                //   (value) => value.firstName === enums.keywords.selectAll
                // );

                setEditedRowData({ ...editedRowData, includedUsers: value });
                // if (isSelectAll) {
                //   let allUsers = users ? [...users] : [];

                //   if (editedRowData.includedUsers?.length === users.length - 3) {
                //     allUsers = [];
                //   } else {
                //     allUsers.shift();
                //     allUsers.shift();
                //     allUsers.shift();
                //   }

                //   setEditedRowData({ ...editedRowData, includedUsers: allUsers });
                // }
              }}
              getOptionLabel={(opt) => {
                return (
                  (opt?.firstName || "") +
                  " " +
                  (opt?.lastName || "") +
                  (opt?.phone ? ` (${opt?.phone})` : "")
                );
              }}
              groupBy={(option) => option?.role}
              getOptionSelected={(option, value) =>
                (option?._id || option.firstName) ===
                (value?._id || value.firstName)
              }
              // renderOption={(option, state) => {
              //   if (option["firstName"] === enums.keywords.selectAll) {
              //     if (
              //       editedRowData?.includedUsers?.length ===
              //       users?.length - (extraOptions || 1)
              //     ) {
              //       state.selected = true;
              //     } else {
              //       state.selected = false;
              //     }

              //     return (
              //       <React.Fragment>
              //         <Checkbox
              //           icon={icon}
              //           checkedIcon={checkedIcon}
              //           // style={{}}
              //           checked={state.selected}
              //         />

              //         {option["firstName"] || ""}
              //       </React.Fragment>
              //     );
              //   }
              // }}
              value={editedRowData?.includedUsers || []}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder={"E-Commerce Users"}
                  size="small"
                  style={{ width: "250px" }}
                  variant={"outlined"}
                  label={""}
                />
              )}
            />

            <div>
              <IconButton onClick={handleSaveClick}>
                <Tooltip title="Save">
                  <Save />
                </Tooltip>
              </IconButton>
              <IconButton onClick={handleCancelClick}>
                <Tooltip title="Cancel">
                  <Cancel />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        ) : (
          <EcommerceUsers row={row} editingRowId={editingRowId} />
        ),
    },
  ];

  return (
    <div>
      <LINKit
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        catalogues={catalogues}
        setCatalogues={setCatalogues}
        validUsers={validUsers}
        setValidUsers={setValidUsers}
        setExpiryUrl={setExpiryUrl}
      />

      <GeneralListPage
        columns={columns}
        parentTableRef={tableRef}
        title={
          expiryUrl?.url ? (
            <Grid
              container
              style={{ cursor: "copy" }}
              onClick={() => {
                navigator.clipboard.writeText(expiryUrl?.url);
                Emitter.emit(
                  "alert_success",
                  `Copied to Clipboard, URL expires in 🎮 ${expiryUrl?.expiresIn} 🎲`
                );
              }}
            >
              <Grid item xs={11}>
                <pre style={{ color: "#007bff", textDecoration: "underline" }}>
                  Copy last generated expiry Link -{" "}
                  {
                    expiryUrl.url.split("/")[
                      expiryUrl.url.split("/").length - 1
                    ]
                  }
                  {"  "}
                </pre>
              </Grid>

              <Grid item xs={1}>
                <MyHoverIcons
                  muiIcon={
                    <FileCopyOutlinedIcon
                      style={{
                        fontSize: "42px",
                        marginTop: "12px",
                        cursor: "copy",
                      }}
                    />
                  }
                  muiIconHover={
                    <FileCopyIcon
                      style={{
                        fontSize: "42px",
                        marginTop: "12px",
                        cursor: "copy",
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          ) : null
        }
        model={model}
        headerTitle="Catalogues"
        searchFields={[
          "name",
          "workflow",
          "includedUsers.firstName",
          "includedUsers.phone",
        ]}
        hideAction={{ delete: true }}
        options={{ selection: false }}
        onRowClick={false}
      />
    </div>
  );
};

export default CataloguesList;
