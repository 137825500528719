import Commands from "./Commands.json";
const moment = require("moment");

const getDateRange = (args) => {
  args = args.trim().toLowerCase();
  let argsArray = args.split(" "),
    from = undefined,
    to = undefined;

  from =
    args === "now"
      ? moment()
      : Commands[args] &&
        moment()
          .add(Commands[args][0], Commands[args][1])
          .startOf(Commands[args][1]);

  to =
    args === "now"
      ? moment()
      : Commands[args] &&
        moment()
          .add(Commands[args][0], Commands[args][1])
          .endOf(Commands[args][1]);

  if (
    !from &&
    argsArray.length >= 2 &&
    argsArray[2] !== "from" &&
    isNaN(argsArray[1]) &&
    !isNaN(argsArray[0])
  ) {
    from = moment().subtract(argsArray[0], argsArray[1]).startOf(argsArray[1]);

    to = moment().subtract(argsArray[0], argsArray[1]).endOf(argsArray[1]);
  } else if (
    !from &&
    argsArray.length >= 3 &&
    argsArray[2] === "from" &&
    isNaN(argsArray[1]) &&
    !isNaN(argsArray[0])
  ) {
    let run = argsArray[4]
      ? Commands[argsArray[3] + " " + argsArray[4]]
      : Commands[argsArray[3]];

    from =
      argsArray[3] === "now"
        ? moment()
        : moment()
            .add(run ? run[0] : 0, run ? run[1] : "day")
            .startOf(run ? run[1] : "day");

    to = moment().add(argsArray[0], argsArray[1]).endOf(argsArray[1]);
  }

  return { from: from && from.toString(), to: to && to.toString() };
};

export default getDateRange;
