import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { notifyUsers, search } from "@ui/ComponentUtils/blueprintAPIs";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

const extraUserOptions = [
  { firstName: enums.keywords.includedUsers.public, role: "Auto" },
  // { firstName: enums.keywords.includedUsers.private, role: "Auto" },
  { firstName: enums.keywords.selectAll, role: enums.roles.customer },
];

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const { setValue } = formMethods;
  const [users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.alerts;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      let res = await search(enums.models.users);

      res.sort((a, b) => a.client?.name?.localeCompare(b.client?.name));

      setUsers([...extraUserOptions, ...res] || []);
    })();

    (async () => {
      let res = (await search(enums.models.iam.users)) || [];

      res.sort((a, b) => a.client?.name?.localeCompare(b.client?.name));

      setEmployees(res || []);
    })();
  }, []);

  const entityFields = [
    {
      name: "title",
      label: "Title",
      fieldType: "textField",
    },
    {
      name: "body",
      label: "Body",
      fieldType: "textField",
    },
    {
      xs: 12,
      name: "includedUsers",
      label: "Include Users",
      fieldType: "dropdown",
      multiple: true,
      render: "firstName",
      renderFunc: (option) =>
        (option?.firstName || "") +
        " " +
        (option?.lastName || "") +
        (option?.phone ? ` (${option?.phone})` : ""),
      extraOptions: 2,
      options: [...users, ...employees] || [],
      onChange: (_, value) => {
        const isSelectAll = value?.find(
          (value) => value.firstName === enums.keywords.selectAll
        );

        if (isSelectAll) {
          let allUsers = [...users, ...employees];

          if (values.includedUsers?.length === extraUserOptions.length) {
            allUsers = [];
          } else {
            extraUserOptions.map((_) => allUsers.shift());
          }

          setValue("includedUsers", allUsers);
        }
      },
      getOptionLabel: (opt) =>
        (opt?.firstName || "") +
        " " +
        (opt?.lastName || "") +
        (opt?.phone ? ` (${opt?.phone})` : ""),
      groupBy: (option) => option?.client?.name,
      getOptionSelected: (option, value) =>
        option?.firstName +
          " " +
          option?.lastName +
          (option?.phone ? ` (${option?.phone})` : "") ===
        value?.firstName +
          " " +
          value?.lastName +
          (value?.phone ? ` (${value?.phone})` : ""),
      renderOption: enums.keywords.selectAll,
    },
  ];

  const handleSendNotification = async () => {
    try {
      if (values.includedUsers?.length) {
        const res = await notifyUsers(enums.models.alerts, {
          body: values.body,
          title: values.title,
          navigation: values.navigation,
          _id: values._id,
          code: values.code,
          includedUsers: values.includedUsers,
          division: values.division,
        });

        CustomEventEmitter.emit("alert_success", "Notified Successfully!");
      } else {
        return CustomEventEmitter.emit(
          "alert_error",
          "Include at least 1 User to Notify!"
        );
      }
    } catch (err) {
      console.log(err);
      return CustomEventEmitter.emit("alert_error", "Something went wrong");
    }
  };

  const links = [
    {
      name: "Alerts",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.title,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      tooltip: "Edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      label: "Send Notification",
      tooltip: "Send Notification",
      iconName: <NotificationsActiveOutlinedIcon />,
      hidden: isEditable,
      onClick: () => {
        handleSendNotification();
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Alerts"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
