import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, isDivisionControlled } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "firstName",
    render: (row) => row?.firstName + " " + row?.lastName,
  },
  {
    title: "Divisions",
    field: "division.name",
    render: (row) =>
      row.division?.map && row.division?.map((div) => div?.name).join(", "),
    hidden: !isDivisionControlled,
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Role",
    field: "role",
  },
  {
    title: "Created Date",
    field: "dateCreated",
    render: (row) => formatDateToRead(row?.dateCreated),
  },
  {
    title: "Created By",
    render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const UsersList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.iam.users}
      navigateModel={enums.models.iam.users}
      headerTitle="Users"
      searchFields={["role", "phone"]}
      hideAction={{ delete: true }}
      userFields={["$firstName", "$lastName"]}
      mainQuery={{ criteria: [{ role: { $ne: enums.roles.customer } }, {}] }}
    />
  );
};

export default UsersList;
