import SmartForm from "./SmartForm";

import RHTextField from "./RHTextField";
import RHAutoComplete from "./RHAutoComplete";
import RHDatePicker from "./RHDatePicker";
import RHDateTimePicker from "./RHDateTimePicker";
import RHCheckbox from "./RHCheckbox";
import RHDocUpload from "./RHDocUpload";

export const globalDateFormat = {
  edit: "dd MMM yyyy",
  read: "DD MMM, YYYY",
};

export const globalDateTimeFormat = {
  edit: "dd MMM yyyy, hh:mm a",
  read: "DD MMM YYYY, hh:mm a",
};

export {
  RHTextField,
  RHAutoComplete,
  RHDatePicker,
  RHDateTimePicker,
  RHCheckbox,
  RHDocUpload,
};
export default SmartForm;
