import { search } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import React, { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import GeneralSection from "@ui/ComponentUtils/GeneralSection";

const DetailContent = (props) => {
  const { isEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  // const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   if (values?._id) {
  //     (async () => {
  //       try {
  //         const res = await search(enums.models.products, {
  //           populate: { path: "category" },
  //         });

  //         setProducts(res || {});
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     })();
  //   }
  // }, [values?._id]);

  const entityFields = [
    {
      xs: 6,
      name: "navigation.screenName",
      label: "Screen",
      fieldType: enums.fieldTypes.dropdown,

      render: () => values.navigation?.screenName,
      options: Object.values(enums.mobileAppScreenNames),
    },
  ];

  // if (
  //   values?.navigation?.screenName === enums.mobileAppScreenNames.productDetail
  // ) {
  //   entityFields.push({
  //     xs: 6,
  //     name: "navigation.product",
  //     label: "Detail Page",
  //     fieldType: enums.fieldTypes.dropdown,
  //     options: products || [],
  //     render: () => values.navigation?.product?.styleNo,
  //     renderFunc: (option) => option?.styleNo,
  //     getOptionLabel: (option) => option?.styleNo || "",
  //   });
  // }

  return (
    <>
      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Navigation"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailContent;
